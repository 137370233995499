// React
import React, { useState } from 'react'

// Redux
import { useSelector } from 'react-redux'
import { DATA_REDUCER, INPUT_REDUCER, RootState } from '../reduxStore'

// Components
import { Card } from '@blueprintjs/core'
import { LineChart } from '../components/charts/LineChart'

// Utils
import { convertDateYearMonthDay } from '../utils'

// Styles
import 'plottable/plottable.css'

import { WorkerDataResponse, QuestionAnsweredDataResponse } from '../types'

export interface FilteredQA {
  worker: string
  question: string
  type: string
  answer: string
  answered_at: string
}

export interface MetricsCardContainerProps {
  options?: string[]
}

export interface Metric {
  title: string
  type: string
  data: string[] | FilteredQA[]
}

export interface CoordinatePoint {
  x: Date
  y: number
}

const stubState = {
  // temporary stubs
  data: [
    { x: new Date('2020-09-29'), y: 5 },
    { x: new Date('2020-10-01'), y: 2 },
    { x: new Date('2020-10-03'), y: 15 }
  ],
  timeline: [new Date('2020-05-01'), new Date('2020-10-01')]
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const MetricsCardsContainer: Function = (): JSX.Element[] => {
  // Local state
  const [state, setState] = useState(stubState)

  // Redux store
  const selectedWorkers: WorkerDataResponse = useSelector(
    (state: RootState) => state[INPUT_REDUCER].workers
  )
  const questionsAnswered: QuestionAnsweredDataResponse = useSelector(
    (state: RootState) => state[DATA_REDUCER].questionsAnswered
  )

  // Filter results by selected worker(s) and convert `answered_at` format to `YYYY-MM-DD`;
  const filteredQA: FilteredQA[] = questionsAnswered
    ? Object.values(questionsAnswered)
        .filter((question) => Object.keys(selectedWorkers).includes(question.worker_id))
        .map((q) => ({
          worker: q.worker_id,
          question: q.question.question_text,
          type: q.question.question_type,
          answer: q.answer,
          answered_at: convertDateYearMonthDay(q.answered_at)
        }))
    : []

  const displayData = (metric: Metric) => {
    switch (metric.type) {
      case 'question':
        if (metric.data.length) {
          const data = metric.data as FilteredQA[]
          const items = data.map((_item, i) => {
            return (
              <div key={i}>
                <LineChart data={state.data} timeline={state.timeline} />
              </div>
            )
          })
          return items
        }
        return
      case 'checkins':
        return
      case 'onboarding_drawer':
        return
      default:
        return
    }
  }

  // On selectedWorkers change;
  if (filteredQA.length) {
    // Count number of questions answered in the same date (YYYY-MM-DD);
    const QACountByDate = filteredQA.reduce((acc: Record<string, number>, question) => {
      acc[question.answered_at] = ++acc[question.answered_at] || 1
      return acc
    }, {})

    const newStateData = Object.keys(QACountByDate).map((key) => ({
      x: new Date(key),
      y: QACountByDate[key]
    }))
    if (newStateData?.length) {
      setState({ ...state, data: newStateData })
    }
  }

  const metrics: Metric[] = [
    {
      title: 'Onaroll Dailys Answered Over Time',
      type: 'question',
      data: filteredQA
    },
    { title: 'Last seen (checkins)', type: 'checkins', data: [] },
    { title: '% onboarding seen', type: 'onboarding_drawer', data: [] }
  ]
  return metrics.map((metric: Metric) => (
    <Card className="mt-2 " key={metric.title}>
      <h3 className="bp3-heading">{metric.title}</h3>
      {displayData(metric)}
    </Card>
  ))
}
