import env from 'utils/runtimeEnv'

export enum Routes {
  home = 'home',
  live = 'live',
  onboarding = 'onboarding',
  newsPosts = 'news-posts',
  setup = 'setup',
  metrics = 'metrics',
  integration = 'integration'
}

const pathsMap = {
  [Routes.home]: () => '/',
  [Routes.live]: () => '/live',
  [Routes.onboarding]: () => '/onboarding',
  [Routes.newsPosts]: () => '/news-posts',
  [Routes.setup]: () => '/setup',
  [Routes.metrics]: () => '/metrics',
  [Routes.integration]: () => '/integration'
}
type PathsMap = typeof pathsMap

export const getPath = <TRoute extends keyof PathsMap>(
  route: TRoute,
  ...params: Parameters<PathsMap[TRoute]>
) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const pathCb: (...args: any[]) => string = pathsMap[route]

  return pathCb(...params)
}

export const isDevENV = () => {
  return process.env.NODE_ENV === 'development'
}

export const getApiHost: () => string = () => {
  if (isDevENV()) {
    return env.REACT_APP_API_HOST ?? ''
  }
  return env.REACT_APP_API_HOST
}
