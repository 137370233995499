import { Card, Colors, Elevation, Tag } from '@blueprintjs/core'
import React, { useState } from 'react'
import { StoreDataResponse, UserProfile, WorkerDataResponse } from '../types'
import { isSmsEnabled } from '../utils'

interface UserProfileInfoProps {
  workerData: WorkerDataResponse
  storeData: StoreDataResponse
  userProfile: UserProfile
  onToggle: (userProfile: UserProfile) => void
}

export const UserProfileInfo = (props: UserProfileInfoProps) => {
  const { workerData, storeData, userProfile, onToggle } = props

  const smsEnabled = isSmsEnabled(userProfile)

  // Get stores for workers associated to user profile
  const workerStores = Object.values(workerData)
    .filter((worker) => worker.user_profile_id === userProfile.id)
    .map((worker) => storeData[worker.store_id]?.name)
    .join(', ')

  const [color, setColor] = useState<string>(Colors.BLUE5)

  return (
    <Card
      interactive={true}
      elevation={Elevation.TWO}
      style={{ background: color }}
      className="databar"
      onClick={() => {
        onToggle(userProfile)
        color === Colors.WHITE ? setColor(Colors.BLUE5) : setColor(Colors.WHITE)
      }}
    >
      <div className="databar-text">
        <p>
          <b>{userProfile.full_name}</b>
        </p>
        <p>{workerStores}</p>
      </div>
      <div className="databar-text">
        <Tag intent={smsEnabled ? 'success' : 'danger'}>
          {smsEnabled ? 'SMS Enabled' : 'SMS Not Enabled'}
        </Tag>
        <p>Phone: {userProfile.phone}</p>
      </div>
    </Card>
  )
}
