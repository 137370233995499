import React from 'react'
import { Dialog } from '@blueprintjs/core'

import { ButtonsGroup } from './ButtonsGroup'
import { UserList, UserListType } from './common/UserList'

interface MessageDialogProps {
  children: React.ReactNode
  userProfileIds: string[]
  isLoading: boolean
  isOpen: boolean
  onClose: (event?: React.SyntheticEvent<HTMLElement>) => void
  onSubmit: (event?: React.MouseEvent<HTMLElement>) => void
}

export const MessageDialog = (props: MessageDialogProps) => {
  const { children, userProfileIds, isLoading, isOpen, onClose, onSubmit } = props

  return (
    <Dialog icon="info-sign" onClose={onClose} title="Text Message Confirmation" isOpen={isOpen}>
      <div className="m-2">
        {children}

        <UserList
          title={`${userProfileIds.length} Team Members`}
          userIds={userProfileIds}
          type={UserListType.userProfile}
        />

        <ButtonsGroup isLoading={isLoading} onSendButtonClick={onSubmit} />
      </div>
    </Dialog>
  )
}
