import React from 'react'

import { AdminHeader } from '../components/AdminHeader'
import { Routes } from '../urls/router-paths'

interface MainProps {
  activePage: Routes
  children: JSX.Element[]
}

const Main = (props: MainProps) => {
  const { activePage, children } = props
  return (
    <div className="App">
      <AdminHeader activePage={activePage} />
      <div className="admin-main">{children}</div>
    </div>
  )
}

export default Main
