import { Label, Switch, TextArea } from '@blueprintjs/core'
import React from 'react'

interface OnboardingMessageDialogBodyProps {
  enrollmentComplete: boolean
  message: string
}

export const OnboardingMessageDialogBody = (props: OnboardingMessageDialogBodyProps) => {
  const { enrollmentComplete, message } = props
  return (
    <div>
      <div className="mtb-1">
        <Switch checked={enrollmentComplete} label="Set enrollment complete" disabled={true} />
      </div>

      <div className="mtb-1">
        <Label>Text Message</Label>
        <TextArea fill={true} disabled={true} value={message} />
      </div>
    </div>
  )
}
