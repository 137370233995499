// Redux
import { update } from './reducerGenerator'
import {
  DISPLAY_STATE_REDUCER,
  DATA_REDUCER,
  MESSAGE_REDUCER,
  RootState,
  OnarollThunkDispatch,
  AUTH_REDUCER
} from './reduxStore'

// Backend
import {
  fetchReward,
  fetchStore,
  putStore,
  postStore,
  fetchStoreGroup,
  fetchDough,
  fetchWorker,
  fetchUserProfile,
  fetchDataIntegration,
  batchPostMessage,
  fetchQuestionAnswer,
  fetchLatestQuestionAnswer,
  putWorker,
  postReward,
  postWorker,
  putReward,
  postUserProfileImpersonation,
  postDropImpersonateUserProfile,
  fetchCachedPoints,
  postUserProfileBonusPoints,
  fetchWhoami,
  fetchUploadSheetNames,
  QueryParams,
  fetchRosterSheetNames,
  fetchGoals
} from './api/backend'

// Types
import {
  DoughEntryDataResponse,
  RewardDataResponse,
  StoreDataResponse,
  StoreGroupDataResponse,
  WorkerDataResponse,
  UserProfileDataResponse,
  DataIntegrationDataResponse,
  MessageError,
  MessageData,
  QuestionAnsweredDataResponse,
  PostMessageResponse,
  Reward,
  Store,
  Worker,
  CachedPointDataResponse,
  GoalsDataResponse,
  Whoami
} from './types'

export const fetchDataIntegrationThunk = () => async (
  dispatch: OnarollThunkDispatch,
  getState: () => RootState
) => {
  const state = getState()
  try {
    const integrations: DataIntegrationDataResponse = await fetchDataIntegration()

    dispatch(
      update(DATA_REDUCER, 'Load fetched data integration data', {
        ...state,
        integrations
      })
    )
  } catch (error) {
    update(DISPLAY_STATE_REDUCER, 'Show error', {
      dataFetchError: error || 'fetchDataIntegrationThunk error'
    })
  }
}

export const fetchRewardThunk = () => async (
  dispatch: OnarollThunkDispatch,
  getState: () => RootState
) => {
  const state = getState()
  try {
    const rewards: RewardDataResponse = await fetchReward()

    dispatch(
      update(DATA_REDUCER, 'Load fetched reward data', {
        ...state,
        rewards
      })
    )
  } catch (error) {
    update(DISPLAY_STATE_REDUCER, 'Show error', {
      dataFetchError: error || 'fetchPerkThunk error'
    })
  }
}

export const fetchStoreGroupThunk = () => async (
  dispatch: OnarollThunkDispatch,
  getState: () => RootState
) => {
  const state = getState()
  try {
    const storeGroups: StoreGroupDataResponse = await fetchStoreGroup()

    dispatch(
      update(DATA_REDUCER, 'Load fetched store group data', {
        ...state,
        storeGroups
      })
    )
  } catch (error) {
    update(DISPLAY_STATE_REDUCER, 'Show error', {
      dataFetchError: error || 'fetchStoreGroupThunk error'
    })
  }
}

export const fetchUploadSheetNamesThunk = (storeGroupId: QueryParams) => async (
  dispatch: OnarollThunkDispatch,
  getState: () => RootState
) => {
  const state = getState()
  try {
    const uploadSheetNames = await fetchUploadSheetNames(storeGroupId)

    dispatch(
      update(DATA_REDUCER, 'Load fetched upload sheet names', {
        ...state,
        uploadSheetNames
      })
    )
  } catch (error) {
    update(DISPLAY_STATE_REDUCER, 'Show error', {
      dataFetchError: error || 'fetchUploadSheetNamesThunk error'
    })
  }
}

export const fetchRosterSheetNamesThunk = (storeGroupId: QueryParams) => async (
  dispatch: OnarollThunkDispatch,
  getState: () => RootState
) => {
  const state = getState()
  try {
    const rosterSheetNames = await fetchRosterSheetNames(storeGroupId)

    dispatch(
      update(DATA_REDUCER, 'Load fetched roster sheet names', {
        ...state,
        rosterSheetNames
      })
    )
  } catch (error) {
    update(DISPLAY_STATE_REDUCER, 'Show error', {
      dataFetchError: error || 'fetchRosterSheetNamesThunk error'
    })
  }
}

export const fetchStoreThunk = () => async (
  dispatch: OnarollThunkDispatch,
  getState: () => RootState
) => {
  const state = getState()
  try {
    const stores: StoreDataResponse = await fetchStore()

    dispatch(
      update(DATA_REDUCER, 'Load fetched store data', {
        ...state,
        stores
      })
    )
  } catch (error) {
    update(DISPLAY_STATE_REDUCER, 'Show error', {
      dataFetchError: error || 'fetchStoreThunk error'
    })
  }
}

export const fetchDoughThunk = () => async (
  dispatch: OnarollThunkDispatch,
  getState: () => RootState
) => {
  const state = getState()
  try {
    const doughEntries: DoughEntryDataResponse = await fetchDough()

    dispatch(
      update(DATA_REDUCER, 'Load fetched dough data', {
        ...state,
        doughEntries
      })
    )
  } catch (error) {
    update(DISPLAY_STATE_REDUCER, 'Show error', {
      dataFetchError: error || 'fetchDoughThunk error'
    })
  }
}

export const fetchWorkerThunk = () => async (
  dispatch: OnarollThunkDispatch,
  getState: () => RootState
) => {
  const state = getState()
  try {
    const workers: WorkerDataResponse = await fetchWorker()
    dispatch(
      update(DATA_REDUCER, 'Load fetched worker data', {
        ...state,
        workers
      })
    )
  } catch (error) {
    update(DISPLAY_STATE_REDUCER, 'Show error', {
      dataFetchError: error || 'fetchWorkerThunk error'
    })
  }
}

export const fetchUserProfileThunk = () => async (
  dispatch: OnarollThunkDispatch,
  getState: () => RootState
) => {
  const state = getState()
  try {
    const userProfiles: UserProfileDataResponse = await fetchUserProfile()
    dispatch(
      update(DATA_REDUCER, 'Load fetched UserProfile data', {
        ...state,
        userProfiles
      })
    )
  } catch (error) {
    update(DISPLAY_STATE_REDUCER, 'Show error', {
      dataFetchError: error || 'fetchUserProfileThunk error'
    })
  }
}

export const fetchWhoamiThunk = () => async (
  dispatch: OnarollThunkDispatch,
  getState: () => RootState
) => {
  const state = getState()
  try {
    const whoami: Whoami = await fetchWhoami()
    dispatch(
      update(AUTH_REDUCER, 'Load fetched Whoami data', {
        ...state.auth,
        whoami,
        isUnauthorized: false
      })
    )
  } catch (error) {
    dispatch(
      update(DISPLAY_STATE_REDUCER, 'Show error', {
        dataFetchError: error || 'fetchWhoamiThunk error'
      })
    )
    dispatch(
      update(AUTH_REDUCER, 'Load whoami information', {
        ...state.auth,
        isUnauthorized: true
      })
    )
  }
}

export const postMessageDataThunk = (message: MessageData) => async (
  dispatch: OnarollThunkDispatch
) => {
  const text = message.text
  const bonus = message.bonus
  const enrollment_complete = message.enrollment_complete
  const news_post = message.news_post
  const user_profile_ids = message.user_profile_ids

  // Liftoff
  const dispatch_liftoff = () =>
    dispatch(
      update(MESSAGE_REDUCER, 'Sending message', {
        isSending: true,
        requestFailed: false,
        errors: [],
        response: { success: [], failure: [] }
      })
    )

  // Jettison
  const dispatch_jettison = (response: PostMessageResponse) =>
    dispatch(
      update(MESSAGE_REDUCER, 'Message has been sent', {
        text,
        user_profile_ids,
        bonus,
        enrollment_complete,
        news_post,
        requestSucceeded: true,
        isSending: false,
        requestFailed: false,
        errors: [],
        response
      })
    )

  // Crash
  const dispatch_crash = (error: MessageError) =>
    dispatch(
      update(MESSAGE_REDUCER, 'Send request has crashed', {
        isSending: false,
        requestFailed: true,
        requestSucceeded: false,
        response: { success: [], failure: [] },
        errors: [
          {
            status: error.status,
            endpoint: error.url,
            message: error.message,
            when: Date.now()
          }
        ]
      })
    )

  //Ignition

  try {
    // [Redux Action] Liftoff
    dispatch_liftoff()

    const result = await batchPostMessage(message)
    //[Redux Action] Jettison
    dispatch_jettison(result)
  } catch (e) {
    //[Redux Action] Crash
    dispatch_crash(e)
  }
}

export const fetchQuestionAnswerThunk = () => async (
  dispatch: OnarollThunkDispatch,
  getState: () => RootState
) => {
  const state = getState()
  try {
    const questionsAnswered: QuestionAnsweredDataResponse = await fetchQuestionAnswer()
    dispatch(
      update(DATA_REDUCER, 'Load answered questions data', {
        ...state,
        questionsAnswered
      })
    )
  } catch (error) {
    update(DISPLAY_STATE_REDUCER, 'Show error', {
      dataFetchError: error || 'fetchQuestionAnswerThunk error'
    })
  }
}

export const fetchLatestQuestionAnswerThunk = (workerIds: string[] | null) => async (
  dispatch: OnarollThunkDispatch,
  getState: () => RootState
) => {
  const state = getState()
  try {
    const latestAnswers = await fetchLatestQuestionAnswer(workerIds)
    dispatch(
      update(DATA_REDUCER, 'Load answered questions data', {
        ...state,
        latestAnswersByWorkerId: latestAnswers
      })
    )
  } catch (error) {
    update(DISPLAY_STATE_REDUCER, 'Show error', {
      dataFetchError: error || 'fetchQuestionAnswerThunk error'
    })
  }
}

export const fetchCachedPointsThunk = () => async (
  dispatch: OnarollThunkDispatch,
  getState: () => RootState
) => {
  const state = getState()
  try {
    const cachedPoints: CachedPointDataResponse = await fetchCachedPoints()

    // TODO: Would be nice if we could check if we have fetched this data in the last 10 mins.
    // Only dispatch action if it has been greater than that
    dispatch(
      update(DATA_REDUCER, 'Load cached points data', {
        ...state,
        cachedPoints
      })
    )
  } catch (error) {
    dispatch(
      update(DISPLAY_STATE_REDUCER, 'Show error', {
        dataFetchError: error || 'fetchCachedPointsThunk error'
      })
    )
  }
}

export const fetchGoalsThunk = (storeId: QueryParams) => async (
  dispatch: OnarollThunkDispatch,
  getState: () => RootState
) => {
  const state = getState()
  try {
    const goals: GoalsDataResponse = await fetchGoals(storeId)

    dispatch(
      update(DATA_REDUCER, 'Load fetched goals by store', {
        ...state,
        goals
      })
    )
  } catch (error) {
    update(DISPLAY_STATE_REDUCER, 'Show error', {
      dataFetchError: error || 'fetchGoalsThunk error'
    })
  }
}

export const putRewardsDataThunk = (object: Reward) => async (dispatch: OnarollThunkDispatch) => {
  dispatch({ type: 'UPDATING_REWARD', reward: object })

  const requestPayload: Reward = await putReward(object)

  dispatch({ type: 'UPDATED_REWARD', reward: requestPayload })

  return requestPayload
}

export const postRewardsDataThunk = (object: Reward) => async (dispatch: OnarollThunkDispatch) => {
  dispatch({ type: 'CREATING_REWARD', reward: object })

  const requestPayload: Reward = await postReward(object)

  dispatch({ type: 'CREATED_REWARD', reward: requestPayload })

  return requestPayload
}

export const putStoreDataThunk = (object: Store) => async (dispatch: OnarollThunkDispatch) => {
  dispatch({ type: 'UPDATING_STORE', store: object })

  const requestPayload: Reward = await putStore(object)

  dispatch({ type: 'UPDATED_STORE', store: requestPayload })

  return requestPayload
}

export const postStoreDataThunk = (object: Store) => async (dispatch: OnarollThunkDispatch) => {
  dispatch({ type: 'CREATING_STORE', store: object })

  const requestPayload: Reward = await postStore(object)

  dispatch({ type: 'CREATED_STORE', store: requestPayload })

  return requestPayload
}

export const putWorkerDataThunk = (object: Worker) => async (dispatch: OnarollThunkDispatch) => {
  dispatch({ type: 'UPDATING_WORKER', worker: object })

  const requestPayload: Worker = await putWorker(object)

  dispatch({ type: 'UPDATED_WORKER', worker: requestPayload })

  return requestPayload
}

export const postWorkerDataThunk = (object: Worker) => async (dispatch: OnarollThunkDispatch) => {
  dispatch({ type: 'CREATING_WORKER', worker: object })

  const requestPayload: Worker = await postWorker(object)

  dispatch({ type: 'CREATED_WORKER', worker: requestPayload })

  return requestPayload
}

export const postImpersonationThunk = (user_profile_id: string) => async (
  dispatch: OnarollThunkDispatch,
  getState: () => RootState
) => {
  const state = getState()
  try {
    const requestPayload = await postUserProfileImpersonation(user_profile_id)
    const whoami: Whoami = await fetchWhoami()
    dispatch(
      update(AUTH_REDUCER, 'Impersonate user profile', {
        ...state.auth,
        whoami,
        isUnauthorized: false
      })
    )
    return requestPayload
  } catch (error) {
    dispatch(
      update(DISPLAY_STATE_REDUCER, 'Show error', {
        dataFetchError: error || 'postImpersonationThunk error'
      })
    )
  }
}

export const postDropImpersonationThunk = () => async (
  dispatch: OnarollThunkDispatch,
  getState: () => RootState
) => {
  const state = getState()
  const resetState = {
    ...state.auth,
    whoami: null,
    isUnauthorized: true
  }
  try {
    const requestPayload = await postDropImpersonateUserProfile()
    dispatch(update(AUTH_REDUCER, 'Drop impersonation for user profile', resetState))
    return requestPayload
  } catch (error) {
    dispatch(
      update(DISPLAY_STATE_REDUCER, 'Show error', {
        dataFetchError: error || 'postDropImpersonationThunk error'
      })
    )
  }
}

export const postGenerateBonusPointsThunk = (
  user_profile_id: string,
  bonus_points: number
) => async (dispatch: OnarollThunkDispatch) => {
  dispatch({ type: 'GENERATING_POINTS_FOR_USER_PROFILE', user_profile_id: user_profile_id })

  const requestPayload = await postUserProfileBonusPoints(user_profile_id, bonus_points)

  dispatch({ type: 'GENERATED_POINTS_FOR_USER_PROFILE', user_profile_id: user_profile_id })

  return requestPayload
}
