import { Button, Callout, FileInput, FormGroup, Intent, MenuItem } from '@blueprintjs/core'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { postAndDownload } from '../../api/backend'
import { GenericSelector, SelectionItem } from '../../components/GenericSelector'
import { DATA_REDUCER, RootState } from '../../reduxStore'
import { RosterSheetNamesResponse, StoreGroup, StoreGroupDataResponse } from '../../types'
import { isEmpty } from 'lodash'
import { Suggest } from '@blueprintjs/select'
import { fetchRosterSheetNamesThunk } from '../../thunks'

interface StoreGroupSelectorProps {
  onStoreGroupSelected: (storeGroup: StoreGroup) => void
}

const StoreGroupSelector = (props: StoreGroupSelectorProps) => {
  const { onStoreGroupSelected } = props

  const storeGroups: StoreGroupDataResponse = useSelector(
    (state: RootState) => state[DATA_REDUCER].storeGroups
  )
  const [buttonText, setButtonText] = useState('Select Store Group')

  const handleSelection = (selected: SelectionItem<StoreGroup>) => {
    const text = selected.label ? `${selected.title} (${selected.label})` : selected.title
    setButtonText(text)
    onStoreGroupSelected(selected.item)
  }

  const allStoreGroups = Object.values(storeGroups).map((s) => {
    return {
      id: s.id,
      title: s.name,
      item: s
    }
  })

  return (
    <GenericSelector
      items={allStoreGroups}
      buttonText={buttonText}
      onItemSelect={handleSelection}
    />
  )
}

const postConvertRoster = async (
  storeGroupId: string | null,
  tabName: string | null,
  file: File
) => {
  const formData = new FormData()
  let filename = null
  if (storeGroupId) {
    formData.append('store_group_id', storeGroupId)
    filename = `${storeGroupId}.csv`
  }
  if (tabName) {
    formData.append('tab_name', tabName)
  }

  formData.append('file', file)
  return await postAndDownload('integration/generic/normalize-roster', formData, filename)
}

interface CalloutState {
  title: string
  message: string
  intent: Intent
}

export const ConvertRoster = () => {
  const dispatch = useDispatch()
  const [selectedStoreGroup, setSelectedStoreGroup] = useState<StoreGroup | null>(null)
  const [file, setFile] = useState<File | null>(null)
  const [callout, setCallout] = useState<CalloutState | null>(null)
  const [selectedTabName, setSelectedTabName] = useState<string | null>(null)

  const rosterSheetNamesResponse: RosterSheetNamesResponse = useSelector(
    (state: RootState) => state[DATA_REDUCER].rosterSheetNames
  )

  const tabNames = !isEmpty(rosterSheetNamesResponse)
    ? rosterSheetNamesResponse.upload_sheet_names
    : []

  const fileText = file ? file.name : 'Select roster report to upload'

  const handleStoreSelection = (storeGroup: StoreGroup) => {
    setSelectedStoreGroup(storeGroup)
    dispatch(fetchRosterSheetNamesThunk({ store_group_id: storeGroup.id }))
  }

  const setError = (message: string) => {
    setCallout({ title: 'Error', message: message, intent: 'danger' })
  }
  const setSuccess = (message: string) => {
    setCallout({ title: 'Success', message: message, intent: 'success' })
  }

  const handleUpload = async () => {
    const storeGroupId = selectedStoreGroup?.id ?? null
    if (!file) {
      setError('Please select a file')
      return
    }
    try {
      const response = await postConvertRoster(storeGroupId, selectedTabName, file)
      setSuccess(response)
    } catch (e) {
      setError(String(e))
    }
  }

  return (
    <div>
      {callout ? (
        <Callout title={callout.title} intent={callout.intent} className="admin-panel">
          {callout.message}
        </Callout>
      ) : null}
      <p>
        Google sheets need to be added in Django before they can be converted.
        <br />
        Select a specific store the report is associated with.
      </p>
      <FormGroup label="Store Group">
        <StoreGroupSelector onStoreGroupSelected={handleStoreSelection} />
      </FormGroup>
      {!isEmpty(tabNames) && selectedStoreGroup && (
        <FormGroup label="Select GoogleSheet Tab this data should be posted to">
          <Suggest
            items={tabNames}
            inputValueRenderer={(item) => item.toString()}
            itemRenderer={(item, { handleClick, modifiers }) => {
              return (
                <MenuItem
                  active={modifiers.active}
                  disabled={modifiers.disabled}
                  onClick={handleClick}
                  text={item}
                />
              )
            }}
            defaultSelectedItem={tabNames[0]}
            onItemSelect={(tab) => setSelectedTabName(tab)}
            inputProps={{ placeholder: 'Select Sheet Tab Name' }}
          />
        </FormGroup>
      )}
      <FormGroup helperText="." label="File" labelFor="file-input" inline={true}>
        <FileInput
          text={fileText}
          inputProps={{ accept: '.csv' }}
          onInputChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const fileList = e.target.files
            if (fileList && fileList.length > 0) {
              setFile(fileList[0])
            }
          }}
        />
      </FormGroup>
      <Button text="Upload" onClick={() => handleUpload()} />
    </div>
  )
}
