/* eslint-disable @typescript-eslint/no-explicit-any */
// React
import React, { useState } from 'react'

// Components
import { Button, Callout } from '@blueprintjs/core'

// Types
import { RequestResponse } from '../types'

export enum Action {
  update = 'update',
  create = 'create',
  duplicate = 'duplicate'
}

interface SubmitButtonProps<T> {
  object: T
  action: Action
  apiCall: () => Promise<any>
  className?: string
}

export const SubmitButton = <T,>(props: SubmitButtonProps<T>) => {
  const [requestResponse, setRequestResponse] = useState<RequestResponse | undefined>(undefined)
  const okStatus = 200

  const ResponseCallout = () => {
    if (!requestResponse) return <div />

    const hasSuccessed = requestResponse.status === okStatus

    const title = hasSuccessed ? 'Request has succeeded' : 'Request has failed'

    const getResponseMessage: any = (message: string) => {
      // If response is ok
      if (hasSuccessed) return message

      let errors: string | undefined | JSX.Element[]

      try {
        const errorsObj: any = JSON.parse(message)
        errors = Object.keys(errorsObj).map((field: string) => (
          <li key={field}>
            <b>{field}: </b> {errorsObj[field]}
          </li>
        ))
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e)
        errors = String(e)
      }

      return errors
    }

    return (
      <Callout
        title={requestResponse.status + ': ' + title}
        intent={hasSuccessed ? 'success' : `danger`}
        className="admin-panel"
      >
        {getResponseMessage(requestResponse.message)}
      </Callout>
    )
  }

  const getButtonTile = () => {
    switch (props.action) {
      case Action.create:
        return 'Create'
      case Action.update:
        return 'Save'
      case Action.duplicate:
        return 'Duplicate'
      default:
        return ''
    }
  }

  const getSuccessMessage = () => {
    switch (props.action) {
      case Action.create:
        return 'Resource has been successfully created'
      case Action.update:
        return 'Resource has been successfully updated'
      case Action.duplicate:
        return 'Resource has been successfully duplicated'
      default:
        return ''
    }
  }

  return (
    <div>
      <ResponseCallout />
      <Button
        className={props?.className}
        text={getButtonTile()}
        onClick={async () => {
          try {
            const request = await props.apiCall()

            // It's safe to assume a 200 response here
            const response: RequestResponse = {
              message: getSuccessMessage(),
              status: 200
            }

            // Set state for callout
            setRequestResponse({ ...response })

            return request
          } catch (e) {
            const response: RequestResponse = {
              message: e.message,
              status: e.status
            }

            // Set state for callout
            setRequestResponse({ ...response })
          }
        }}
      />
    </div>
  )
}
