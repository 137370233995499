import {
  BatchPostMessageResponse,
  DataIntegration,
  DataIntegrationDataResponse,
  DoughEntry,
  DoughEntryDataResponse,
  RewardDataPayload,
  RewardDataResponse,
  Reward,
  Flour,
  Store,
  StoreDataResponse,
  StoreGroup,
  StoreGroupDataResponse,
  Worker,
  WorkerDataResponse,
  UserProfile,
  UserProfileDataResponse,
  MessageData,
  QuestionAnswered,
  QuestionAnsweredDataResponse,
  WorkerLatestAnswers,
  HasId,
  PostMessageResponse,
  CachedPointDataResponse,
  UploadSheetNamesResponse,
  CachedPoint,
  Whoami,
  GoalsDataResponse,
  Goals
} from '../types'
import { debounce } from 'lodash'

import { getApiHost } from '../urls/router-paths'
import { groupBy, toDictionary, toDictionaryById } from '../utils'
const API_PATH = '/admin/dashboard/api/v1/'

enum ENDPOINTS {
  store = 'store',
  dough = 'dough',
  worker = 'worker',
  userProfile = 'userprofile',
  message = 'message',
  newsPost = 'news-post',
  reward = 'reward',
  flour = 'flour',
  goals = 'goal',
  integration = 'integration',
  storeGroup = 'storegroup',
  csrf = 'csrf',
  questionAnswered = 'question/answered',
  latestQuestionAnswered = 'question/answered/latest',
  workerFilter = 'filter',
  impersonate = 'impersonate',
  dropImpersonate = 'drop-impersonate',
  cachedPoints = 'point',
  bonusPoints = 'bonuspoint',
  whoami = 'whoami',
  uploadSheetNames = 'integration/upload-sheet-names',
  rosterSheetNames = 'integration/roster-sheet-names'
}

type ApiCall<T> = () => Promise<T>

export class HttpError extends Error {
  status: number
  url: string
  constructor(message: string, status: number, url: string) {
    super(message)
    this.name = 'HttpError'
    this.message = message
    this.status = status
    this.url = url
  }
}

export interface PagedResponse<T> {
  next?: string
  previous?: string
  results: T[]
}

export type QueryParams = Record<string, string | string[] | number | number[]>

const apiHost = getApiHost()

const _get = async (url_path: string, query_params?: QueryParams) => {
  const param_str = query_params
    ? `?${Object.keys(query_params)
        .map((key) => key + '=' + query_params[key])
        .join('&')}`
    : ''

  const result = await fetch(`${apiHost}${API_PATH}${url_path}${param_str}`, {
    mode: 'cors',
    credentials: 'include'
  })
  if (!result.ok) {
    throw new HttpError(result.statusText, result.status, result.url)
  }
  return await result.json()
}

export const fetchCsrf = async () => {
  try {
    const csrf: { token: string } = await _get(ENDPOINTS.csrf)
    return csrf.token
  } catch (e) {
    if (e.status === 404) {
      // This is fine: no data found
      return ''
    }
    throw e
  }
}

export const post = async <T>(url_path: string, body: T) => {
  const token = await fetchCsrf()

  const result = await fetch(`${apiHost}${API_PATH}${url_path}`, {
    method: 'POST',
    mode: 'cors',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRFToken': token
    },
    body: JSON.stringify(body)
  })
  if (!result.ok) {
    let errorMessage: string

    try {
      const error = await result.text()
      errorMessage = error
    } catch (e) {
      errorMessage = String(e)
    }

    throw new HttpError(errorMessage, result.status, result.url)
  }
  try {
    return await result.json()
  } catch (e) {
    return result
  }
}

export const postAndDownload = async (
  url_path: string,
  form: FormData,
  download_file_name: string | null = null
) => {
  const token = await fetchCsrf()

  const result = await fetch(`${apiHost}${API_PATH}${url_path}`, {
    method: 'POST',
    mode: 'cors',
    credentials: 'include',
    headers: {
      'X-CSRFToken': token
    },
    body: form
  })
  if (!result.ok) {
    let errorMessage: string

    try {
      const error = await result.text()
      errorMessage = error
    } catch (e) {
      errorMessage = String(e)
    }

    throw new HttpError(errorMessage, result.status, result.url)
  }
  const isCsv = result.headers.get('Content-Type')?.indexOf('text/csv')
  if (isCsv !== undefined && isCsv > -1) {
    const blob = await result.blob()
    const file = window.URL.createObjectURL(new Blob([blob]))
    const link = document.createElement('a')
    link.href = file
    link.setAttribute('download', download_file_name ? download_file_name : `roster.csv`)
    document.body.appendChild(link)
    link.click()
    link.parentNode?.removeChild(link)
    return 'Formatted roster downloaded successfully'
  } else {
    return await result.text()
  }
}

export const postForm = async (url_path: string, form: FormData) => {
  const token = await fetchCsrf()

  const result = await fetch(`${apiHost}${API_PATH}${url_path}`, {
    method: 'POST',
    mode: 'cors',
    credentials: 'include',
    headers: {
      'X-CSRFToken': token
    },
    body: form
  })
  if (!result.ok) {
    let errorMessage: string

    try {
      const error = await result.text()
      errorMessage = error
    } catch (e) {
      errorMessage = String(e)
    }

    throw new HttpError(errorMessage, result.status, result.url)
  }
  try {
    return await result.json()
  } catch (e) {
    return result
  }
}

/**
 * delay posting form data to the backend by 3 seconds to avoid possible race conditions that might cause data to be duplicated.
 * delaying the API call would allow for atleast one API request to be processed at a time from the backend which would allow validation
 * to run properly for adjascent requests.
 **/
export const debouncedFormPost = debounce(postForm, 3000)

const _put = async <T>(url_path: string, body: T) => {
  const token = await fetchCsrf()

  const result = await fetch(`${apiHost}${API_PATH}${url_path}`, {
    method: 'PUT',
    mode: 'cors',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRFToken': token
    },
    body: JSON.stringify(body)
  })
  if (!result.ok) {
    let errorMessage: string

    try {
      const error = await result.text()
      errorMessage = error
    } catch (e) {
      errorMessage = String(e)
    }

    throw new HttpError(errorMessage, result.status, result.url)
  }
  return await result.json()
}

export const fetchDataIntegration: ApiCall<DataIntegrationDataResponse> = async () => {
  try {
    const integrations: DataIntegration[] = await _get(ENDPOINTS.integration)
    const result: DataIntegrationDataResponse = toDictionaryById(integrations)
    return result
  } catch (e) {
    if (e.status === 404) {
      // This is fine: no data found
      return {}
    }
    throw e
  }
}

export const fetchReward: ApiCall<RewardDataResponse> = async () => {
  try {
    const rewards: RewardDataPayload = await _get(ENDPOINTS.reward)
    const result: RewardDataResponse = toDictionaryById(rewards.results)
    return result
  } catch (e) {
    if (e.status === 404) {
      // This is fine: no data found
      return {}
    }
    throw e
  }
}

// Disabling eslint rule for the following unused code block
// This code block may be useful in the future

/* eslint-disable @typescript-eslint/no-unused-vars */

const tryGetPaged = <T extends HasId>(endpoint: ENDPOINTS) => {
  return async () => {
    try {
      const response: PagedResponse<T> = await _get(endpoint)
      const result = toDictionaryById(response.results)
      return result
    } catch (e) {
      // This is fine: no data found
      if (e.status === 404) {
        return {}
      }
      throw e
    }
  }
}

/* eslint-enable @typescript-eslint/no-unused-vars */

const tryGet = <T extends HasId>(endpoint: ENDPOINTS) => {
  return async () => {
    try {
      const response: T[] = await _get(endpoint)
      const result = toDictionaryById(response)
      return result
    } catch (e) {
      // This is fine: no data found
      if (e.status === 404) {
        return {}
      }
      throw e
    }
  }
}

export const fetchStoreGroup: ApiCall<StoreGroupDataResponse> = async () => {
  try {
    const storeGroups: StoreGroup[] = await _get(ENDPOINTS.storeGroup)
    const result: StoreGroupDataResponse = toDictionaryById(storeGroups)
    return result
  } catch (e) {
    if (e.status === 404) {
      // This is fine: no data found
      return {}
    }
    throw e
  }
}

export const fetchStore: ApiCall<StoreDataResponse> = async () => {
  try {
    const stores: Store[] = await _get(ENDPOINTS.store)
    const result: StoreDataResponse = toDictionaryById(stores)
    return result
  } catch (e) {
    if (e.status === 404) {
      // This is fine: no data found
      return {}
    }
    throw e
  }
}

export const fetchDough: ApiCall<DoughEntryDataResponse> = async () => {
  try {
    const doughEntries: DoughEntry[] = await _get(ENDPOINTS.dough)
    const result: DoughEntryDataResponse = toDictionaryById(doughEntries)
    return result
  } catch (e) {
    if (e.status === 404) {
      // This is fine: no data found
      return {}
    }
    throw e
  }
}

export const fetchUserProfile: ApiCall<UserProfileDataResponse> = async () => {
  return await tryGet<UserProfile>(ENDPOINTS.userProfile)()
}

export const fetchWorker: ApiCall<WorkerDataResponse> = async () => {
  try {
    const workers: Worker[] = await _get(ENDPOINTS.worker)
    const result: WorkerDataResponse = toDictionaryById(workers)
    return result
  } catch (e) {
    if (e.status === 404) {
      // This is fine: no data found
      return {}
    }
    throw e
  }
}

export const fetchWhoami: ApiCall<Whoami> = async () => {
  const result: Whoami = await _get(ENDPOINTS.whoami)
  return result
}

const postMessage = async (message: MessageData): Promise<PostMessageResponse> => {
  if (message.news_post) {
    const formData = new FormData()
    for (const [key, value] of Object.entries(message.news_post)) {
      if (value === undefined) continue
      if (value instanceof File) {
        formData.append(key, value)
      } else if (value instanceof Date) {
        formData.append(key, value.toISOString())
      } else {
        formData.append(key, value.toString())
      }
    }
    for (const user_profile_id of message.user_profile_ids) {
      formData.append('user_profile_ids', user_profile_id.toString())
    }
    return await postForm(ENDPOINTS.newsPost, formData)
  }
  return await post(ENDPOINTS.message, message)
}

export const batchPostMessage = async (message: MessageData) => {
  const BATCH_LENGTH = 20
  const result: BatchPostMessageResponse = {
    message_data: message,
    success: [],
    failure: [],
    error: []
  }
  // Batch messages into groups for sending
  for (let index = 0; index < message.user_profile_ids.length; index += BATCH_LENGTH) {
    // Generate batch of user profile ids
    const user_profile_ids = message.user_profile_ids.slice(index, BATCH_LENGTH + index)
    // Send messages just for that batch
    try {
      const response = await postMessage({ ...message, user_profile_ids: user_profile_ids })
      result.success = [...result.success, ...response.success]
      result.failure = [...result.failure, ...response.failure]
    } catch (e) {
      result.error.push(e)
    }
  }
  return result
}

export const fetchQuestionAnswer = async () => {
  try {
    const questionsAnswered: QuestionAnswered[] = await _get(ENDPOINTS.questionAnswered)
    const parsed = questionsAnswered.map((q) => {
      return {
        ...q,
        answered_at: new Date(q.answered_at)
      }
    })
    const result: QuestionAnsweredDataResponse = toDictionaryById(parsed)

    return result
  } catch (e) {
    if (e.status === 404) {
      // This is fine: no data found
      return {}
    }
    throw e
  }
}

export const fetchLatestQuestionAnswer = async (workerIds: string[] | null) => {
  try {
    const queryParams = workerIds ? { worker_ids: workerIds } : null
    const questionsAnswered: { worker_id: string; latest_answer_date: string }[] = await post(
      ENDPOINTS.latestQuestionAnswered,
      queryParams
    )
    const parsed = questionsAnswered.map((q) => {
      return {
        id: q.worker_id,
        latestAnswerDate: q?.latest_answer_date ? new Date(q.latest_answer_date) : null
      }
    })

    const result: WorkerLatestAnswers = toDictionary(
      parsed,
      (r) => r.id,
      (r) => r.latestAnswerDate
    )

    return result
  } catch (e) {
    if (e.status === 404) {
      // This is fine: no data found
      return {}
    }
    throw e
  }
}

export const fetchUserProfileFilters = async (queryParams: QueryParams) => {
  const response: string[] = await _get(ENDPOINTS.workerFilter, queryParams)
  return response
}

export const fetchUploadSheetNames = async (storeGroupId: QueryParams) => {
  const response: UploadSheetNamesResponse = await _get(ENDPOINTS.uploadSheetNames, storeGroupId)
  return response
}

export const fetchRosterSheetNames = async (storeId: QueryParams) => {
  const response: UploadSheetNamesResponse = await _get(ENDPOINTS.rosterSheetNames, storeId)
  return response
}

export const fetchCachedPoints: ApiCall<CachedPointDataResponse> = async () => {
  try {
    const cachedPoints: CachedPoint[] = await _get(ENDPOINTS.cachedPoints)
    const result: CachedPointDataResponse = groupBy(cachedPoints, (item) => item['user_profile_id'])
    return result
  } catch (e) {
    if (e.status === 404) {
      // This is fine: no data found
      return {}
    }
    throw e
  }
}

export const fetchGoals = async (storeId: QueryParams) => {
  const response: GoalsDataResponse = await _get(ENDPOINTS.goals, storeId)
  return response
}

export const postGoals = async (goals: Goals) => await post(ENDPOINTS.goals, goals)

export const putGoals = async (goals: Goals) => await _put(ENDPOINTS.goals, goals)

export const postReward = async (reward: Reward) => await post(ENDPOINTS.reward, reward)

export const putReward = async (reward: Reward) => await _put(ENDPOINTS.reward, reward)

export const postStore = async (store: Store) => await post(ENDPOINTS.store, store)

export const putStore = async (store: Store) => await _put(ENDPOINTS.store, store)

export const postWorker = async (worker: Worker) => await post(ENDPOINTS.worker, worker)

export const putWorker = async (worker: Worker) => await _put(ENDPOINTS.worker, worker)

export const postFlour = async (flour: Flour) => await post(ENDPOINTS.flour, flour)

export const postUserProfileImpersonation = async (user_profile_id?: string) =>
  await post(ENDPOINTS.impersonate, { user_profile_id: user_profile_id })

export const postDropImpersonateUserProfile = async () => await post(ENDPOINTS.dropImpersonate, {})

export const postUserProfileBonusPoints = async (user_profile_id: string, bonus_points: number) => {
  const response = await post(ENDPOINTS.bonusPoints, {
    user_profile_id,
    bonus_points
  })
  return response
}
