/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { ChangeEvent, FormEvent, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState, MESSAGE_REDUCER, defaultMessageReducer } from '../reduxStore'
import { update } from '../reducerGenerator'
import { postMessageDataThunk } from '../thunks'
import { Text, TextArea, Card, Callout, Button, Pre } from '@blueprintjs/core'
import { ButtonsGroup } from './ButtonsGroup'
import { ToggleButton } from './common/ToggleButton'
import { UserList, UserListType } from './common/UserList'
import { OnarollCallout } from './common/Callout'
import { LiveMessageDialogBody } from './LiveMessageDialogBody'
import { MessageDialog } from './MessageDialog'
import { OnboardingMessageDialogBody } from './OnboardingMessageDialogBody'
import { LiveMessageConfig } from './LiveMessageConfig'
import { NewsPostDialogBody } from './NewsPostDialogBody'
import { NewsPostMessageConfig } from './NewsPostMessageConfig'
import { OnboardingMessageConfig } from './OnboardingMessageConfig'
import { MessageDataResponse, MessageVariable, MessageTextVariable, UserProfile } from '../types'

import * as STUBS from '../fixtures'

import AppleSVGIcon from '../assets/apple-icon.svg'
import WindowsSVGIcon from '../assets/windows-icon.svg'
import { groupBy, isASCII } from '../utils'

interface MessageTextProps {
  selectedUserProfiles: UserProfile[]
  onReset: () => void
  initState: MessageTextState
  type: MessageTextType
  data?: Record<string, string>
}

interface MessageTextState {
  textContent: string
  bonusPoints: number
  recipientsIsOpen: boolean
  enrollmentComplete: boolean
  newsPost?: {
    title: string
    deliveryDate: Date
    featured: boolean
    image?: File
    thumbnail?: File
  }
}

export enum MessageTextType {
  Live,
  Onboarding,
  NewsPosts
}

export const MessageText = (props: MessageTextProps) => {
  const { selectedUserProfiles, data, onReset, type, initState } = props

  const messageState = useSelector((state: RootState) => state[MESSAGE_REDUCER])
  const [state, setState] = useState<MessageTextState>({ ...initState })
  const [showCallout, setShowCallout] = useState<boolean>(true)
  const [showConfirmationDialog, setShowConfirmationDialog] = useState<boolean>(false)

  const dispatch = useDispatch()

  // Internal Getters
  const get_variable = (val: MessageTextVariable): string => {
    const variable = val.replace(/\$/g, '')

    if (!variable) return ''

    const mockData = STUBS.MOCK_WORKER

    let value = ''

    if (variable in mockData) {
      // @ts-ignore
      value = mockData[variable]
    }

    if (Array.isArray(value)) return value.slice(-1)

    return value
  }

  const variablesRegex = STUBS.VARIABLES.map((varGroup) =>
    Object.keys(varGroup)
      .map((variable) => variable.slice(1))
      .join('|')
  ).flat()

  const escapedVariableRegex = new RegExp(`\\$(?<variable>${variablesRegex})`, 'g')

  const escapedTextContent = state.textContent.replace(escapedVariableRegex, (x) =>
    get_variable(x as MessageTextVariable)
  )

  // Estimates message segments based on characters and likely encoding necessary to send message
  const printMessageSegments = (text: string) => {
    const ascii = isASCII(text)
    const encoding = ascii ? 'GSM' : 'UCS-2'
    const segmentLimit = ascii ? 153 : 67
    const segments = Math.ceil(text.length / segmentLimit)
    return `${segments} segments (${encoding} encoding)`
  }

  const printCharCount = (text: string) => {
    const count = text.length
    const description = `character${count !== 1 ? 's' : ''}`
    return `${count} ${description}`
  }

  const userProfileIds = Object.values(selectedUserProfiles).map((userProfile) => userProfile.id)

  let callout = (
    <div>
      {messageState.errors.map((error, index) => (
        <OnarollCallout
          key={index}
          title={error.status + ': Request failed'}
          description={error.message}
          intent="danger"
          className="admin-panel"
          onClose={() => setShowCallout(false)}
        />
      ))}
    </div>
  )

  // Update callout version if it was successful
  if (messageState.requestSucceeded) {
    const successMessages = messageState.response.success
    const failureMessages = messageState.response.failure

    // Organize messages by success or error reasons
    const successGroups = groupBy(
      successMessages,
      (messageDataResponse) => messageDataResponse.reason
    )
    const failureGroups = groupBy(
      failureMessages,
      (messageDataResponse) => messageDataResponse.reason
    )

    const successMessageElements = (
      <div>
        <p>
          Successful actions: <b>{successMessages.length}</b>
        </p>
        {Object.entries(successGroups).map(([reason, data], i) => {
          return (
            <UserList
              key={i}
              title={data.length + ' ' + reason}
              userIds={data.map(
                (messageDataResponse: MessageDataResponse) => messageDataResponse.user_profile_id
              )}
              type={UserListType.userProfile}
            />
          )
        })}
      </div>
    )
    const failureMessageElements = (
      <div>
        <p>
          Failed actions: <b>{failureMessages.length}</b>
        </p>
        {Object.entries(failureGroups).map(([reason, data], i) => {
          return (
            <UserList
              key={i}
              title={data.length + ' ' + reason}
              userIds={data.map(
                (messageDataResponse: MessageDataResponse) => messageDataResponse.user_profile_id
              )}
              type={UserListType.userProfile}
            />
          )
        })}
      </div>
    )

    const recipientList = (
      <div>
        {successMessageElements}
        {failureMessageElements}
      </div>
    )
    callout = (
      <OnarollCallout
        title="200: Request completed"
        description={recipientList}
        intent="success"
        className="admin-panel"
        onClose={() => setShowCallout(false)}
      />
    )
  }

  const responseCalloutSection = showCallout ? callout : <div />

  const emojisBar = STUBS.EMOJIS.map((emoji) => (
    <Button onClick={() => handleVariableClick(emoji)} className="emoji" text={emoji} key={emoji} />
  ))

  // Handlers
  const handleStringChange = (handler: (value: string) => void) => {
    return (event: React.FormEvent<HTMLElement>) => {
      return handler((event.target as HTMLInputElement).value)
    }
  }

  const handleBonusPointsValueChange = (value: number) => {
    setState({ ...state, bonusPoints: value })
    if (data) data['bonus_points'] = value.toString()
  }

  const handleEnrollmentCompleteChange = () => {
    setState({ ...state, enrollmentComplete: !state.enrollmentComplete })
  }

  const handleTitleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const prevState = state
    if (prevState.newsPost) {
      prevState.newsPost.title = event.target.value
    }
    setState({ ...prevState })
  }

  const handleImageChange = (event: FormEvent<HTMLInputElement>) => {
    const prevState = state
    const files = event.currentTarget.files
    if (prevState.newsPost && files && files.length) {
      prevState.newsPost.image = files[0]
    }
    setState({ ...prevState })
  }

  const handleThumbnailChange = (event: FormEvent<HTMLInputElement>) => {
    const prevState = state
    const files = event.currentTarget.files
    if (prevState.newsPost && files && files.length) {
      prevState.newsPost.thumbnail = files[0]
    }
    setState({ ...prevState })
  }

  const handleDeliveryDateChange = (selectedDate: Date) => {
    const prevState = state
    if (prevState.newsPost) {
      prevState.newsPost.deliveryDate = selectedDate
    }
    setState({ ...prevState })
  }

  const handleFeaturedChange = () => {
    const prevState = state
    if (prevState.newsPost) {
      prevState.newsPost.featured = !prevState.newsPost.featured
    }
    setState({ ...prevState })
  }

  const handleTextChange = handleStringChange((textContent: string) =>
    setState({ ...state, textContent })
  )

  const handleResetContentState = () => {
    dispatch(update(MESSAGE_REDUCER, 'RESET_MESSAGE_REQUEST', { ...defaultMessageReducer }))
    setState({ ...initState, recipientsIsOpen: state.recipientsIsOpen })
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleResetRecipientState = (_event: React.MouseEvent<HTMLElement>) => {
    dispatch(update(MESSAGE_REDUCER, 'RESET_MESSAGE_REQUEST', { ...defaultMessageReducer }))
    onReset()
  }

  const handleVariableClick = (replacement: string) =>
    setState({ ...state, textContent: state.textContent + ' ' + replacement })

  const handleOnVariableClick = (variable: string) => {
    setState((prevState: MessageTextState) => {
      prevState.textContent = state.textContent + ' ' + variable

      return { ...prevState }
    })
  }

  const handleMessageSubmission = async () => {
    let news_post = undefined
    if (state.newsPost) {
      news_post = {
        title: state.newsPost.title,
        message: state.textContent,
        featured: state.newsPost.featured,
        published_at: state.newsPost.deliveryDate,
        image: state.newsPost.image,
        thumbnail: state.newsPost.thumbnail
      }
    }

    await dispatch(
      postMessageDataThunk({
        text: state.textContent,
        bonus: state.bonusPoints,
        enrollment_complete: state.enrollmentComplete,
        news_post,
        user_profile_ids: userProfileIds
      })
    )

    setShowCallout(true)
    setShowConfirmationDialog(false)
  }
  const handleOnConfirmationDialogClose = () => setShowConfirmationDialog(false)

  const handleOnConfirmationDialogOpen = () => setShowConfirmationDialog(true)

  //Sub-components
  const heading = <h2 className="bp3-heading">Message</h2>

  const clearButtons = (
    <div className="clear-buttons-section">
      <Button
        onClick={handleResetContentState}
        icon="eraser"
        intent="danger"
        text="Clear Content"
      />
      <Button
        onClick={handleResetRecipientState}
        icon="eraser"
        intent="danger"
        text="Clear Recipients"
      />
    </div>
  )

  const livePreview = (
    <Card>
      <h3 className="bp3-heading">Live Preview</h3>
      <Text className="live-preview-text">{escapedTextContent}</Text>
      <div className="bp3-text-muted">
        <p>{printCharCount(escapedTextContent)}</p>
        <p>{printMessageSegments(escapedTextContent)}</p>
      </div>
    </Card>
  )
  const pointsTotal = state.bonusPoints * selectedUserProfiles.length
  const formSubmission = (
    <div className="mb-1">
      <UserList title="Show Recipients" userIds={userProfileIds} type={UserListType.userProfile} />
      {responseCalloutSection}
      <ButtonsGroup onSendButtonClick={handleOnConfirmationDialogOpen} />{' '}
    </div>
  )

  const textVariables = STUBS.VARIABLES.map((variable: MessageVariable, index: number) => (
    <Pre key={`variable-${index}`}>
      <ul className="bp3-list-unstyled">
        {Object.entries(variable).map(([value, description]) => (
          <li className="mb-1" key={value}>
            <Button onClick={() => handleOnVariableClick(value)} value={value} text={value} />
            {description}
          </li>
        ))}
      </ul>
    </Pre>
  ))

  const textVariablesButtons = (
    <ToggleButton iconName={'user'} text={'Text Variables'} isOpen={false}>
      <div>{textVariables}</div>
    </ToggleButton>
  )

  const messageTextArea = (
    <TextArea
      fill={true}
      onChange={handleTextChange}
      value={state.textContent}
      className="mb-1 message-text"
    />
  )

  const callouts = (
    <div className="mb-1">
      <Callout icon="info-sign" title={'More Emojis and Symbols'}>
        <div>
          <img src={AppleSVGIcon} className="icon-svg" alt="" />
          <kbd>⌘ command</kbd> + <kbd>^ control</kbd> + <kbd>⎵ space</kbd>
        </div>
        <div className="mt-1">
          <img src={WindowsSVGIcon} className="icon-svg" alt="" />
          <kbd>Windows Key</kbd> + <kbd>.</kbd>
        </div>
      </Callout>
    </div>
  )

  const emojis = <div className="mb-1">{emojisBar}</div>

  const getMessageConfiguration = (type: MessageTextType) => {
    if (state.newsPost) {
      return (
        <NewsPostMessageConfig
          title={state.newsPost.title}
          deliveryDate={state.newsPost.deliveryDate}
          featured={state.newsPost.featured}
          image={state.newsPost.image}
          thumbnail={state.newsPost.thumbnail}
          onTitleChange={handleTitleChange}
          onImageChange={handleImageChange}
          onThumbnailChange={handleThumbnailChange}
          onDeliveryDateChange={handleDeliveryDateChange}
          onFeaturedChange={handleFeaturedChange}
        />
      )
    }
    switch (type) {
      case MessageTextType.Live:
        return (
          <LiveMessageConfig
            bonusPoints={state.bonusPoints}
            bonusPointsTotal={pointsTotal}
            onValueChange={handleBonusPointsValueChange}
          />
        )

      case MessageTextType.Onboarding:
        return (
          <OnboardingMessageConfig
            enrollmentComplete={state.enrollmentComplete}
            bonusPoints={state.bonusPoints}
            bonusPointsTotal={pointsTotal}
            onValueChange={handleBonusPointsValueChange}
            onChange={handleEnrollmentCompleteChange}
          />
        )

      default:
        break
    }
  }

  const getConfirmationDialogBody = (type: MessageTextType) => {
    if (state.newsPost) {
      return (
        <NewsPostDialogBody
          title={state.newsPost.title}
          deliveryDate={state.newsPost.deliveryDate}
          featured={state.newsPost.featured}
          message={state.textContent}
          image={state.newsPost.image}
          thumbnail={state.newsPost.thumbnail}
        />
      )
    }
    switch (type) {
      case MessageTextType.Live:
        return (
          <LiveMessageDialogBody bonusPoints={state.bonusPoints} message={escapedTextContent} />
        )

      case MessageTextType.Onboarding:
        return (
          <OnboardingMessageDialogBody
            enrollmentComplete={state.enrollmentComplete}
            message={escapedTextContent}
          />
        )

      default:
        break
    }
  }

  const confirmationDialog = (
    <MessageDialog
      userProfileIds={userProfileIds}
      isLoading={messageState.isSending}
      isOpen={showConfirmationDialog}
      onClose={handleOnConfirmationDialogClose}
      onSubmit={handleMessageSubmission}
    >
      {getConfirmationDialogBody(type)}
    </MessageDialog>
  )

  return (
    <div className="message-component">
      {confirmationDialog}
      {heading}
      {getMessageConfiguration(type)}
      {messageTextArea}
      {emojis}
      {callouts}
      {textVariablesButtons}
      {formSubmission}
      {livePreview}
      {clearButtons}
    </div>
  )
}
