// React
import React, { useState } from 'react'
import { useSelector } from 'react-redux'

// Redux
import { useDispatch } from 'react-redux'

// Thunks
import {
  fetchWorkerThunk,
  putWorkerDataThunk,
  postWorkerDataThunk,
  postGenerateBonusPointsThunk
} from '../thunks'

// Components
import { Card, FormGroup, InputGroup, Label, NumericInput, Switch } from '@blueprintjs/core'
import { SubmitButton, Action } from './SubmitButton'
import { ToggleButton } from '../components/common/ToggleButton'
import { DateTimePickerField } from './common/Fields'
import { GenericSelector } from './GenericSelector'

// Types
import {
  Store,
  StoreDataResponse,
  UserProfile,
  UserProfileDataResponse,
  Worker,
  WorkerDataResponse
} from '../types'
import { SelectionItem } from './GenericSelector'
import { DATA_REDUCER, RootState } from '../reduxStore'
import UserCachedPointsWidget from './UserCachedPointsWidget'

// Defaults
const defaultState: { [key in keyof Worker]: string | boolean } = {
  id: '',
  first_name: '',
  last_name: '',
  outside_id: '',
  phone: '',
  active: false,
  store_id: '',
  onboarding_modal_seen: false,
  enrolled_on: Date(),
  user_profile_id: ''
}

interface GenerateBonusPointsProps {
  userProfileId: string
}

const GenerateBonusPoints = ({ userProfileId }: GenerateBonusPointsProps) => {
  const dispatch = useDispatch()
  const [bonusPoints, setBonusPoints] = useState<number>()

  const requestGenerateBonusPoints = async () => {
    if (bonusPoints || bonusPoints === 0) {
      const request = await dispatch(postGenerateBonusPointsThunk(userProfileId, bonusPoints))
      return request
    }
  }

  const onChange = (valAsNumber: number) => setBonusPoints(valAsNumber)

  const requestPayload = {
    user_profile_id: userProfileId,
    bonus_points: bonusPoints
  }

  return (
    <Card className="mtb-2 mrl-1">
      <FormGroup label="Generate Bonus points:" labelFor="bonus-points">
        <NumericInput
          min={0}
          max={1000}
          value={bonusPoints}
          onValueChange={onChange}
          name="bonus-points"
        />
        <SubmitButton
          className="mt-1"
          object={requestPayload}
          action={Action.update}
          apiCall={requestGenerateBonusPoints}
        />
      </FormGroup>
    </Card>
  )
}

export const WorkerSidebarForm = (props: Worker) => {
  //Local State
  const initState = props ?? defaultState
  const [state, setState] = useState<Worker>({ ...initState })

  const userProfiles: UserProfileDataResponse = useSelector(
    (state: RootState) => state[DATA_REDUCER].userProfiles
  )
  const stores: StoreDataResponse = useSelector((state: RootState) => state[DATA_REDUCER].stores)
  const workers: WorkerDataResponse = useSelector((state: RootState) => state[DATA_REDUCER].workers)

  // Persistence and data store operations
  const dispatch = useDispatch()

  const dispatchUpdateOperation = async () => {
    const request = await dispatch(putWorkerDataThunk(state))
    dispatch(fetchWorkerThunk())
    return request
  }

  const dispatchCreateOperation = async () => {
    const request = await dispatch(postWorkerDataThunk(state))
    dispatch(fetchWorkerThunk())
    return request
  }

  // Components
  const createInputGroup = (title: string, key: keyof Worker, type = 'text') => {
    const value = state[key] ? String(state[key]) : undefined
    return (
      <div className="mtb-2">
        <Label>{title}</Label>
        <InputGroup
          type={type}
          value={value}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setState({ ...state, [key]: e.currentTarget.value })
          }
        />
      </div>
    )
  }

  const createSwitchGroup = (title: string, key: 'active' | 'onboarding_modal_seen') => {
    return (
      <div className="mtb-1">
        <Label>{title}</Label>
        <Switch
          innerLabelChecked="Enabled"
          innerLabel="Disabled"
          checked={state[key]}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setState({ ...state, [key]: e.currentTarget.checked })
          }
        />
      </div>
    )
  }

  const storeOptions: SelectionItem<Store>[] = Object.values(stores).map((store: Store) => ({
    id: store.id,
    title: store.name,
    item: store
  }))

  const storeField = (
    <div className="mtb-2">
      <Label>At store</Label>
      <GenericSelector
        items={storeOptions}
        buttonText={stores[state.store_id]?.name || 'Store'}
        onItemSelect={(item) => {
          setState((prevState) => {
            prevState.store_id = item.id
            return { ...prevState }
          })
        }}
      />
    </div>
  )

  const userProfileOptions: SelectionItem<UserProfile>[] = Object.values(userProfiles).map(
    (profile: UserProfile) => ({
      id: profile.id,
      title: profile.display_name,
      item: profile
    })
  )

  const userProfileField = (
    <div className="mtb-2">
      <Label>User Profile</Label>
      <GenericSelector
        items={userProfileOptions}
        buttonText={userProfiles[state.user_profile_id]?.display_name || 'User Profile'}
        onItemSelect={(item) => {
          setState((prevState) => {
            prevState.user_profile_id = item.id
            return { ...prevState }
          })
        }}
      />
    </div>
  )

  const firstNameField = createInputGroup('First Name', 'first_name')

  const lastNameField = createInputGroup('Last Name', 'last_name')

  const outsideIdField = createInputGroup('Outside ID', 'outside_id')

  const phoneNumberField = createInputGroup('Phone Number', 'phone', 'tel')

  const activeField = createSwitchGroup('Active?', 'active')

  const enrolledOnField = (
    <div className="mtb-2">
      <DateTimePickerField
        label="Enrolled On"
        value={state.enrolled_on}
        update={(value) =>
          setState((prevState) => {
            prevState.enrolled_on = value
            return { ...prevState }
          })
        }
        iconName="calendar"
      />
    </div>
  )

  const bonusPointsField = () => {
    const { user_profile_id } = state
    if (!user_profile_id) return null
    return <GenerateBonusPoints userProfileId={user_profile_id} />
  }

  const toggleableWorkerInfo = () => {
    const selectedWorker = workers[state.id]
    if (!selectedWorker) return null
    const dateContent = (() => {
      if (!selectedWorker.enrolled_on) return null
      const date = new Date(selectedWorker.enrolled_on)
      return (
        <div>
          <b>Enrolled on:</b> {date.toLocaleDateString()} {date.toLocaleTimeString()}
        </div>
      )
    })()
    return (
      <div>
        <ToggleButton
          iconName="user"
          text={`Show more info about ${selectedWorker.first_name}`}
          isOpen={false}
        >
          {dateContent}
          <UserCachedPointsWidget
            userProfileId={selectedWorker.user_profile_id}
            workerId={selectedWorker.id}
          />
        </ToggleButton>
      </div>
    )
  }

  const actions = (
    <div className="mt-2">
      {state.id ? (
        <SubmitButton object={state} action={Action.update} apiCall={dispatchUpdateOperation} />
      ) : (
        <SubmitButton object={state} action={Action.create} apiCall={dispatchCreateOperation} />
      )}
    </div>
  )

  return (
    <FormGroup helperText="." labelFor="text-input">
      <h2>{state.first_name ?? 'Add a new Worker'}</h2>
      {toggleableWorkerInfo()}
      {userProfileField}
      {storeField}
      {firstNameField}
      {lastNameField}
      {outsideIdField}
      {phoneNumberField}
      {activeField}
      {enrolledOnField}
      {actions}
      {bonusPointsField()}
    </FormGroup>
  )
}
