import { datadogLogs } from '@datadog/browser-logs'
import env from 'utils/runtimeEnv'
import { logInfo } from 'utils/logger'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { reduxStore } from './reduxStore'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import './App.css'

const hasEnvSetup = Boolean(
  env.REACT_APP_DATADOG_APP_ID &&
    env.REACT_APP_DATADOG_CLIENT_TOKEN &&
    env.REACT_APP_DATADOG_SERVICE
)

// Datadog initialization
const site = 'datadoghq.com'
const sampleRate = 100
const clientToken = env.REACT_APP_DATADOG_CLIENT_TOKEN ?? 'dd-client-token-fill-me-in'
const service = env.REACT_APP_DATADOG_SERVICE ?? 'dd-service-fill-me-in'
const nodeEnv = process.env.NODE_ENV

const ddLogsConfig = {
  clientToken,
  site,
  service,
  env: nodeEnv,
  sampleRate,
  forwardErrorsToLogs: true
}

if (hasEnvSetup) {
  logInfo('Initializing DD Logs config', ddLogsConfig)
  datadogLogs.init(ddLogsConfig)
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={reduxStore}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
