import React from 'react'
import { Card, NumericInput } from '@blueprintjs/core'

import { convertPointsToDollars } from '../utils'

interface LiveMessageConfigProps {
  bonusPoints: number
  bonusPointsTotal: number
  onValueChange: (value: number) => void
}

export const LiveMessageConfig = (props: LiveMessageConfigProps) => {
  const { onValueChange, bonusPoints, bonusPointsTotal } = props

  return (
    <Card className="mtb-2">
      <label className="bp3-label .modifier mb-1">
        Bonus Points <span className="bp3-text-muted">(optional)</span>
      </label>

      <NumericInput
        placeholder="Enter bonus points..."
        onValueChange={onValueChange}
        value={bonusPoints}
      />
      <div className="mt-1">
        <div>
          <b>{convertPointsToDollars(bonusPointsTotal)}</b> Total
        </div>
        <div>
          <b>{convertPointsToDollars(bonusPoints)}</b> Per Team Member
        </div>
      </div>
    </Card>
  )
}
