import React from 'react'
import { DatePicker } from '@blueprintjs/datetime'

const MAX_DATE = new Date(2050, 12, 31)

interface DateSelectorProps {
  className?: string
  date: Date | null
  modifiers?: { [name: string]: (date: Date) => boolean }
  onChange: (selectedDate: Date, isUserChange: boolean) => void
}

export const DateSelector = (props: DateSelectorProps) => {
  const { className, date, modifiers, onChange } = props

  return (
    <DatePicker
      className={className}
      modifiers={modifiers}
      onChange={onChange}
      showActionsBar={true}
      maxDate={MAX_DATE}
      value={date}
    />
  )
}
