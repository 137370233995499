import { FormGroup, MenuItem } from '@blueprintjs/core'
import { Suggest } from '@blueprintjs/select'

import React, { useState, Fragment } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { isEmpty } from 'lodash'

import { DateTimePickerField } from '../../components/common/Fields'
import { GenericMultiSelector, SelectionItem } from '../../components/GenericSelector'
import { DATA_REDUCER, RootState } from '../../reduxStore'
import { Store, StoreDataResponse, StoreGroup, StoreGroupDataResponse , UploadSheetNamesResponse} from '../../types'
import {
  fetchUploadSheetNamesThunk
} from '../../thunks'

import { DataUpload } from './DataUpload'

export const GenericDataImport = () => {
  const dispatch = useDispatch()
  const [selectedStoreGroups, setSelectedStoreGroups] = useState<StoreGroup[]>([])
  const [importDate, setImportDate] = useState<Date | null>(null)
  const [selectedTabName, setSelectedTabName] = useState<string | string[] | null>(null)

  const storeGroupResponse: StoreGroupDataResponse = useSelector(
    (state: RootState) => state[DATA_REDUCER].storeGroups
  )

  const uploadSheetNamesResponse: UploadSheetNamesResponse = useSelector(
    (state: RootState) => state[DATA_REDUCER].uploadSheetNames
  )

  const tabNames = !isEmpty(uploadSheetNamesResponse) ? Object.values(uploadSheetNamesResponse)[0] : []

  const activeStoreGroups: StoreGroup[] = Object.values(storeGroupResponse).filter(
    (storeGroup: StoreGroup) => !!storeGroup.active
  )

  const storeResponse: StoreDataResponse = useSelector(
    (state: RootState) => state[DATA_REDUCER].stores
  )
  const stores: Store[] = Object.values(storeResponse)
  const selectedStores: Store[] = selectedStoreGroups
    .map((storeGroup: StoreGroup) => {
      return stores.filter((store) => store.group_id === storeGroup.id)
    })
    .flat()

  const storeGroupItems = activeStoreGroups.map((storeGroup: StoreGroup) => ({
    id: storeGroup.id,
    title: storeGroup.name,
    item: storeGroup
  }))

  const selectedStoreGroupItems = selectedStoreGroups.map((storeGroup: StoreGroup) => ({
    id: storeGroup.id,
    title: storeGroup.name,
    item: storeGroup
  }))

  const handleStoreGroupSelection = (item: SelectionItem<StoreGroup>) => {
    dispatch(fetchUploadSheetNamesThunk({store_group_id: item.id}))
    setSelectedStoreGroups((prev: StoreGroup[]) => Array.from(new Set([...prev, item.item])))
  }

  const handleStoreGroupRemoval = (item: SelectionItem<StoreGroup>) => {
    setSelectedStoreGroups(
      selectedStoreGroups.filter(
        (selectedStoreGroup: StoreGroup) => item.item !== selectedStoreGroup
      )
    )
  }

  const getDatePicker = () => {
    const selectedStoresNeedManualDate = selectedStoreGroups.some(
      (storeGroup: StoreGroup) => storeGroup.manual_date
    )
    return (
      <Fragment>
        {selectedStoresNeedManualDate && (
          <DateTimePickerField
            label={'Import Date'}
            value={importDate}
            update={setImportDate}
            iconName={'calendar'}
          />
        )}
      </Fragment>
    )
  }

  return (
    <div>
      <FormGroup label="Store Groups">
        <GenericMultiSelector
          items={storeGroupItems}
          selectedItems={selectedStoreGroupItems}
          onItemSelect={handleStoreGroupSelection}
          onItemRemove={handleStoreGroupRemoval}
          placeholder={'Select Store Group'}
        />
      </FormGroup>
      {getDatePicker()}
      {!isEmpty(tabNames) && !isEmpty(selectedStoreGroups) &&
      <FormGroup label="Select GoogleSheet Tab this data should be posted to">
        <Suggest
          items={tabNames}
          inputValueRenderer={(item) => item.toString()}
          itemRenderer={(item, { handleClick, modifiers }) => {
            return (
              <MenuItem
                active={modifiers.active}
                disabled={modifiers.disabled}
                onClick={handleClick}
                text={item}
              />
            )
          }}
          onItemSelect={(tab) => setSelectedTabName(tab)}
          inputProps={{placeholder: 'Select Sheet Tab Name'}}
        />
      </FormGroup>
      }
      <DataUpload
        storeGroups={selectedStoreGroups}
        stores={selectedStores}
        date={importDate}
        selectedTabName={selectedTabName}
      />
    </div>
  )
}
