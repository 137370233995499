// https://docs.datadoghq.com/logs/log_collection/javascript/
import { datadogLogs } from '@datadog/browser-logs'

type DD_LOG_PARAMS = Record<string, unknown>

export const logInfo = (text: string, params: DD_LOG_PARAMS = {}) =>
  datadogLogs.logger.info(text, params)

export const logWarning = (text: string, params: DD_LOG_PARAMS = {}) =>
  datadogLogs.logger.warn(text, params)

export const logError = (text: string, params: DD_LOG_PARAMS = {}) =>
  datadogLogs.logger.error(text, params)
