import * as React from 'react'
import { IconName } from '@blueprintjs/icons'
import { Button, ButtonGroup, Spinner } from '@blueprintjs/core'
import { IProps } from '@blueprintjs/core'

export interface ButtonsGroupProps extends IProps {
  hideIcon?: boolean
  isLoading?: boolean
  iconName?: IconName
  onSendButtonClick?: () => void
  text?: string
}

const loadingPostfix = (text: string, isLoading: boolean) => {
  if (!text || !isLoading) {
    return null
  }
  const postfix = 'ing...'
  return text === text.toUpperCase() ? postfix.toUpperCase() : postfix
}

export const ButtonsGroup = ({
  hideIcon,
  isLoading = false,
  iconName = 'send-message',
  onSendButtonClick,
  text = 'SEND'
}: ButtonsGroupProps) => {
  const icon = hideIcon ? null : iconName
  const buttonStatus = isLoading ? <Spinner size={Spinner.SIZE_SMALL} /> : icon
  return (
    <ButtonGroup style={{ minWidth: 200 }}>
      <Button onClick={onSendButtonClick} icon={buttonStatus}>
        {text}
        {loadingPostfix(text, isLoading)}
      </Button>
    </ButtonGroup>
  )
}
