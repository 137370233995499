import React from 'react'
import { Label, NumericInput, TextArea } from '@blueprintjs/core'

interface BonusPointsDialogBodyProps {
  bonusPoints: number
  message: string
}

export const LiveMessageDialogBody = (props: BonusPointsDialogBodyProps) => {
  const { bonusPoints, message } = props

  return (
    <div>
      <div className="mtb-1">
        <Label>Bonus Points</Label>
        <NumericInput disabled={true} value={bonusPoints} />
      </div>

      <div className="mtb-1">
        <Label>Text Message</Label>
        <TextArea fill={true} disabled={true} value={message} />
      </div>
    </div>
  )
}
