import { Button, Callout, Card, Colors, Elevation, Intent, Label, Tag } from '@blueprintjs/core'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { fetchUserProfileFilters } from '../api/backend'
import { ToggleButton } from '../components/common/ToggleButton'
import { DataCard } from '../components/DataCard'
import { GenericMultiSelector, GenericSelector, SelectionItem } from '../components/GenericSelector'
import { MessageText, MessageTextType } from '../components/MessageText'
import { USER_SETTINGS as userSettings } from '../fixtures'
import Main from '../layouts/Main'
import { DATA_REDUCER, RootState } from '../reduxStore'
import {
  Store,
  StoreDataResponse,
  StoreGroup,
  StoreGroupDataResponse,
  UserProfile,
  UserProfileDataResponse,
  UserSetting,
  WorkerDataResponse
} from '../types'
import { Routes } from '../urls/router-paths'
import { isSmsEnabled } from '../utils'

type SelectionState<T> = SelectionItem<T>

enum UserTypeEnum {
  All = '',
  TeamMember = 'team_member',
  TeamLeader = 'team_leader'
}

enum OnboardingSeenEnum {
  none = 'none',
  yes = 'yes',
  no = 'no'
}

const makeEnumSelection = <T,>(enumValue: T, title: string): SelectionItem<T> => {
  return { id: String(enumValue), title: title, item: enumValue }
}

const Live = () => {
  // Initialize selections and filters
  const [filteredUserProfiles, setFilteredUserProfiles] = useState<UserProfile[]>([])
  const [userProfilesSelected, setUserProfilesSelected] = useState<UserProfile[]>([])
  const [storesSelected, setStoresSelected] = useState<Store[]>([])
  const [storeGroupsSelected, setStoreGroupsSelected] = useState<StoreGroup[]>([])
  const [messagesFrequencySelected, setMessagesFrequencySelected] = useState<UserSetting[]>([])
  const [manuallyRemovedUserProfileIds, setManuallyRemovedUserProfileIds] = useState<string[]>([])

  // Initialize intent for the filter update button
  const [buttonIntent, setButtonIntent] = useState<Intent>(Intent.PRIMARY)
  const [error, setError] = useState<string>('')

  const workerResponse: WorkerDataResponse = useSelector(
    (state: RootState) => state[DATA_REDUCER].workers
  )
  const userProfileResponse: UserProfileDataResponse = useSelector(
    (state: RootState) => state[DATA_REDUCER].userProfiles
  )
  const storeResponse: StoreDataResponse = useSelector(
    (state: RootState) => state[DATA_REDUCER].stores
  )
  const storeGroupResponse: StoreGroupDataResponse = useSelector(
    (state: RootState) => state[DATA_REDUCER].storeGroups
  )
  const messagesFrequencyResponse = userSettings.messages_frequency

  const userProfileItems: SelectionItem<UserProfile>[] = Object.values(userProfileResponse)
    .filter((userProfile: UserProfile) => userProfile.enrolled_end)
    .map((userProfile: UserProfile) => ({
      id: userProfile.id,
      title: userProfile.full_name,
      item: userProfile
    }))

  const storeItems: SelectionItem<Store>[] = Object.values(storeResponse)
    .filter((store: Store) => store.active)
    .map((store: Store) => ({
      id: store.id,
      title: store.name,
      item: store
    }))

  const storeGroupItems: SelectionItem<StoreGroup>[] = Object.values(storeGroupResponse)
    .filter((storeGroup: StoreGroup) => storeGroup.active)
    .map((storeGroup: StoreGroup) => ({
      id: storeGroup.id,
      title: storeGroup.name,
      item: storeGroup
    }))

  // User settings
  const messagesFrequencyItems: SelectionItem<UserSetting>[] = Object.values(
    messagesFrequencyResponse
  ).map((messageFrequency: UserSetting) => ({
    id: messageFrequency.id,
    title: messageFrequency.name,
    label: messageFrequency.label,
    item: messageFrequency
  }))

  const selectedUserProfileItems: SelectionItem<UserProfile>[] = userProfilesSelected.map(
    (userProfile: UserProfile) => ({
      id: userProfile.id,
      title: userProfile.full_name,
      item: userProfile
    })
  )

  const selectedStoreItems: SelectionItem<Store>[] = storesSelected.map((store: Store) => ({
    id: store.id,
    title: store.name,
    item: store
  }))

  const selectedStoreGroupItems: SelectionItem<StoreGroup>[] = storeGroupsSelected.map(
    (storeGroup: StoreGroup) => ({
      id: storeGroup.id,
      title: storeGroup.name,
      item: storeGroup
    })
  )

  const selectedMessagesFrequencyItems: SelectionItem<
    UserSetting
  >[] = messagesFrequencySelected.map((messageFrequency: UserSetting) => ({
    id: messageFrequency.id,
    title: messageFrequency.name,
    label: messageFrequency.label,
    item: messageFrequency
  }))

  // User Types Filter
  const userTypesOptions = [
    makeEnumSelection(UserTypeEnum.All, 'All'),
    makeEnumSelection(UserTypeEnum.TeamLeader, 'Team Leader'),
    makeEnumSelection(UserTypeEnum.TeamMember, 'Team Member')
  ]

  const [userType, setUserType] = useState<SelectionState<UserTypeEnum>>(userTypesOptions[0])

  // Onboarding Seen Filter
  const onboardingSeenFilterOptions = [
    makeEnumSelection(OnboardingSeenEnum.none, 'None'),
    makeEnumSelection(OnboardingSeenEnum.yes, 'Yes'),
    makeEnumSelection(OnboardingSeenEnum.no, 'No')
  ]

  const [onboardingSeen, setOnboardingSeen] = useState<SelectionState<OnboardingSeenEnum>>(
    onboardingSeenFilterOptions[0]
  )

  // Refresh filtered data when state changes
  const updateFilters = async () => {
    // Reset manually removed user profiles
    setManuallyRemovedUserProfileIds([])
    const queryParams = {
      store: storesSelected.map((store) => store.id),
      store_group: storeGroupsSelected.map((storeGroup) => storeGroup.id),
      messages_frequency: messagesFrequencySelected.map((messageFrequency) => messageFrequency.id),
      user_type: userType.id
    }

    // Onboarding seen Param
    if (onboardingSeen.id !== OnboardingSeenEnum.none) {
      Object.assign(queryParams, {
        onboarding_seen: onboardingSeen.id
      })
    }

    let result: string[] = []
    try {
      result = await fetchUserProfileFilters(queryParams)
      setError('')
    } catch (error) {
      setError(error?.status + ': ' + error?.message)
    }

    // Apply API driven filters
    let updatedFilteredUserProfiles = Object.values(
      userProfileResponse
    ).filter((userProfile: UserProfile) => result.includes(userProfile.id))

    if (userProfilesSelected.length) {
      // Apply user profile selected filter if defined
      const userProfilesSelectedIds = userProfilesSelected.map((userProfile) => userProfile.id)

      updatedFilteredUserProfiles = updatedFilteredUserProfiles.filter((userProfile) => {
        return userProfilesSelectedIds.includes(userProfile.id)
      })
    }

    setFilteredUserProfiles(updatedFilteredUserProfiles)

    setButtonIntent(Intent.PRIMARY)
  }

  const userProfileSelector = (
    <GenericMultiSelector
      data-testid="user-profile-selector"
      items={userProfileItems}
      selectedItems={selectedUserProfileItems}
      placeholder="User Profile"
      onItemSelect={(item: SelectionItem<UserProfile>) => {
        setUserProfilesSelected((prev: UserProfile[]) => {
          setButtonIntent(Intent.DANGER)
          return Array.from(new Set([...prev, item.item]))
        })
      }}
      onItemRemove={(item: SelectionItem<UserProfile>) => {
        setUserProfilesSelected((prev: UserProfile[]) => {
          setButtonIntent(Intent.DANGER)
          return prev.filter((comp: UserProfile) => comp !== item.item)
        })
      }}
    />
  )

  const storeSelector = (
    <GenericMultiSelector
      items={storeItems}
      selectedItems={selectedStoreItems}
      placeholder="Store"
      onItemSelect={(item: SelectionItem<Store>) => {
        setStoresSelected((prev: Store[]) => {
          setButtonIntent(Intent.DANGER)
          return Array.from(new Set([...prev, item.item]))
        })
      }}
      onItemRemove={(item: SelectionItem<Store>) => {
        setStoresSelected((prev: Store[]) => {
          setButtonIntent(Intent.DANGER)
          return prev.filter((comp: Store) => comp !== item.item)
        })
      }}
    />
  )

  const storeGroupSelector = (
    <GenericMultiSelector
      items={storeGroupItems}
      selectedItems={selectedStoreGroupItems}
      placeholder="Store Group"
      onItemSelect={(item: SelectionItem<StoreGroup>) => {
        setStoreGroupsSelected((prev: StoreGroup[]) => {
          setButtonIntent(Intent.DANGER)
          return Array.from(new Set([...prev, item.item]))
        })
      }}
      onItemRemove={(item: SelectionItem<StoreGroup>) => {
        setStoreGroupsSelected((prev: StoreGroup[]) => {
          setButtonIntent(Intent.DANGER)
          return prev.filter((comp: StoreGroup) => comp !== item.item)
        })
      }}
    />
  )

  const userTypesSelector = (
    <div className="mtb-2">
      <Label>User Type</Label>
      <GenericSelector
        items={userTypesOptions}
        buttonText={userType?.title || 'None'}
        onItemSelect={(item) => {
          setUserType(item)
          setButtonIntent(Intent.DANGER)
        }}
      />
    </div>
  )

  const messageFrequencySelector = (
    <GenericMultiSelector
      items={messagesFrequencyItems}
      selectedItems={selectedMessagesFrequencyItems}
      placeholder="Text Preference"
      onItemSelect={(item: SelectionItem<UserSetting>) => {
        setMessagesFrequencySelected((prev: UserSetting[]) => {
          setButtonIntent(Intent.DANGER)
          return Array.from(new Set([...prev, item.item]))
        })
      }}
      onItemRemove={(item: SelectionItem<UserSetting>) => {
        setMessagesFrequencySelected((prev: UserSetting[]) => {
          setButtonIntent(Intent.DANGER)
          return prev.filter((comp: UserSetting) => comp !== item.item)
        })
      }}
    />
  )

  const onboardingSeenSelector = (
    <div className="mtb-2">
      <Label>Onboarding Cards Seen</Label>
      <GenericSelector
        items={onboardingSeenFilterOptions}
        buttonText={onboardingSeen?.title || 'None'}
        onItemSelect={(item) => {
          setOnboardingSeen(item)
          setButtonIntent(Intent.DANGER)
        }}
      />
    </div>
  )

  const toggleIndividualUserProfile = (userProfile: UserProfile) => {
    const updatedRemovedList = manuallyRemovedUserProfileIds

    const userProfileIndex: number = manuallyRemovedUserProfileIds.indexOf(userProfile.id)

    // Add worker id to array if it is not found
    if (userProfileIndex === -1) {
      updatedRemovedList.push(userProfile.id)
    } else {
      updatedRemovedList.splice(userProfileIndex, 1)
    }

    setManuallyRemovedUserProfileIds([...manuallyRemovedUserProfileIds])
  }

  const renderUserProfileInfo = (userProfile: UserProfile) => {
    const smsEnabled = isSmsEnabled(userProfile)

    // Get stores for workers associated to user profile
    const workerStores = Object.values(workerResponse)
      .filter((worker) => worker.user_profile_id === userProfile.id)
      .map((worker) => storeResponse[worker.store_id]?.name)
      .join(', ')

    const removed = manuallyRemovedUserProfileIds.includes(userProfile.id)
    const color = removed ? Colors.WHITE : Colors.BLUE5

    return (
      <Card
        interactive={true}
        elevation={Elevation.TWO}
        style={{ background: color }}
        className="databar"
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onClick={(_event: React.MouseEvent<HTMLDivElement>) => {
          toggleIndividualUserProfile(userProfile)
        }}
      >
        <div className="databar-text">
          <p>
            <b>{userProfile.full_name}</b>
          </p>
          <p>{workerStores}</p>
        </div>
        <div className="databar-text">
          <Tag intent={smsEnabled ? 'success' : 'danger'}>
            {smsEnabled ? 'SMS Enabled' : 'SMS Disabled'}
          </Tag>
          <p>Phone: {userProfile.phone}</p>
        </div>
      </Card>
    )
  }

  const recipientsList = (
    <ToggleButton iconName={'user'} text={'Show Recipients'} isOpen={false}>
      <div>
        {filteredUserProfiles.map((userProfile: UserProfile) => {
          return renderUserProfileInfo(userProfile)
        })}
      </div>
    </ToggleButton>
  )

  // Remove manually removed user profiles from the filtered set
  const filteredWithoutRemoved = filteredUserProfiles.filter((userProfile) => {
    return !manuallyRemovedUserProfileIds.includes(userProfile.id)
  })

  const smsEnabled = filteredWithoutRemoved.filter((userProfile) => {
    return isSmsEnabled(userProfile)
  })

  const userProfileSMSEnabledDataCard = (
    <DataCard title="Team Members (SMS Enabled)" value={smsEnabled.length} />
  )
  const userProfileSMSDisabledDataCard = (
    <DataCard
      title="Team Members (SMS Disabled)"
      value={filteredWithoutRemoved.length - smsEnabled.length}
    />
  )

  const filterText = buttonIntent === Intent.PRIMARY ? 'Synced' : 'Update'
  const updateFiltersButton = (
    <Button text={filterText} intent={buttonIntent} onClick={updateFilters} />
  )
  const errorCallout = error.length > 0 ? <Callout title={error} intent={Intent.DANGER} /> : <div />

  const handleOnMessageReset = () => {
    setUserProfilesSelected([])
    setStoresSelected([])
    setStoreGroupsSelected([])
    setMessagesFrequencySelected([])
    setOnboardingSeen(onboardingSeenFilterOptions[0])
    setFilteredUserProfiles([])
    setButtonIntent(Intent.PRIMARY)
  }

  return (
    <Main activePage={Routes.live}>
      <div className="menu-nav">
        {userProfileSelector}
        {storeSelector}
        {storeGroupSelector}
        {userTypesSelector}
        {messageFrequencySelector}
        {onboardingSeenSelector}
        {updateFiltersButton}
        {errorCallout}
      </div>
      <div className="admin-content-wrapper">
        <div className="admin-page">
          <div className="infobar">
            {userProfileSMSEnabledDataCard}
            {userProfileSMSDisabledDataCard}
          </div>
          {recipientsList}
          <MessageText
            onReset={handleOnMessageReset}
            selectedUserProfiles={filteredWithoutRemoved}
            initState={{
              textContent: '',
              bonusPoints: 0,
              recipientsIsOpen: false,
              enrollmentComplete: false
            }}
            type={MessageTextType.Live}
          />
        </div>
      </div>
    </Main>
  )
}

export default Live
