import { useSelector } from 'react-redux'
import { DATA_REDUCER, RootState } from '../reduxStore'
import {
  Reward,
  RewardDataResponse,
  StoreDataResponse,
  StoreGroupDataResponse,
  Worker,
  WorkerDataResponse,
  UserProfileDataResponse
} from '../types'

export const RewardData = () => {
  const rewards: RewardDataResponse = useSelector((state: RootState) => state[DATA_REDUCER].rewards)
  const stores: StoreDataResponse = useSelector((state: RootState) => state[DATA_REDUCER].stores)
  const storeGroups: StoreGroupDataResponse = useSelector(
    (state: RootState) => state[DATA_REDUCER].storeGroups
  )

  const resolveReferences = (reward: Reward) => {
    reward.stores = []
    reward.storeGroups = []

    if (reward.store_ids) {
      reward.store_group_ids = []
      reward.store_ids.forEach((storeID) => {
        // Map store object to reward if it exists
        const store = stores[storeID]
        if (store) {
          reward.stores.push(store)
          // Map store group object to reward if it exists
          const storeGroup = storeGroups[store.group_id]
          const storeGroupIsUnique = storeGroup
            ? reward.store_group_ids.indexOf(storeGroup.id) === -1
            : false

          if (storeGroupIsUnique) {
            reward.store_group_ids.push(storeGroup.id)
            reward.storeGroups.push(storeGroup)
          }
        }
      })
    }

    return reward
  }
  return rewards ? Object.values(rewards).map(resolveReferences) : []
}

// Return an array of worker data with foreign keys resolved to objects
export const WorkerData = () => {
  const workers: WorkerDataResponse = useSelector((state: RootState) => state[DATA_REDUCER].workers)
  const stores: StoreDataResponse = useSelector((state: RootState) => state[DATA_REDUCER].stores)

  const resolveReferences = (worker: Worker) => {
    worker.store = stores[worker.store_id]
    return worker
  }

  return workers ? Object.values(workers).map(resolveReferences) : []
}

export const StoreData = () => {
  const stores: StoreDataResponse = useSelector((state: RootState) => state[DATA_REDUCER].stores)

  return stores ? Object.values(stores) : []
}

export const UserProfileData = () => {
  const workers: WorkerDataResponse = useSelector((state: RootState) => state[DATA_REDUCER].workers)
  const userProfiles: UserProfileDataResponse = useSelector(
    (state: RootState) => state[DATA_REDUCER].userProfiles
  )

  Object.values(workers).forEach((worker: Worker) => {
    const userProfileId = worker.user_profile_id
    const userProfile = userProfiles[userProfileId]

    if (!userProfileId || !userProfile) return

    if (!userProfile.worker_ids) {
      userProfile.worker_ids = []
    }
    const hasWorkerId = userProfile.worker_ids.includes(worker.id)

    if (!hasWorkerId) userProfile.worker_ids.push(worker.id)
  })

  return { ...userProfiles }
}
