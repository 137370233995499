import React from 'react'
import {
  Button,
  Navbar,
  NavbarDivider,
  NavbarGroup,
  NavbarHeading,
  Alignment,
  Intent
} from '@blueprintjs/core'

import { useHistory } from 'react-router-dom'
import { Routes } from '../urls/router-paths'

interface AdminHeaderProps {
  activePage: Routes
}

export const AdminHeader = (props: AdminHeaderProps) => {
  const { activePage } = props
  const history = useHistory()

  const getIntent = (route: Routes) => {
    return activePage === route ? Intent.PRIMARY : Intent.NONE
  }

  return (
    <header className="header">
      <Navbar className="bp3-dark">
        <div className="header-content-wrapper">
          <NavbarGroup align={Alignment.LEFT}>
            <NavbarHeading>Onaroll</NavbarHeading>
            <NavbarDivider />
            <Button
              onClick={() => {
                history.push('/live')
              }}
              className="bp3-minimal"
              intent={getIntent(Routes.live)}
              icon="person"
              text="Live"
            />
            <Button
              onClick={() => {
                history.push('/onboarding')
              }}
              className="bp3-minimal"
              intent={getIntent(Routes.onboarding)}
              icon="new-person"
              text="Onboarding"
            />
            <Button
              onClick={() => {
                history.push('/news-posts')
              }}
              className="bp3-minimal"
              intent={getIntent(Routes.newsPosts)}
              icon="feed"
              text="News Posts"
            />
            <Button
              onClick={() => {
                history.push('/setup')
              }}
              className="bp3-minimal"
              intent={getIntent(Routes.setup)}
              icon="build"
              text="Setup"
            />
            <Button
              onClick={() => {
                history.push('/integration')
              }}
              className="bp3-minimal"
              intent={getIntent(Routes.integration)}
              icon="layout-auto"
              text="Integration"
            />
          </NavbarGroup>
        </div>
      </Navbar>
    </header>
  )
}
