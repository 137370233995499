import { Intent } from '@blueprintjs/core'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { FilterGroup } from '../components/FilterGroup'
import { SelectionItem } from '../components/GenericSelector'
import { MessageText, MessageTextType } from '../components/MessageText'
import { UserProfileInfo } from '../components/UserProfileInfo'
import { USER_SETTINGS as userSettings } from '../fixtures'
import Main from '../layouts/Main'
import { DATA_REDUCER, RootState } from '../reduxStore'
import {
  Store,
  StoreDataResponse,
  StoreGroup,
  StoreGroupDataResponse,
  UserProfile,
  UserProfileDataResponse,
  UserSetting,
  WorkerDataResponse
} from '../types'
import { Routes } from '../urls/router-paths'
import { addOrRemoveArrayItem } from '../utils'

const NewsPosts = () => {
  const [filteredUserProfiles, setFilteredUserProfiles] = useState<UserProfile[]>([])
  const [userProfilesSelected, setUserProfilesSelected] = useState<UserProfile[]>([])
  const [storesSelected, setStoresSelected] = useState<Store[]>([])
  const [storeGroupsSelected, setStoreGroupsSelected] = useState<StoreGroup[]>([])
  const [messagesFrequencySelected, setMessagesFrequencySelected] = useState<UserSetting[]>([])
  const [manuallyRemovedUserProfileIds, setManuallyRemovedUserProfileIds] = useState<string[]>([])
  const [buttonIntent, setButtonIntent] = useState<Intent>(Intent.PRIMARY)

  const workerResponse: WorkerDataResponse = useSelector(
    (state: RootState) => state[DATA_REDUCER].workers
  )

  const userProfileResponse: UserProfileDataResponse = useSelector(
    (state: RootState) => state[DATA_REDUCER].userProfiles
  )
  const allUserProfiles: UserProfile[] = Object.values(userProfileResponse)
  const onboardedUserProfiles: UserProfile[] = allUserProfiles.filter(
    (userProfile) => !userProfile.enrolled_end
  )

  const storeResponse: StoreDataResponse = useSelector(
    (state: RootState) => state[DATA_REDUCER].stores
  )

  const storeGroupResponse: StoreGroupDataResponse = useSelector(
    (state: RootState) => state[DATA_REDUCER].storeGroups
  )

  const messagesFrequencyResponse = userSettings.messages_frequency

  const userProfileItems: SelectionItem<UserProfile>[] = onboardedUserProfiles.map(
    (userProfile: UserProfile) => ({
      id: userProfile.id,
      title: userProfile.full_name,
      item: userProfile
    })
  )

  const storeItems: SelectionItem<Store>[] = Object.values(storeResponse)
    .filter((store: Store) => store.active)
    .map((store) => ({
      id: store.id,
      title: store.name,
      item: store
    }))

  const storeGroupItems: SelectionItem<StoreGroup>[] = Object.values(storeGroupResponse)
    .filter((storeGroup: StoreGroup) => storeGroup.active)
    .map((storeGroup) => ({
      id: storeGroup.id,
      title: storeGroup.name,
      item: storeGroup
    }))

  const messagesFrequencyItems: SelectionItem<UserSetting>[] = Object.values(
    messagesFrequencyResponse
  ).map((messageFrequency: UserSetting) => ({
    id: messageFrequency.id,
    title: messageFrequency.name,
    label: messageFrequency.label,
    item: messageFrequency
  }))

  const selectedUserProfileItems: SelectionItem<UserProfile>[] = userProfilesSelected.map(
    (userProfile: UserProfile) => ({
      id: userProfile.id,
      title: userProfile.full_name,
      item: userProfile
    })
  )

  const selectedStoreItems: SelectionItem<Store>[] = storesSelected.map((store: Store) => ({
    id: store.id,
    title: store.name,
    item: store
  }))

  const selectedStoreGroupItems: SelectionItem<StoreGroup>[] = storeGroupsSelected.map(
    (storeGroup: StoreGroup) => ({
      id: storeGroup.id,
      title: storeGroup.name,
      item: storeGroup
    })
  )

  const selectedMessagesFrequencyItems: SelectionItem<
    UserSetting
  >[] = messagesFrequencySelected.map((messageFrequency: UserSetting) => ({
    id: messageFrequency.id,
    title: messageFrequency.name,
    label: messageFrequency.label,
    item: messageFrequency
  }))

  const userProfileProps = {
    values: allUserProfiles,
    items: userProfileItems,
    selectedItems: selectedUserProfileItems,
    setSelectedItems: setUserProfilesSelected
  }

  const storeProps = {
    items: storeItems,
    selectedItems: selectedStoreItems,
    setSelectedItems: setStoresSelected
  }

  const storeGroupProps = {
    items: storeGroupItems,
    selectedItems: selectedStoreGroupItems,
    setSelectedItems: setStoreGroupsSelected
  }

  const messageFrequencyProps = {
    items: messagesFrequencyItems,
    selectedItems: selectedMessagesFrequencyItems,
    setSelectedItems: setMessagesFrequencySelected
  }

  const handleFilter = (filteredUserProfiles: UserProfile[]) => {
    setManuallyRemovedUserProfileIds([])
    setFilteredUserProfiles(filteredUserProfiles)
    setButtonIntent(Intent.PRIMARY)
  }

  const handleUserProfileToggle = (userProfile: UserProfile) => {
    const updatedRemovedList = manuallyRemovedUserProfileIds
    addOrRemoveArrayItem(updatedRemovedList, userProfile.id)
    setManuallyRemovedUserProfileIds([...updatedRemovedList])
  }

  const recipientsList = (
    <div>
      {filteredUserProfiles.map((userProfile: UserProfile) => {
        return (
          <UserProfileInfo
            key={userProfile.id}
            workerData={workerResponse}
            storeData={storeResponse}
            userProfile={userProfile}
            onToggle={handleUserProfileToggle}
          />
        )
      })}
    </div>
  )

  const filteredWithoutRemoved = filteredUserProfiles.filter((userProfile) => {
    return !manuallyRemovedUserProfileIds.includes(userProfile.id)
  })

  const handleOnMessageReset = () => {
    setUserProfilesSelected([])
    setStoresSelected([])
    setStoreGroupsSelected([])
    setMessagesFrequencySelected([])
    setFilteredUserProfiles([])
    setButtonIntent(Intent.PRIMARY)
  }

  return (
    <Main activePage={Routes.newsPosts}>
      <div className="menu-nav">
        <FilterGroup
          userProfileProps={userProfileProps}
          storeProps={storeProps}
          storeGroupProps={storeGroupProps}
          messageFrequencyProps={messageFrequencyProps}
          buttonIntent={buttonIntent}
          setButtonIntent={setButtonIntent}
          onFilter={handleFilter}
        />
      </div>
      <div className="admin-content-wrapper">
        <div className="admin-page">
          <div className="infobar" />
          <MessageText
            onReset={handleOnMessageReset}
            selectedUserProfiles={filteredWithoutRemoved}
            initState={{
              textContent: '',
              bonusPoints: 0,
              recipientsIsOpen: false,
              enrollmentComplete: false,
              newsPost: {
                title: '',
                deliveryDate: new Date(Date.now()),
                featured: false
              }
            }}
            type={MessageTextType.NewsPosts}
          />
        </div>
      </div>
      <div className="recipient-list">{recipientsList}</div>
    </Main>
  )
}

export default NewsPosts
