// React
import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

// Components
import { FormGroup, InputGroup, Label, TextArea, Button } from '@blueprintjs/core'
import { SubmitButton, Action } from './SubmitButton'
import { DateTimePickerField } from './common/Fields'
import { GenericMultiSelector } from './GenericSelector'
import { ToggleButton } from './common/ToggleButton'
import UserCachedPointsWidget from './UserCachedPointsWidget'

// Thunks
import { postDropImpersonationThunk, postImpersonationThunk } from '../thunks'

// Types
import { UserProfile, UserProfileDataResponse, Whoami, Worker, WorkerDataResponse } from '../types'
import { SelectionItem } from './GenericSelector'
import { AUTH_REDUCER, DATA_REDUCER, RootState } from '../reduxStore'

// Defaults

const defaultState: UserProfile = {
  id: '',
  first_name: '',
  last_name: '',
  full_name: '',
  display_name: '',
  phone: '',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  sms_frequency: '',
  birthday: null,
  enrolled_start: null,
  enrolled_end: null,
  notes: ''
}

export const UserProfileSidebarForm = (props: UserProfile) => {
  //Local State
  const initState = props ?? defaultState

  const [state, setState] = useState<UserProfile>({ ...initState })

  const whoami: Whoami | null = useSelector((state: RootState) => state[AUTH_REDUCER].whoami)

  const userProfileResponse: UserProfileDataResponse = useSelector(
    (state: RootState) => state[DATA_REDUCER].userProfiles
  )

  const workerResponse: WorkerDataResponse = useSelector(
    (state: RootState) => state[DATA_REDUCER].workers
  )
  const allWorkers: Worker[] = Object.values(workerResponse)

  const workerItems: SelectionItem<Worker>[] = allWorkers.map((worker: Worker) => ({
    id: worker.id,
    title: `${worker.first_name} ${worker.last_name}`,
    item: worker
  }))

  const workerIds = props.worker_ids ?? []

  const [workersSelected, setWorkersSelected] = useState<Worker[]>(
    workerIds.length ? allWorkers.filter((worker: Worker) => workerIds.includes(worker.id)) : []
  )

  const selectedWorkerItems: SelectionItem<Worker>[] = workersSelected.map((worker: Worker) => ({
    id: worker.id,
    title: `${worker.first_name} ${worker.last_name}`,
    item: worker
  }))

  // Persistence and data store operations
  const dispatch = useDispatch()

  const dispatchImpersonation = async () => {
    const userProfileId = state.id
    const request = await dispatch(postImpersonationThunk(userProfileId))
    return request
  }

  const dispatchDropImpersonation = async () => {
    const request = await dispatch(postDropImpersonationThunk())
    return request
  }

  // Components
  const createInputGroup = (title: string, key: keyof UserProfile, type = 'text') => {
    const value = state[key] ? String(state[key]) : undefined
    return (
      <div className="mtb-2">
        <Label>{title}</Label>
        <InputGroup
          type={type}
          value={value}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setState({ ...state, [key]: e.currentTarget.value })
          }
        />
      </div>
    )
  }

  const workersField = (
    <div className="mtb-2">
      <Label>Workers</Label>
      <GenericMultiSelector
        items={workerItems}
        selectedItems={selectedWorkerItems}
        onItemSelect={(item: SelectionItem<Worker>) => {
          setWorkersSelected((prev: Worker[]) => {
            return Array.from(new Set([...prev, item.item]))
          })
        }}
        onItemRemove={(item: SelectionItem<Worker>) => {
          setWorkersSelected((prev: Worker[]) => {
            return prev.filter((comp: Worker) => comp.id !== item.id)
          })
        }}
      />
    </div>
  )

  const firstNameField = createInputGroup('First Name', 'first_name')

  const lastNameField = createInputGroup('Last Name', 'last_name')

  const fullNameField = createInputGroup('Full Name', 'full_name')

  const displayNameField = createInputGroup('Display Name', 'display_name')

  const phoneNumberField = createInputGroup('Phone Number', 'phone', 'tel')

  const smsFrequencyField = createInputGroup('SMS Frequency', 'sms_frequency')

  const notesField = (
    <div className="mtb2">
      <Label>Notes</Label>
      <TextArea
        className="textarea-section"
        fill
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
          setState({ ...state, notes: e.currentTarget.value })
        }
        value={state.notes}
      />
    </div>
  )

  const birthdayField = (
    <div className="mtb-2">
      <DateTimePickerField
        label="Birthday"
        value={state.birthday}
        update={(value) =>
          setState((prevState) => {
            prevState.birthday = value
            return { ...prevState }
          })
        }
        iconName="calendar"
      />
    </div>
  )

  const toggleableUserProfileInfo = () => {
    const selectedUserProfile = userProfileResponse[state.id]
    if (!selectedUserProfile) return null
    const workersInUserProfile = (() => {
      if (!selectedUserProfile?.worker_ids?.length) return null
      const selectedUserProfileWorkers = selectedUserProfile.worker_ids.map(
        (id) => workerResponse[id]
      )
      return (
        <article>
          <h4>Workers associated with this user profile:</h4>
          <ul>
            {selectedUserProfileWorkers.map((worker) => (
              <li key={worker.id}>
                {worker.first_name} {worker.last_name}
              </li>
            ))}
          </ul>
        </article>
      )
    })()
    return (
      <section>
        <ToggleButton
          iconName="user"
          text={`Show more info about ${
            selectedUserProfile.display_name ?? selectedUserProfile.full_name
          }`}
          isOpen={false}
        >
          <UserCachedPointsWidget userProfileId={selectedUserProfile.id} />
          {workersInUserProfile}
        </ToggleButton>
      </section>
    )
  }

  const impersonateButton = whoami ? (
    <Button text="Drop Impersonation" onClick={dispatchDropImpersonation} />
  ) : (
    <Button text="Impersonate" onClick={dispatchImpersonation} />
  )

  const impersonate = <div className="mt-2">{state.id ? impersonateButton : <div />}</div>

  const actions = (
    <div className="mt-2">
      {state.id ? (
        // eslint-disable-next-line no-undef
        <SubmitButton object={state} action={Action.update} apiCall={() => Promise.resolve()} />
      ) : (
        // eslint-disable-next-line no-undef
        <SubmitButton object={state} action={Action.create} apiCall={() => Promise.resolve()} />
      )}
    </div>
  )

  return (
    <FormGroup helperText="." labelFor="text-input">
      <h2>{state.full_name ?? 'Add a new User Profile'}</h2>
      {toggleableUserProfileInfo()}
      {workersField}
      {firstNameField}
      {lastNameField}
      {fullNameField}
      {displayNameField}
      {phoneNumberField}
      {smsFrequencyField}
      {birthdayField}
      {notesField}
      {impersonate}
      {actions}
    </FormGroup>
  )
}
