import React from 'react'
import { useSelector } from 'react-redux'
import { DATA_REDUCER, RootState } from '../reduxStore'
import { CachedPoint, CachedPointDataResponse } from '../types'

interface UserCachedPointsWidgetProps {
  userProfileId: string
  workerId?: string
}

export default function UserCachedPointsWidget({
  userProfileId,
  workerId
}: UserCachedPointsWidgetProps) {
  const cachedPoints: CachedPointDataResponse = useSelector(
    (state: RootState) => state[DATA_REDUCER].cachedPoints
  )
  const userPoints = cachedPoints[userProfileId]
  if (!userPoints?.length) return null
  const heading = `Total points associated with this ${workerId ? 'worker' : 'user profile'}:`
  let userProfileOrWorkerPoints: CachedPoint | undefined
  if (workerId) {
    userProfileOrWorkerPoints = userPoints.find(
      (point) => point.user_profile_id === userProfileId && point.worker_id === workerId
    )
  } else {
    userProfileOrWorkerPoints = userPoints.reduce((memo, point) => {
      return {
        ...memo,
        dough_credits: (memo.dough_credits ?? 0) + point.dough_credits,
        dough_debits: (memo.dough_debits ?? 0) + point.dough_debits
      }
    }, {} as CachedPoint)
  }
  if (!userProfileOrWorkerPoints) return null
  return (
    <article>
      <h4>{heading}</h4>
      <div>
        <p>
          <span>Points Earned: </span>
          {userProfileOrWorkerPoints.dough_credits}
        </p>
        <p>
          <span>Points Used: </span>
          {userProfileOrWorkerPoints.dough_debits}
        </p>
        <p>
          <span>Total Points: </span>
          {userProfileOrWorkerPoints.dough_credits + userProfileOrWorkerPoints.dough_debits}
        </p>
      </div>
    </article>
  )
}
