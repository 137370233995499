import React from 'react'
import { DateRangePicker } from '@blueprintjs/datetime'
import { useDispatch, useSelector } from 'react-redux'
import { INPUT_REDUCER, RootState } from '../reduxStore'
import { update } from '../reducerGenerator'
import { DateRange } from '../types'

export const DateRangeSelector = () => {
  const dispatch = useDispatch()
  const dateRange = useSelector((state: RootState) => state[INPUT_REDUCER].dateRange)

  const selectRange = (dates: DateRange) => {
    dispatch(
      update(INPUT_REDUCER, 'Update selection data', {
        dateRange: dates
      })
    )
  }

  return (
    <DateRangePicker allowSingleDayRange={true} onChange={selectRange} defaultValue={dateRange} />
  )
}
