import { Button, Callout, Intent } from '@blueprintjs/core'
import React, { SetStateAction, useState } from 'react'
import { fetchUserProfileFilters } from '../api/backend'
import { Store, StoreGroup, UserProfile, UserSetting } from '../types'
import { GenericMultiSelector, SelectionItem } from './GenericSelector'

export interface FilterProps<T> {
  values?: T[]
  items: SelectionItem<T>[]
  selectedItems: SelectionItem<T>[]
  setSelectedItems: (selectedItems: SetStateAction<T[]>) => void
}

interface FilterGroupProps {
  userProfileProps: FilterProps<UserProfile>
  storeProps: FilterProps<Store>
  storeGroupProps: FilterProps<StoreGroup>
  messageFrequencyProps: FilterProps<UserSetting>
  buttonIntent: Intent
  setButtonIntent: (buttonIntent: SetStateAction<Intent>) => void
  onFilter: (filteredUserProfiles: UserProfile[]) => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  extendQueryParams?: (queryParams: any) => any
  children?: React.ReactNode
}

export const FilterGroup = (props: FilterGroupProps) => {
  const {
    userProfileProps,
    storeProps,
    storeGroupProps,
    messageFrequencyProps,
    buttonIntent,
    setButtonIntent,
    onFilter,
    extendQueryParams,
    children
  } = props

  const [error, setError] = useState<string>('')

  const handleUpdateFilters = async () => {
    let queryParams = {
      store: storeProps.selectedItems.map((store) => store.id),
      store_group: storeGroupProps.selectedItems.map((storeGroup) => storeGroup.id),
      messages_frequency: messageFrequencyProps.selectedItems.map(
        (messageFrequency) => messageFrequency.id
      )
    }

    if (extendQueryParams) {
      queryParams = extendQueryParams(queryParams)
    }

    let result: string[] = []
    try {
      result = await fetchUserProfileFilters(queryParams)
      setError('')
    } catch (error) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      setError((error as any)?.status + ': ' + (error as any)?.message)
    }

    if (!userProfileProps.values || !userProfileProps.values.length) {
      setError('FilterGroup component misconfigured: No user profiles defined')
      onFilter([])
      return
    }

    // Apply API driven filters
    let updatedFilteredUserProfiles = userProfileProps.values.filter((userProfile: UserProfile) =>
      result.includes(userProfile.id)
    )

    if (userProfileProps.selectedItems.length) {
      // Apply user profile selected filter if defined
      const userProfilesSelectedIds = userProfileProps.selectedItems.map(
        (userProfile) => userProfile.id
      )

      updatedFilteredUserProfiles = updatedFilteredUserProfiles.filter((userProfile) => {
        return userProfilesSelectedIds.includes(userProfile.id)
      })
    }

    onFilter(updatedFilteredUserProfiles)
  }

  const userProfileSelector = (
    <GenericMultiSelector
      items={userProfileProps.items}
      selectedItems={userProfileProps.selectedItems}
      placeholder="User Profile"
      onItemSelect={(item: SelectionItem<UserProfile>) => {
        userProfileProps.setSelectedItems((prev: UserProfile[]) => {
          setButtonIntent(Intent.DANGER)
          return Array.from(new Set([...prev, item.item]))
        })
      }}
      onItemRemove={(item: SelectionItem<UserProfile>) => {
        userProfileProps.setSelectedItems((prev: UserProfile[]) => {
          setButtonIntent(Intent.DANGER)
          return prev.filter((comp: UserProfile) => comp !== item.item)
        })
      }}
    />
  )

  const storeSelector = (
    <GenericMultiSelector
      items={storeProps.items}
      selectedItems={storeProps.selectedItems}
      placeholder="Store"
      onItemSelect={(item: SelectionItem<Store>) => {
        storeProps.setSelectedItems((prev: Store[]) => {
          setButtonIntent(Intent.DANGER)
          return Array.from(new Set([...prev, item.item]))
        })
      }}
      onItemRemove={(item: SelectionItem<Store>) => {
        storeProps.setSelectedItems((prev: Store[]) => {
          setButtonIntent(Intent.DANGER)
          return prev.filter((comp: Store) => comp !== item.item)
        })
      }}
    />
  )

  const storeGroupSelector = (
    <GenericMultiSelector
      items={storeGroupProps.items}
      selectedItems={storeGroupProps.selectedItems}
      placeholder="Store Group"
      onItemSelect={(item: SelectionItem<StoreGroup>) => {
        storeGroupProps.setSelectedItems((prev: StoreGroup[]) => {
          setButtonIntent(Intent.DANGER)
          return Array.from(new Set([...prev, item.item]))
        })
      }}
      onItemRemove={(item: SelectionItem<StoreGroup>) => {
        storeGroupProps.setSelectedItems((prev: StoreGroup[]) => {
          setButtonIntent(Intent.DANGER)
          return prev.filter((comp: StoreGroup) => comp !== item.item)
        })
      }}
    />
  )

  const messageFrequencySelector = (
    <GenericMultiSelector
      items={messageFrequencyProps.items}
      selectedItems={messageFrequencyProps.selectedItems}
      placeholder="SMS Quantity Preference"
      onItemSelect={(item: SelectionItem<UserSetting>) => {
        messageFrequencyProps.setSelectedItems((prev: UserSetting[]) => {
          setButtonIntent(Intent.DANGER)
          return Array.from(new Set([...prev, item.item]))
        })
      }}
      onItemRemove={(item: SelectionItem<UserSetting>) => {
        messageFrequencyProps.setSelectedItems((prev: UserSetting[]) => {
          setButtonIntent(Intent.DANGER)
          return prev.filter((comp: UserSetting) => comp !== item.item)
        })
      }}
    />
  )

  const filterText = buttonIntent === Intent.PRIMARY ? 'Synced' : 'Update'
  const updateFiltersButton = (
    <Button text={filterText} intent={buttonIntent} onClick={handleUpdateFilters} />
  )

  const errorCallout = error.length > 0 ? <Callout title={error} intent={Intent.DANGER} /> : <div />

  return (
    <div>
      {userProfileSelector}
      {storeSelector}
      {storeGroupSelector}
      {messageFrequencySelector}
      {updateFiltersButton}
      {children}
      {errorCallout}
    </div>
  )
}
