import React from 'react'
import { MultiSelector, Selector } from './Selector'
import { InputSelector, DataSelector } from '../reduxStore'
import { Worker } from '../types'

const DATA_SELECTOR = DataSelector.workers
const INPUT_SELECTOR = InputSelector.workers
const BUTTON_LABEL = 'Worker'

// Convert worker objects to common item interface
const mapToItem = (worker: Worker) => {
  return {
    key: worker.id,
    label: worker.outside_id,
    display: `${worker.first_name} ${worker.last_name}`
  }
}

export const WorkerSelector = () => {
  return (
    <Selector
      itemMapper={(item) => mapToItem(item as Worker)}
      dataSelector={DATA_SELECTOR}
      inputSelector={INPUT_SELECTOR}
      buttonLabel={BUTTON_LABEL}
    />
  )
}

export const WorkerMultiSelector = () => {
  return (
    <MultiSelector
      itemMapper={(item) => mapToItem(item as Worker)}
      dataSelector={DATA_SELECTOR}
      inputSelector={INPUT_SELECTOR}
      buttonLabel={BUTTON_LABEL}
    />
  )
}
