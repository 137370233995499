//React
import React from 'react'

// Redux
import { DATA_REDUCER, RootState } from '../../reduxStore'
import { useSelector } from 'react-redux'

//Components
import { Card } from '@blueprintjs/core'

import { ToggleButton } from './ToggleButton'

// Types
import { StoreDataResponse, WorkerDataResponse, UserProfileDataResponse } from '../../types'

//Utils
import { mergeToUniqueArray } from '../../utils'

export enum UserListType {
  worker = 'worker',
  userProfile = 'userProfile'
}

interface UserListProps {
  title: string
  type: UserListType
  userIds: string[]
}

export const UserList = (props: UserListProps) => {
  const { title, type, userIds } = props

  const workers: WorkerDataResponse = useSelector((state: RootState) => state[DATA_REDUCER].workers)
  const userProfiles: UserProfileDataResponse = useSelector(
    (state: RootState) => state[DATA_REDUCER].userProfiles
  )
  const stores: StoreDataResponse = useSelector((state: RootState) => state[DATA_REDUCER].stores)

  if (!userIds) return <div />

  const listItems = userIds.map((userId: string) => {
    let userName = ''
    let storeNames = ''
    let storeGroupNames = ''
    let phoneNumber: string | null = ''

    switch (type) {
      case UserListType.worker: {
        const workerObject = workers[userId]
        userName = workerObject.first_name + ' ' + workerObject.last_name
        phoneNumber = workerObject.phone
        storeNames = stores[workerObject.store_id]?.name
        storeGroupNames = stores[workerObject.store_id]?.group_name
        break
      }
      case UserListType.userProfile: {
        const userProfileObject = userProfiles[userId]
        userName = userProfileObject.first_name + ' ' + userProfileObject.last_name
        phoneNumber = userProfileObject.phone

        if (!userProfileObject.worker_ids?.length) break

        storeNames =
          mergeToUniqueArray(
            userProfileObject.worker_ids.map(
              (worker_id) => `${stores[workers[worker_id].store_id]?.name}`
            )
          ).join(', ') ?? ''

        storeGroupNames =
          mergeToUniqueArray(
            userProfileObject.worker_ids.map(
              (worker_id) => `${stores[workers[worker_id].store_id]?.group_name}`
            )
          ).join(', ') ?? ''

        break
      }
      default:
        break
    }

    return (
      <Card key={userId} className="user-list-item">
        <div className="databar-text">
          <p>
            <b>{userName}</b>
          </p>
          <p>{phoneNumber ?? 'No phone number'}</p>
        </div>
        {`${storeNames} (${storeGroupNames})`}
      </Card>
    )
  })

  return (
    <div className="mt-1">
      <ToggleButton iconName={'user'} text={`${title}`} isOpen={false}>
        <div>{listItems}</div>
      </ToggleButton>
    </div>
  )
}
