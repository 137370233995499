// React
import React, { useEffect } from 'react'
import { usePrevious } from '../../utils/react'

// Plottable
import * as Plottable from 'plottable'

// Styles
import 'plottable/plottable.css'

// Types
import { LineChartData, PlotChartProps } from '../../types'

export const LineChart = (props: PlotChartProps) => {
  // Props
  const { data, timeline } = props

  const prevTimeline = usePrevious({ timeline })

  // Refs
  const plotDivRef = React.createRef<HTMLDivElement>()

  // Chart configs
  const xScale = new Plottable.Scales.Time().domain(timeline)
  const yScale = new Plottable.Scales.Linear().domain([0, 15])

  const xAxis = new Plottable.Axes.Time(xScale, 'bottom')
  const yAxis = new Plottable.Axes.Numeric(yScale, 'left')

  const plot = new Plottable.Plots.Line()
    .x((d: LineChartData) => d.x, xScale)
    .y((d: LineChartData) => d.y, yScale)

  const chart = new Plottable.Components.Table([
    [yAxis, plot],
    [null, xAxis]
  ])

  useEffect(() => {
    const drawChartToElement = (el: HTMLDivElement) => chart.renderTo(el)

    if (data && plotDivRef.current) {
      drawChartToElement(plotDivRef.current)
    }
  }, [data, plotDivRef, chart])

  useEffect(() => {
    const redrawPlot = () => plot.redraw()
    const updatePlotData = () => plot.datasets([new Plottable.Dataset(data)])

    updatePlotData()
    redrawPlot()
  }, [data, prevTimeline, timeline, plot])

  return (
    <div>
      <div ref={plotDivRef} style={{ height: '180px', width: '100%' }} />
    </div>
  )
}
