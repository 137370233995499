export interface HasId {
  id: string
}

export interface DoughEntry extends HasId {
  user_profile_id: string | null
  point_type: string
  direction: string
  amount: number
  // eslint-disable-next-line @typescript-eslint/ban-types
  metadata: object
}

export enum ProductFulfillmentType {
  weGift = 'WeGift',
  inventory = 'Inventory'
}

interface ProductFulfillment {
  fulfillmentType: ProductFulfillmentType
}

export interface WeGiftProduct extends ProductFulfillment, HasId {
  reward_id: string
  product_code: string
  amount: number
}

export interface RaiseProduct extends HasId {
  brand_id: string
  brand_name: string
}

export interface RewardProduct extends ProductFulfillment, HasId {
  reward_id: string
  card_id: string
  serial: string
  available: boolean
  custom_redemption_message: string
}

export interface Reward extends HasId {
  active: boolean
  points: number
  title: string
  tagline: string
  description: string
  image: string
  image_alternate: string
  brand_color: string
  custom_sms_message: string
  effective_at: Date | null
  expires_at: Date | null
  wegift_products: WeGiftProduct[]
  raise_product_id: string | null
  reward_products: RewardProduct[]
  store_ids: string[]
  store_group_ids: string[]
  stores: Store[]
  storeGroups: StoreGroup[]
}
export interface RewardDataPayload {
  next: string | null
  previous: string | null
  results: Reward[]
}

export interface Goals extends HasId {
  expires_at: string
  store_id: string
  group_id: string
  title: string
  description: string
}

export interface Store extends HasId {
  name: string
  group_id: string
  group_name: string
  worker_count: number
  timezone: string
  active: boolean
}

export interface StoreGroup extends HasId {
  name: string
  active: boolean
  manual_stores: boolean
  manual_date: boolean
  flour_fields: string[]
}

export interface Worker extends HasId {
  first_name: string
  last_name: string
  outside_id: string
  phone: string | null
  active: boolean
  store_id: string
  user_profile_id: string
  onboarding_modal_seen: boolean
  enrolled_on: Date | null
  store?: Store
}

export interface UserProfile extends HasId {
  first_name: string
  last_name: string
  full_name: string
  display_name: string
  phone: string | null
  sms_frequency: 'never' | 'weekly' | 'daily'
  worker_ids?: string[]
  birthday: Date | null
  enrolled_start: Date | null
  enrolled_end: Date | null
  notes: string
  opted_out_date: string
}

export interface DataIntegration extends HasId {
  store_id: string
  outside_id: string
  system: string
  integration_data_type: string
}

export interface CachedPoint {
  user_profile_id: string
  worker_id: string
  dough_credits: number
  dough_debits: number
}

export interface DoughEntryDataResponse {
  [key: string]: DoughEntry
}

export interface RewardDataResponse {
  [key: string]: Reward
}

export type UserProfileDataResponse = Record<string, UserProfile>

export interface WorkerDataResponse {
  [key: string]: Worker
}

export interface StoreDataResponse {
  [key: string]: Store
}

export interface GoalsDataResponse {
  [key: string]: Goals
}

export interface StoreGroupDataResponse {
  [key: string]: StoreGroup
}

export interface DataIntegrationDataResponse {
  [key: string]: DataIntegration
}

export interface CachedPointDataResponse {
  // same as Record<string, CachedPoint[]>
  [key: string]: CachedPoint[]
}

export interface UploadSheetNamesResponse {
  [key: string]: string[]
}

export interface RosterSheetNamesResponse {
  [key: string]: string[]
}

export interface Impersonator {
  user_id: number
  user_name: string
}

export interface Whoami {
  user_profile_id: string
  display_name: string
  onboarding_modal_seen: boolean
  store: string
  phone: string
  impersonator: Impersonator | null
}

export type APIDataTypes = Reward | Store | StoreGroup | Worker | DataIntegration | Goals

export interface APIDataResponse {
  [key: string]: APIDataTypes
}

// Structure of message request
export interface MessageData {
  text: string
  bonus: number
  enrollment_complete?: boolean
  news_post?: {
    title: string
    message: string
    published_at: Date
    featured: boolean
    image?: File
    thumbnail?: File
  }
  user_profile_ids: string[]
}

// Messaging error with a reason for a specific user profile
export interface MessageDataResponse {
  user_profile_id: string
  reason: string
}

// Complete messaging API response
export interface PostMessageResponse {
  success: MessageDataResponse[]
  failure: MessageDataResponse[]
}

// Structure of messaging request error
export interface MessageError {
  status: string
  url: string
  message: string
  when: number
}

// Complete batched messaging API response of results
export interface BatchPostMessageResponse {
  message_data: MessageData
  success: MessageDataResponse[]
  failure: MessageDataResponse[]
  error: MessageError[]
}

export interface MessageTextState {
  textContent: string
}

export type DateRange = [Date | null, Date | null]

// eslint-disable-next-line @typescript-eslint/ban-types
export type StateType = object

export interface Update {
  type: string
  reducer: string
  tag: string
  state: StateType
}

export type MessageTextVariable =
  | '$url_root'
  | '$url_perks'
  | '$url_stats'
  | '$url_help'
  | '$first_name'
  | '$last_name'
  | '$bonus_points'
  | '$points_accrued'
  | '$points_balance'
  | '$points_remaining'
  | '$next_reward'

enum MessageVarOptions {
  url_root = '$url_root',
  url_perks = '$url_perks',
  url_stats = '$url_stats',
  url_help = '$url_help',
  first_name = '$first_name',
  last_name = '$last_name',
  bonus_points = '$bonus_points',
  points_accrued = '$points_accrued',
  points_balance = '$points_balance',
  points_remaining = '$points_remaining',
  next_reward = '$next_reward'
}

export type MessageVariable = {
  [key in MessageVarOptions]: string
}

// LiveViews
export enum LiveViews {
  worker = 'Worker',
  store = 'Store',
  storeGroup = 'Store Group'
}

// Questions Answered
export interface Question {
  id: string
  question_type: string
  question_text: string
}

export interface QuestionAnswered {
  id: string
  worker_id: string
  question: Question
  answer: string
  answered_at: Date
}

export interface WorkerLatestAnswers {
  [id: string]: Date | null
}

export interface QuestionAnsweredDataResponse {
  [key: string]: QuestionAnswered
}

// Line Chart
export interface LineChartData {
  x: Date
  y: number
  label?: string
}

export interface LineChartTimeline {
  initDate: Date
  endDate: number
}

export interface PlotChartProps {
  data: LineChartData[]
  timeline: (Date | Date)[]
}

// Setup page
export enum SetUpViews {
  perks = 'Perks',
  stores = 'Stores',
  users = 'Users',
  workers = 'Workers',
  questions = 'Questions',
  messageLibrary = 'Message Library'
}

// Library Sidebar
export interface LibrarySidebarProps<T> {
  targetRow: T | null
  view: SetUpViews
  isOpen?: boolean
  nameClass?: string
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface PerkLibrarySidebarFormProps extends Reward {}

export interface PerkLibrarySidebarFormState extends PerkLibrarySidebarFormProps {
  redemptionModalCopy: string
  redemptionSMSCopy: string
  expiryDateIsOpen: boolean
  expiryDate: Date
  forever: boolean
  weGiftProducts?: WeGiftProduct[]
  rewardProducts?: RewardProduct[]
}

interface FlourData {
  date: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any
}

export interface Flour {
  store: string
  data: FlourData
}

interface HTTPHeader {
  content_type: string | string[]
}

export interface RequestResponse {
  status: number | undefined
  message: string
  headers?: HTTPHeader
}

export enum MessageFrequency {
  none = 'None',
  essential = 'Essential',
  fun = 'Fun',
  full = 'Full'
}

export interface UserSetting {
  id: string
  name: string
  label: string
}

export interface MockWorker {
  first: string
  last: string
  display_name: string
  url_root: string
  url_perks: string
  url_stats: string
  url_help: string
  points_accrued: number
  points_balance: number
  points_remaining: string
  next_reward: string
}
