import React from 'react'
import { MultiSelector, Selector } from './Selector'
import { InputSelector, DataSelector } from '../reduxStore'
import { StoreGroup } from '../types'

const DATA_SELECTOR = DataSelector.storeGroups
const INPUT_SELECTOR = InputSelector.storeGroups
const BUTTON_LABEL = 'Store Group'

// Convert worker objects to common item interface
const mapToItem = (storeGroup: StoreGroup) => {
  return {
    key: storeGroup.id,
    label: '',
    display: storeGroup.name
  }
}

export const StoreGroupSelector = () => {
  return (
    <Selector
      itemMapper={(item) => mapToItem(item as StoreGroup)}
      dataSelector={DATA_SELECTOR}
      inputSelector={INPUT_SELECTOR}
      buttonLabel={BUTTON_LABEL}
    />
  )
}

export const StoreGroupMultiSelector = () => {
  return (
    <MultiSelector
      itemMapper={(item) => mapToItem(item as StoreGroup)}
      dataSelector={DATA_SELECTOR}
      inputSelector={INPUT_SELECTOR}
      buttonLabel={BUTTON_LABEL}
    />
  )
}
