// React
import React from 'react'

// Components
import { DatePicker, TimePrecision } from '@blueprintjs/datetime'
import { InputGroup, Label, NumericInput, Tag, IconName } from '@blueprintjs/core'
import { ToggleButton } from './ToggleButton'

export interface FieldProps<T> {
  label: string
  value: T
  disabled?: boolean
  update: (value: T) => void
  iconName?: IconName
  tag?: string
}

// Generic integer input field
export const IntegerInput = (props: FieldProps<number>) => {
  const { label, value, update } = props
  return (
    <div className="sidebar-field">
      <Label>{label}</Label>
      <NumericInput value={value} onValueChange={(value: number) => update(value)} />
    </div>
  )
}

// Generic string input field
export const StringInput = (props: FieldProps<string>) => {
  const { label, value, update, tag } = props
  const rightElement = tag ? <Tag>{tag}</Tag> : undefined
  return (
    <div className="sidebar-field">
      <Label>{label}</Label>
      <InputGroup
        type="text"
        // Set value to an empty string if it is passed in as null
        value={value ?? ''}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          update(e.currentTarget.value)
        }}
        rightElement={rightElement}
      />
    </div>
  )
}

export const DateTimePickerField = (props: FieldProps<Date | null>) => {
  const { tag, label, value, update, iconName, disabled } = props

  const MAX_DATE: Date = new Date(2050, 12, 31)

  return (
    <div className="sidebar-field">
      <Label>{label}</Label>
      <div className="mtb-1">
        <ToggleButton
          disabled={disabled ?? false}
          iconName={iconName}
          key={String(tag) || 0}
          text={value ? new Date(value).toDateString() + ' (UTC)' : 'Select Date (UTC)'}
          isOpen={false}
        >
          <DatePicker
            onChange={(date: Date) => update(date)}
            showActionsBar={true}
            timePickerProps={{
              showArrowButtons: true,
              useAmPm: true,
              precision: TimePrecision.MINUTE
            }}
            maxDate={MAX_DATE}
            value={value ? new Date(value) : new Date()}
          />
        </ToggleButton>
      </div>
    </div>
  )
}
