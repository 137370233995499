// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react'
import { PerksLibrarySidebarForm } from './PerksLibrarySidebarForm'
import { StoreSidebarForm } from './StoreSidebarForm'
import { WorkerSidebarForm } from './WorkerSidebarForm'
import { UserProfileSidebarForm } from './UserProfileSidebarForm'
import { LibrarySidebarProps, SetUpViews } from '../types'

export const LibrarySidebar = (props: LibrarySidebarProps) => {
  const { view, targetRow } = props
  const getSideBarForm = () => {
    switch (view) {
      case SetUpViews.perks:
        return <PerksLibrarySidebarForm key={targetRow.id} {...targetRow} />
      case SetUpViews.stores:
        return <StoreSidebarForm key={targetRow.id} {...targetRow} />
      case SetUpViews.workers:
        return <WorkerSidebarForm key={targetRow.id} {...targetRow} />
      case SetUpViews.users:
        return <UserProfileSidebarForm key={targetRow.id} {...targetRow} />
      default:
        return <div />
    }
  }

  return (
    <aside className="sidebar-library">
      <div className="sticky">{getSideBarForm()}</div>
    </aside>
  )
}
