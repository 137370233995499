// React
import React, { useState } from 'react'
import Main from '../layouts/Main'

// Redux
import { DATA_REDUCER, RootState } from '../reduxStore'
import { useSelector } from 'react-redux'

// Components
import { Card, Colors, Elevation, Intent, Tag } from '@blueprintjs/core'
import { DataCard } from '../components/DataCard'
import { DateSelector } from '../components/DateSelector'
import { SelectionItem } from '../components/GenericSelector'
import { MessageText, MessageTextType } from '../components/MessageText'
import { Routes } from '../urls/router-paths'

// Types
import {
  Store,
  StoreGroup,
  StoreDataResponse,
  StoreGroupDataResponse,
  UserProfile,
  UserProfileDataResponse,
  UserSetting,
  WorkerDataResponse
} from '../types'

// Fixtures
import { USER_SETTINGS as userSettings } from '../fixtures'

import { addOrRemoveArrayItem, calculateDaysSince, stripTimeFromDate } from '../utils'
import { FilterGroup } from '../components/FilterGroup'
import { isSmsEnabled } from '../utils'

const Onboarding = () => {
  const [filteredUserProfiles, setFilteredUserProfiles] = useState<UserProfile[]>([])
  const [dateFilteredUserProfiles, setDateFilteredUserProfiles] = useState<UserProfile[]>([])
  const [userProfilesSelected, setUserProfilesSelected] = useState<UserProfile[]>([])
  const [storesSelected, setStoresSelected] = useState<Store[]>([])
  const [storeGroupsSelected, setStoreGroupsSelected] = useState<StoreGroup[]>([])
  const [messagesFrequencySelected, setMessagesFrequencySelected] = useState<UserSetting[]>([])
  const [buttonIntent, setButtonIntent] = useState<Intent>(Intent.PRIMARY)
  const [manuallyRemovedUserProfileIds, setManuallyRemovedUserProfileIds] = useState<string[]>([])
  const [dateSelected, setDateSelected] = useState<Date | null>(null)
  const [daysSinceEnrollmentStarted, setDaysSinceEnrollmentStarted] = useState<number>(0)

  const workerResponse: WorkerDataResponse = useSelector(
    (state: RootState) => state[DATA_REDUCER].workers
  )

  const userProfileResponse: UserProfileDataResponse = useSelector(
    (state: RootState) => state[DATA_REDUCER].userProfiles
  )
  const allUserProfiles: UserProfile[] = Object.values(userProfileResponse)
  const onboardedUserProfiles: UserProfile[] = allUserProfiles.filter(
    (userProfile: UserProfile) => !userProfile.enrolled_end
  )

  const storeResponse: StoreDataResponse = useSelector(
    (state: RootState) => state[DATA_REDUCER].stores
  )

  const storeGroupResponse: StoreGroupDataResponse = useSelector(
    (state: RootState) => state[DATA_REDUCER].storeGroups
  )

  const messagesFrequencyResponse = userSettings.messages_frequency

  const userProfileItems: SelectionItem<UserProfile>[] = onboardedUserProfiles.map(
    (userProfile: UserProfile) => ({
      id: userProfile.id,
      title: userProfile.full_name,
      item: userProfile
    })
  )

  const storeItems: SelectionItem<Store>[] = Object.values(storeResponse)
    .filter((store: Store) => store.active)
    .map((store) => ({
      id: store.id,
      title: store.name,
      item: store
    }))

  const storeGroupItems: SelectionItem<StoreGroup>[] = Object.values(storeGroupResponse)
    .filter((storeGroup: StoreGroup) => storeGroup.active)
    .map((storeGroup) => ({
      id: storeGroup.id,
      title: storeGroup.name,
      item: storeGroup
    }))

  const messagesFrequencyItems: SelectionItem<UserSetting>[] = Object.values(
    messagesFrequencyResponse
  ).map((messageFrequency: UserSetting) => ({
    id: messageFrequency.id,
    title: messageFrequency.name,
    label: messageFrequency.label,
    item: messageFrequency
  }))

  const selectedUserProfileItems: SelectionItem<UserProfile>[] = userProfilesSelected.map(
    (userProfile: UserProfile) => ({
      id: userProfile.id,
      title: userProfile.full_name,
      item: userProfile
    })
  )

  const selectedStoreItems: SelectionItem<Store>[] = storesSelected.map((store: Store) => ({
    id: store.id,
    title: store.name,
    item: store
  }))

  const selectedStoreGroupItems: SelectionItem<StoreGroup>[] = storeGroupsSelected.map(
    (storeGroup: StoreGroup) => ({
      id: storeGroup.id,
      title: storeGroup.name,
      item: storeGroup
    })
  )

  const selectedMessagesFrequencyItems: SelectionItem<
    UserSetting
  >[] = messagesFrequencySelected.map((messageFrequency: UserSetting) => ({
    id: messageFrequency.id,
    title: messageFrequency.name,
    label: messageFrequency.label,
    item: messageFrequency
  }))

  const userProfileProps = {
    values: allUserProfiles,
    items: userProfileItems,
    selectedItems: selectedUserProfileItems,
    setSelectedItems: setUserProfilesSelected
  }

  const storeProps = {
    items: storeItems,
    selectedItems: selectedStoreItems,
    setSelectedItems: setStoresSelected
  }

  const storeGroupProps = {
    items: storeGroupItems,
    selectedItems: selectedStoreGroupItems,
    setSelectedItems: setStoreGroupsSelected
  }

  const messageFrequencyProps = {
    items: messagesFrequencyItems,
    selectedItems: selectedMessagesFrequencyItems,
    setSelectedItems: setMessagesFrequencySelected
  }

  const handleFilter = (filteredUserProfiles: UserProfile[]) => {
    setManuallyRemovedUserProfileIds([])
    setFilteredUserProfiles(filteredUserProfiles)
    filterUserProfilesByDate(filteredUserProfiles, dateSelected)
    setButtonIntent(Intent.PRIMARY)
  }

  const filterUserProfilesByDate = (userProfiles: UserProfile[], date: Date | null) => {
    if (date) {
      userProfiles = userProfiles.filter((userProfile) => {
        if (!userProfile.enrolled_start) return false
        const enrolledStartDateNoTime = stripTimeFromDate(userProfile.enrolled_start)
        const selectedDateNoTime = stripTimeFromDate(date)
        return enrolledStartDateNoTime.getTime() === selectedDateNoTime.getTime()
      })
    } else {
      userProfiles = userProfiles.filter((userProfile) => {
        return !userProfile.enrolled_start && !userProfile.enrolled_end
      })
    }
    setDateFilteredUserProfiles(userProfiles)
  }

  const handleDateSelectorChange = (selectedDate: Date) => {
    setDateSelected(selectedDate)
    setDaysSinceEnrollmentStarted(calculateDaysSince(selectedDate))
    filterUserProfilesByDate(filteredUserProfiles, selectedDate)
  }

  const filteredWithoutRemoved = dateFilteredUserProfiles.filter((userProfile) => {
    return !manuallyRemovedUserProfileIds.includes(userProfile.id)
  })

  const daysSinceEnrollmentStart = (
    <DataCard title="Days since start of enrollment" value={daysSinceEnrollmentStarted} />
  )

  const enrolledStartDates: (number | null)[] = filteredUserProfiles
    .map((userProfile) => {
      if (!userProfile.enrolled_start) return null
      return stripTimeFromDate(userProfile.enrolled_start).getTime()
    })
    .filter((date) => date !== null)

  const enrollmentStartModifier = (date: Date) => {
    const dateWithNoTime = stripTimeFromDate(date)
    return enrolledStartDates.includes(dateWithNoTime.getTime())
  }

  const dateSelector = (
    <DateSelector
      className="ml-1"
      modifiers={{ enrollmentStart: enrollmentStartModifier }}
      date={dateSelected}
      onChange={handleDateSelectorChange}
    />
  )

  const userProfileCohort = (
    <DataCard title="Team Members in selected cohort" value={filteredWithoutRemoved.length} />
  )

  const toggleIndividualUserProfile = (userProfile: UserProfile) => {
    const updatedRemovedList = manuallyRemovedUserProfileIds
    addOrRemoveArrayItem(updatedRemovedList, userProfile.id)
    setManuallyRemovedUserProfileIds([...manuallyRemovedUserProfileIds])
  }

  const renderUserProfileInfo = (userProfile: UserProfile) => {
    const smsEnabled = isSmsEnabled(userProfile)

    // Get stores for workers associated to user profile
    const workerStores = Object.values(workerResponse)
      .filter((worker) => worker.user_profile_id === userProfile.id)
      .map((worker) => storeResponse[worker.store_id]?.name)
      .join(', ')

    const removed = manuallyRemovedUserProfileIds.includes(userProfile.id)
    const color = removed ? Colors.WHITE : Colors.BLUE5

    return (
      <Card
        interactive={true}
        elevation={Elevation.TWO}
        style={{ background: color }}
        className="databar"
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onClick={(_event: React.MouseEvent<HTMLDivElement>) => {
          toggleIndividualUserProfile(userProfile)
        }}
      >
        <div className="databar-text">
          <p>
            <b>{userProfile.full_name}</b>
          </p>
          <p>{workerStores}</p>
        </div>
        <div className="databar-text">
          <Tag intent={smsEnabled ? 'success' : 'danger'}>
            {smsEnabled ? 'SMS Enabled' : 'SMS Not Enabled'}
          </Tag>
          <p>Phone: {userProfile.phone}</p>
        </div>
      </Card>
    )
  }

  const recipientsList = (
    <div>
      {dateFilteredUserProfiles.map((userProfile: UserProfile) => {
        return renderUserProfileInfo(userProfile)
      })}
    </div>
  )

  const handleOnMessageReset = () => {
    setUserProfilesSelected([])
    setStoresSelected([])
    setStoreGroupsSelected([])
    setMessagesFrequencySelected([])
    setFilteredUserProfiles([])
    setButtonIntent(Intent.PRIMARY)
  }

  return (
    <Main activePage={Routes.onboarding}>
      <div className="menu-nav">
        <FilterGroup
          userProfileProps={userProfileProps}
          storeProps={storeProps}
          storeGroupProps={storeGroupProps}
          messageFrequencyProps={messageFrequencyProps}
          buttonIntent={buttonIntent}
          setButtonIntent={setButtonIntent}
          onFilter={handleFilter}
        />
      </div>
      <div className="admin-content-wrapper">
        <div className="admin-page">
          <div className="infobar">
            {dateSelector}
            {daysSinceEnrollmentStart}
            {userProfileCohort}
          </div>
          <MessageText
            onReset={handleOnMessageReset}
            selectedUserProfiles={filteredWithoutRemoved}
            initState={{
              textContent: '',
              bonusPoints: 0,
              recipientsIsOpen: false,
              enrollmentComplete: false
            }}
            type={MessageTextType.Onboarding}
          />
        </div>
      </div>
      <div className="recipient-list">{recipientsList}</div>
    </Main>
  )
}

export default Onboarding
