import React from 'react'
import { Card, Elevation } from '@blueprintjs/core'

export interface DataCardProps {
  title: string
  value: string | number
}

export const DataCard = (props: DataCardProps) => {
  const { title, value } = props
  return (
    <Card interactive={true} elevation={Elevation.TWO} className="datacard">
      <p>{title}</p>
      <h1>{value}</h1>
    </Card>
  )
}
