// React
import React from 'react'

// Components
import { Callout, Button, Intent } from '@blueprintjs/core'

interface OnarollCalloutProps {
  title: string
  description: string | JSX.Element | undefined
  intent: Intent
  className: string
  onClose: (event: React.MouseEvent<HTMLElement>) => void
}

export const OnarollCallout = (props: OnarollCalloutProps) => {
  const { title, description, intent, className, onClose } = props
  return (
    <div className="callout-section">
      <Callout title={title} intent={intent} className={className}>
        {description}
      </Callout>
      <div className={`callout-close-section ${intent}`}>
        <Button onClick={(event: React.MouseEvent<HTMLElement>) => onClose(event)} icon="cross" />
      </div>
    </div>
  )
}
