// React
import React from 'react'

// Components
import { Select } from '@blueprintjs/select'
import { ItemPredicate, ItemRenderer, MultiSelect } from '@blueprintjs/select'
import { Button, MenuItem, Tag } from '@blueprintjs/core'

export interface SelectionItem<T> {
  id: string
  title: string
  label?: string
  item: T
}

export interface GenericSelectProps<T> {
  buttonText: string
  items: SelectionItem<T>[]
  disabled?: boolean
  onItemSelect: (item: SelectionItem<T>) => void
}

const escapeRegExpChars = (text: string) => {
  return text.replace(/([.*+?^=!:${}()|[\]/\\])/g, '\\$1')
}

const highlightText = (text: string, query: string) => {
  let lastIndex = 0
  const words = query
    .split(/\s+/)
    .filter((word) => word.length > 0)
    .map(escapeRegExpChars)
  if (words.length === 0) {
    return [text]
  }
  const regexp = new RegExp(words.join('|'), 'gi')
  const tokens: React.ReactNode[] = []
  // eslint-disable-next-line no-constant-condition
  while (true) {
    const match = regexp.exec(text)
    if (!match) {
      break
    }
    const length = match[0].length
    const before = text.slice(lastIndex, regexp.lastIndex - length)
    if (before.length > 0) {
      tokens.push(before)
    }
    lastIndex = regexp.lastIndex
    tokens.push(<strong key={lastIndex}>{match[0]}</strong>)
  }

  const rest = text.slice(lastIndex)
  if (rest.length > 0) {
    tokens.push(rest)
  }
  return tokens
}

export const GenericSelector = <T,>(props: GenericSelectProps<T>) => {
  const GenericSelect = Select.ofType<SelectionItem<T>>()
  const filterOptions: ItemPredicate<SelectionItem<T>> = (query, item) => {
    return `${String(item.title).toLowerCase()}`.indexOf(query.toLowerCase()) >= 0
  }

  const renderItem: ItemRenderer<SelectionItem<T>> = (
    item: SelectionItem<T>,
    { handleClick, modifiers, query }
  ) => {
    if (!modifiers.matchesPredicate) {
      return null
    }
    const text = item.title
    const label = item.label
    return (
      <MenuItem
        label={label}
        key={item.id}
        onClick={handleClick}
        text={highlightText(text, query)}
      />
    )
  }

  const isDisabled = props.disabled ? true : false

  return (
    <GenericSelect
      items={props.items}
      itemRenderer={renderItem}
      itemPredicate={filterOptions}
      onItemSelect={props.onItemSelect}
      disabled={isDisabled}
    >
      <Button text={props.buttonText} rightIcon="caret-down" disabled={isDisabled} />
    </GenericSelect>
  )
}

export interface GenericMultiSelectorProps<T> {
  items: SelectionItem<T>[]
  selectedItems: SelectionItem<T>[]
  onItemSelect: (item: SelectionItem<T>) => void
  onItemRemove: (item: SelectionItem<T>) => void
  placeholder?: string
}

export const GenericMultiSelector = <T,>(props: GenericMultiSelectorProps<T>) => {
  const { items, selectedItems, onItemSelect, onItemRemove, placeholder, ...restProps } = props

  const itemPredicate: ItemPredicate<SelectionItem<T>> = (
    query: string,
    item: SelectionItem<T>
  ) => {
    return item.title.toLowerCase().indexOf(query.toLowerCase()) >= 0
  }

  const itemRenderer: ItemRenderer<SelectionItem<T>> = (
    item: SelectionItem<T>,
    { handleClick, modifiers }
  ) => {
    if (!modifiers.matchesPredicate) return null
    return (
      <MenuItem
        text={item.title}
        icon={'blank'}
        label={item.label}
        key={item.id}
        onClick={handleClick}
        {...restProps}
      />
    )
  }

  const tagRenderer = (item: SelectionItem<T>) => <Tag key={item.id}>{item.title}</Tag>

  const handleSelection = (item: SelectionItem<T>) => {
    onItemSelect(item)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleRemoveSelection = (value: any) => {
    const item = items.filter((item: SelectionItem<T>) => item.id === value.key).shift()
    if (!item) return
    onItemRemove(item)
  }

  const GenericMultiSelect = MultiSelect.ofType<SelectionItem<T>>()
  return (
    <div className="selector">
      <GenericMultiSelect
        items={items}
        selectedItems={selectedItems}
        onItemSelect={handleSelection}
        placeholder={placeholder}
        itemPredicate={itemPredicate}
        itemRenderer={itemRenderer}
        tagRenderer={tagRenderer}
        tagInputProps={{
          onRemove: handleRemoveSelection
        }}
        resetOnSelect={true}
      />
    </div>
  )
}
