import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import { Live, Onboarding, NewsPosts, Metrics, Setup } from './routes'
import { getPath, Routes } from './urls/router-paths'
import {
  fetchWorkerThunk,
  fetchStoreThunk,
  fetchStoreGroupThunk,
  fetchRewardThunk,
  fetchDataIntegrationThunk,
  fetchUserProfileThunk,
  fetchCachedPointsThunk,
  fetchWhoamiThunk
} from './thunks'

import '@blueprintjs/core/lib/css/blueprint.css'
import '@blueprintjs/datetime/lib/css/blueprint-datetime.css'
import '@blueprintjs/icons/lib/css/blueprint-icons.css'
import '@blueprintjs/select/lib/css/blueprint-select.css'

import 'normalize.css'
import './App.css'
import Integration from './routes/Integration'

export const App = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    const loadState = async () => {
      dispatch(fetchWhoamiThunk())
      dispatch(fetchCachedPointsThunk())
      // dispatch(fetchDoughThunk())
      dispatch(fetchWorkerThunk())
      dispatch(fetchUserProfileThunk())
      dispatch(fetchStoreThunk())
      dispatch(fetchStoreGroupThunk())
      dispatch(fetchRewardThunk())
      dispatch(fetchDataIntegrationThunk())
    }

    loadState()
  }, [dispatch])

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path={getPath(Routes.live)} component={Live} />
        <Route exact path={getPath(Routes.onboarding)} component={Onboarding} />
        <Route exact path={getPath(Routes.newsPosts)} component={NewsPosts} />
        <Route exact path={getPath(Routes.setup)} component={Setup} />
        <Route exact path={getPath(Routes.metrics)} component={Metrics} />
        <Route exact path={getPath(Routes.integration)} component={Integration} />
        <Route>
          <Redirect to={getPath(Routes.live)} />
        </Route>
      </Switch>
    </BrowserRouter>
  )
}

export default App
