import React from 'react'
import { MultiSelector, Selector } from './Selector'
import { InputSelector, DataSelector } from '../reduxStore'
import { Store } from '../types'

const DATA_SELECTOR = DataSelector.stores
const INPUT_SELECTOR = InputSelector.stores
const BUTTON_LABEL = 'Store'

// Convert worker objects to common item interface
const mapToItem = (store: Store) => {
  return {
    key: store.id,
    label: store.group_name,
    display: store.name
  }
}

export const StoreSelector = () => {
  return (
    <Selector
      itemMapper={(item) => mapToItem(item as Store)}
      dataSelector={DATA_SELECTOR}
      inputSelector={INPUT_SELECTOR}
      buttonLabel={BUTTON_LABEL}
    />
  )
}

export const StoreMultiSelector = () => {
  return (
    <MultiSelector
      itemMapper={(item) => mapToItem(item as Store)}
      dataSelector={DATA_SELECTOR}
      inputSelector={INPUT_SELECTOR}
      buttonLabel={BUTTON_LABEL}
    />
  )
}
