import { HasId, UserProfile } from '../types'

const POINTS_PER_DOLLAR = 100
const DAYS_TO_MILLS = 1000 * 60 * 60 * 24

// Convert number of points to dollar Value
export const convertPointsToDollars = (points: number) => {
  const dollars = points / POINTS_PER_DOLLAR
  return '$' + dollars.toLocaleString()
}

// Convert date format to 'YYYY-MM-DD';
export const convertDateYearMonthDay = (date: string | Date) =>
  new Date(date).toISOString().split('T')[0]

export const toDictionary = <TItem, Value>(
  array: TItem[],
  getKey: (item: TItem) => string,
  getValue: (item: TItem) => Value
): Record<string, Value> => {
  const result: Record<string, Value> = {}
  array.forEach((item) => {
    const key = getKey(item)
    result[key] = getValue(item)
  })
  return result
}

// Returns true if string is all ASCII characters, and false if not
export const isASCII = (str: string) => {
  return /^[\x32-\xFF\s]*$/.test(str)
}

export const toDictionaryById = <TItem extends HasId>(array: TItem[]): Record<string, TItem> => {
  return toDictionary(
    array,
    (item) => item.id,
    (x) => x
  )
}

export const groupBy = <T, K extends string | number | symbol>(list: T[], getKey: (item: T) => K) =>
  list.reduce((previous, currentItem) => {
    const group = getKey(currentItem)
    if (!previous[group]) previous[group] = []
    previous[group].push(currentItem)
    return previous
  }, {} as Record<K, T[]>)

export const maxBy = <T>(list: T[], byKey: (item: T) => number) => {
  return list.reduce((acc, current) => {
    const currentKey = byKey(current)
    const previousMax = byKey(acc)
    return currentKey > previousMax ? current : acc
  })
}

export const minBy = <T>(list: T[], byKey: (item: T) => number) => {
  return list.reduce((acc, current) => {
    const currentKey = byKey(current)
    const previousMax = byKey(acc)
    return currentKey < previousMax ? current : acc
  })
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const mergeToUniqueArray = (arrArg: any[]) => {
  return arrArg.flat().filter((elem, pos, arr) => {
    return arr.indexOf(elem) === pos
  })
}

export const calculateDaysSince = (date: Date): number => {
  if (!date) {
    return 0
  }
  let diffMills = Date.now() - date.getTime()
  if (diffMills <= 0) {
    diffMills = 0
  }
  return Math.floor(diffMills / DAYS_TO_MILLS)
}

export const addOrReplaceArrayItem = <T>(array: T[], item: T) => {
  const index = array.indexOf(item)
  index === -1 ? array.push(item) : array.splice(index, 1, item)
}

export const addOrRemoveArrayItem = <T>(array: T[], item: T) => {
  const index = array.indexOf(item)
  index === -1 ? array.push(item) : array.splice(index, 1)
}

export const removeArrayItem = <T>(array: T[], item: T) => {
  const index = array.indexOf(item)
  if (index === -1) return
  array.splice(index, 1)
}

export const stripTimeFromDate = (date: Date) => {
  const dateWithTime = new Date(date)
  return new Date(dateWithTime.getFullYear(), dateWithTime.getMonth(), dateWithTime.getDate())
}

export const isSmsEnabled = (userProfile: UserProfile) => {
  return !userProfile.opted_out_date && userProfile.sms_frequency !== 'never'
}
