import { FileInput, Label, Switch, TextArea } from '@blueprintjs/core'
import { DateInput } from '@blueprintjs/datetime'
import React from 'react'

interface NewsPostDialogBodyProps {
  title: string
  deliveryDate: Date
  featured: boolean
  message: string
  image?: File
  thumbnail?: File
}

export const NewsPostDialogBody = (props: NewsPostDialogBodyProps) => {
  const { title, deliveryDate, featured, message, image, thumbnail } = props

  const formatDate = (date: Date) => {
    const dateString = date.toLocaleDateString('en-US')
    const timeString = date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' })
    return `${dateString} ${timeString}`
  }

  const parseDate = (str: string) => new Date(str)

  return (
    <div>
      <div className="mtb-1">
        <Label>Title</Label>
        <input className="bp3-input" type="text" dir="auto" disabled={true} value={title} />
      </div>

      <div className="mtb-1">
        <Label>Image</Label>
        <FileInput text={image ? image.name : ''} disabled={true} />
      </div>

      <div className="mtb-1">
        <Label>Thumbnail</Label>
        <FileInput text={thumbnail ? thumbnail.name : ''} disabled={true} />
      </div>

      <div className="mtb-1">
        <Label>Delivery Date</Label>
        <DateInput
          formatDate={formatDate}
          parseDate={parseDate}
          disabled={true}
          value={deliveryDate}
        />
      </div>

      <div className="mtb-1">
        <Switch disabled={true} checked={featured} label="Is featured?" />
      </div>

      <div className="mtb-1">
        <Label>Text Message</Label>
        <TextArea fill={true} disabled={true} value={message} />
      </div>
    </div>
  )
}
