import React from 'react'
import { Card, Switch, NumericInput } from '@blueprintjs/core'
import { convertPointsToDollars } from '../utils'

interface OnboardingMessageConfigProps {
  enrollmentComplete: boolean
  bonusPoints: number
  bonusPointsTotal: number
  onValueChange: (value: number) => void
  onChange: () => void
}

export const OnboardingMessageConfig = (props: OnboardingMessageConfigProps) => {
  const { enrollmentComplete, onChange } = props
  return (
    <Card className="mtb-">
      <Switch
        checked={enrollmentComplete}
        onChange={onChange}
        label="Set enrollment complete"
        className="mb-2"
      />

      <label className="bp3-label .modifier mb-1">
        Bonus Points <span className="bp3-text-muted">(optional)</span>
      </label>

      <NumericInput
        placeholder="Enter bonus points..."
        onValueChange={props.onValueChange}
        value={props.bonusPoints}
      />
      <div className="mt-1">
        <div>
          <b>{convertPointsToDollars(props.bonusPointsTotal)}</b> Total
        </div>
        <div>
          <b>{convertPointsToDollars(props.bonusPoints)}</b> Per Team Member
        </div>
      </div>
    </Card>
  )
}
