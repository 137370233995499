import React, { useState } from 'react'
import { isEmpty } from 'lodash'

import { Callout, FileInput, FormGroup, Intent, Label, Checkbox } from '@blueprintjs/core'
import { postFlour, debouncedFormPost } from '../../api/backend'
import { ButtonsGroup } from '../../components/ButtonsGroup'
import { IntegerInput } from '../../components/common/Fields'
import { Flour, Store, StoreDataResponse, StoreGroup, Goals, GoalsDataResponse } from '../../types'
import { useDispatch, useSelector } from 'react-redux'
import { DATA_REDUCER, RootState } from '../../reduxStore'
import { GenericSelector, SelectionItem } from 'components/GenericSelector'
import { fetchGoalsThunk } from 'thunks'
import { DateTime as dt } from 'luxon'

interface StoreSelectorProps {
  onStoreSelected: (store: Store) => void
}

const StoreSelector = (props: StoreSelectorProps) => {
  const { onStoreSelected } = props

  const stores: StoreDataResponse = useSelector((state: RootState) => state[DATA_REDUCER].stores)
  const [buttonText, setButtonText] = useState('Select Store')

  const handleSelection = (selected: SelectionItem<Store>) => {
    const text = selected.label ? `${selected.title} (${selected.label})` : selected.title
    setButtonText(text)
    onStoreSelected(selected.item)
  }

  const allStores = Object.values(stores).map((s) => {
    return {
      id: s.id,
      title: s.name,
      label: s.group_name,
      item: s
    }
  })

  return (
    <GenericSelector items={allStores} buttonText={buttonText} onItemSelect={handleSelection} />
  )
}

enum FileStoreSelector {
  manual = 'manual',
  inFile = 'inFile'
}

interface CalloutState {
  title: string
  intent: Intent
  message?: string
  created?: number
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface UploadResponse {
  filename: string
  message?: string
  created: number
}

interface UploadProps {
  storeSelector?: FileStoreSelector
  storeGroups?: StoreGroup[]
  stores: Store[]
  date?: Date | null
  flourKeys?: string[]
  selectedTabName?: string[] | string | null
}

interface FileUploadsByStore {
  [key: string]: File
}
interface FlourByStore {
  [key: string]: Flour
}

const GENERIC_ID = 'generic'
const FILES_ID = 'files'

export const DataUpload = (props: UploadProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [filePaths, setFilePaths] = useState<FileUploadsByStore>({})
  const [isDuplicates, setIsDuplicates] = useState<boolean>(false)
  const [flourData, setFlourData] = useState<FlourByStore>({})
  const [callouts, setCallouts] = useState<CalloutState[]>([])
  const [isGoals, setIsGoals] = useState<boolean>(false)
  const [isRoster, setIsRoster] = useState<boolean>(false)
  const [selectedStore, setSelectedStore] = useState<Store | null>(null)
  const [selectedGoal, setSelectedGoal] = useState<Goals | null>(null)
  const { storeGroups, stores, date, selectedTabName } = props
  let { flourKeys, storeSelector } = props
  const dispatch = useDispatch()

  interface GoalSelectorProps {
    onGoalSelected: (goal: Goals) => void
  }

  const GoalSelector = (props: GoalSelectorProps) => {
    const { onGoalSelected } = props
    const goalsResponse: GoalsDataResponse = useSelector(
      (state: RootState) => state[DATA_REDUCER].goals
    )

    const filteredGoalsByStore = Object.values(goalsResponse)
    const [buttonText, setButtonText] = useState('Select Goal')

    const handleSelectionGoals = (selected: SelectionItem<Goals>) => {
      const text = selected.title
      setButtonText(text)
      onGoalSelected(selected.item)
    }

    const allGoals = Object.values(filteredGoalsByStore).map((s) => {
      return {
        id: s.id,
        store: s.store_id,
        title: s.title + ': ' + s.description,
        item: s
      }
    })

    return (
      <GenericSelector
        items={allGoals}
        buttonText={buttonText}
        onItemSelect={handleSelectionGoals}
      />
    )
  }

  const setError = (title = 'Error', message: string | undefined, created?: number) => {
    setCallouts((prevState: CalloutState[]) => {
      return [...prevState, { title, message, intent: 'danger', created }]
    })
  }

  const setSuccess = (title: string, message: string, created?: number) => {
    setCallouts((prevState: CalloutState[]) => {
      return [...prevState, { title, message, intent: 'success', created }]
    })
  }

  if (!storeSelector && storeGroups) {
    const storeGroupsHavingManualStores = storeGroups.filter(
      (storeGroup: StoreGroup) => storeGroup.manual_stores === true
    ).length

    storeSelector = FileStoreSelector.inFile
    if (storeGroupsHavingManualStores > 0) storeSelector = FileStoreSelector.manual
  }

  if (!flourKeys && storeGroups) {
    flourKeys = storeGroups.map((storeGroup: StoreGroup) => storeGroup.flour_fields).flat()
  }
  const handleDuplicateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsDuplicates(e.target.checked)
  }

  const handleGoalsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsGoals(e.target.checked)
  }

  const handleRosterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsRoster(e.target.checked)
  }

  const handleStoreSelection = (store: Store) => {
    setSelectedStore(store)
    dispatch(fetchGoalsThunk({ store_id: store.id }))
  }
  const handleGoalSelection = (goal: Goals) => {
    setSelectedGoal(goal)
  }

  const handleUpload = async () => {
    const formData = new FormData()

    // Clear out previous callouts
    setCallouts([])

    const filesDefined = !!Object.keys(filePaths).length
    const flourDefined = !!Object.keys(flourData).length

    if (!filesDefined && !flourDefined) {
      setError('Error', 'Please select a file or define Flour data', 0)
      return
    }

    if (!storeSelector) {
      setError('Error', 'Please define whether stores will be manually selected or not', 0)
      return
    }

    setIsLoading(true)

    if (filesDefined) {
      Object.keys(filePaths).forEach((file_id: string) => {
        formData.set(file_id, filePaths[file_id])
      })

      const storeGroupIds = JSON.stringify(
        storeGroups?.map((storeGroup: StoreGroup) => storeGroup.id)
      )

      const storeIds =
        storeSelector === FileStoreSelector.manual
          ? JSON.stringify(stores?.map((store: Store) => store.id))
          : null

      formData.set('upload_type', storeSelector)

      if (storeGroupIds) {
        formData.append('store_group_ids', storeGroupIds)
      }

      if (storeIds) {
        formData.append('store_ids', storeIds)
      }

      if (date) {
        formData.append('date', date.toJSON())
      }
      if (isDuplicates) {
        formData.append('allow_duplicate', String(isDuplicates))
      }
      if (selectedTabName) {
        formData.append('upload_sheet_name', selectedTabName as string)
      }

      if (isEmpty(storeGroups)) {
        setError('Error', 'No store group is selected', 0)
      } else {
        await debouncedFormPost('integration/data-upload', formData)
        setFilePaths({})
        setSuccess(
          'Success',
          'Data processing job successfully submitted. Please check the #data-upload or #data-upload-staging slack channels to see results',
          0
        )
      }
    }

    if (flourDefined) {
      // Get just ISO date if date is defined
      const parsedDate = date ? date.toJSON().split('T')[0] : null
      try {
        for (const flour of Object.values(flourData)) {
          if (parsedDate) {
            flour.data['date'] = parsedDate
          }
          await postFlour(flour)
        }
        setSuccess(`Success`, 'Created flour', Object.keys(flourData).length)
      } catch (error) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        setError(`Failure creating flour`, (error as any)?.message, 0)
      } finally {
        setFlourData({})
      }
    }
    setIsLoading(false)
  }

  const handleUploadV2 = async () => {
    const formData = new FormData()

    // Clear out previous callouts
    setCallouts([])

    const filesDefined = !!Object.keys(filePaths).length
    const storeId = selectedStore?.id ?? null
    const goalsId = selectedGoal?.id ?? null
    const currentDate = dt.now().toISODate().toString()

    if (!filesDefined) {
      setError('Error', 'Please select a file', 0)
      return
    }

    if (!(isGoals || isRoster)) {
      setError('Error', 'Must select upload type', 0)
      return
    }

    if (isGoals && isRoster) {
      setError('Error', 'Please select only one upload type', 0)
      return
    }

    setIsLoading(true)

    if (filesDefined) {
      Object.keys(filePaths).forEach((file_id: string) => {
        formData.set(file_id, filePaths[file_id])
      })

      if (currentDate) {
        formData.append('date', currentDate)
      }
      if (storeId) {
        formData.append('store_id', storeId)
      }
      if (goalsId) {
        formData.append('goal_id', goalsId)
      }
      if (isGoals) {
        formData.append('goals', String(isGoals))
      }
      if (isRoster) {
        formData.append('roster', String(isRoster))
      }

      if (isEmpty(storeId)) {
        setError('Error', 'No store is selected')
      } else {
        await debouncedFormPost('integration/store-and-process', formData)
        setFilePaths({})
        setSuccess(
          'Success',
          'Successfully saved and processed file. Please check the #data-upload or #data-upload-staging slack channels to see results'
        )
      }
    }
    setIsLoading(false)
  }

  const handleSaveToS3 = async () => {
    const formData = new FormData()

    // Clear out previous callouts
    setCallouts([])

    const filesDefined = !!Object.keys(filePaths).length
    const storeId = selectedStore?.id ?? null

    if (!filesDefined) {
      setError('Error', 'Please select a file', 0)
      return
    }

    setIsLoading(true)

    if (filesDefined) {
      Object.keys(filePaths).forEach((file_id: string) => {
        formData.set(file_id, filePaths[file_id])
      })

      if (storeId) {
        formData.append('store_id', storeId)
      }
      if (isGoals) {
        formData.append('goals', String(isGoals))
      }
      if (isRoster) {
        formData.append('roster', String(isRoster))
      }

      if (isEmpty(storeId)) {
        setError('Error', 'No store is selected')
      } else {
        await debouncedFormPost('integration/data-store', formData)
        setFilePaths({})
        setSuccess(
          'Success',
          'Successfully saved file to s3. Please check the #data-upload or #data-upload-staging slack channels to see results'
        )
      }
    }
    setIsLoading(false)
  }

  interface FileUploadInputProps {
    store?: Store
  }

  const FileUploadInput = (props: FileUploadInputProps) => {
    const { store } = props

    const setFileUploadPaths = (filesList: FileList) => {
      setFilePaths((prevState: FileUploadsByStore) => {
        const fileObject: File = filesList[0]
        prevState[store?.id ?? GENERIC_ID] = fileObject
        return { ...prevState }
      })
    }

    const inputLabel = store ? store.name : 'Generic Upload'

    const getInputText = () => {
      const defaultInputText = 'Choose a file ...'

      const fileIsAttached = filePaths[GENERIC_ID]
      const storeFileIsAttached = store && filePaths[store.id]

      if (!store && fileIsAttached) {
        return filePaths[GENERIC_ID].name ?? defaultInputText
      } else if (store && storeFileIsAttached) {
        return filePaths[store.id].name ?? defaultInputText
      } else {
        return defaultInputText
      }
    }

    return (
      <div className="mtb-2">
        <Label>{inputLabel}</Label>
        <FileInput
          text={getInputText()}
          inputProps={{ accept: '.csv, .xls, .xlsx' }}
          onInputChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const filesList = e.target.files
            if (filesList?.length) setFileUploadPaths(filesList)
          }}
        />
      </div>
    )
  }

  interface FlourUploadInputProps {
    store: Store
  }

  const FlourUploadInput = (props: FlourUploadInputProps) => {
    const { store } = props

    const setFlourValue = (key: string) => {
      return (value: number) => {
        setFlourData((prevState: FlourByStore) => {
          const storeFlour: Flour = prevState[store.id] ?? {
            store: store.id,
            data: {},
            allow_duplicate: isDuplicates
          }
          storeFlour.data[key] = value

          return {
            ...prevState,
            [store.id]: storeFlour
          }
        })
      }
    }

    if (!flourKeys || flourKeys.length === 0) {
      return <div />
    }

    const fields = flourKeys.map((key) => {
      return (
        <IntegerInput
          key={`${store.id} + ${key}`}
          label={key}
          value={flourData?.[store.id]?.data?.[key]}
          update={setFlourValue(key)}
        />
      )
    })
    return <div>{fields}</div>
  }

  const getFileInputs = () => {
    let fileInputs: React.ReactElement[] = []
    if (storeSelector === FileStoreSelector.manual && stores.length) {
      fileInputs = stores.map((store: Store) => {
        return (
          // eslint-disable-next-line react/prop-types
          <div key={store.id}>
            <FileUploadInput store={store} />
            <FlourUploadInput store={store} />
          </div>
        )
      })
    }
    return (
      <div>
        <FileUploadInput />
        {fileInputs}
      </div>
    )
  }

  const FileUploadInputV2 = (props: FileUploadInputProps) => {
    const { store } = props

    const setFileUploadPaths = (filesList: FileList) => {
      setFilePaths((prevState: FileUploadsByStore) => {
        const fileObject: File = filesList[0]
        prevState[store?.id ?? FILES_ID] = fileObject
        return { ...prevState }
      })
    }

    const inputLabel = store ? store.name : 'File Upload'

    const getInputText = () => {
      const defaultInputText = 'Choose a file ...'

      const fileIsAttached = filePaths[FILES_ID]
      const storeFileIsAttached = store && filePaths[store.id]

      if (!store && fileIsAttached) {
        return filePaths[FILES_ID].name ?? defaultInputText
      } else if (store && storeFileIsAttached) {
        return filePaths[store.id].name ?? defaultInputText
      } else {
        return defaultInputText
      }
    }

    return (
      <div className="mtb-2">
        <Label>{inputLabel}</Label>
        <FileInput
          text={getInputText()}
          inputProps={{ accept: '.csv, .xls, .xlsx' }}
          onInputChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const filesList = e.target.files
            if (filesList?.length) setFileUploadPaths(filesList)
          }}
        />
      </div>
    )
  }

  const getFileInputsV2 = () => {
    let fileInputs: React.ReactElement[] = []
    if (storeSelector === FileStoreSelector.manual && stores.length) {
      fileInputs = stores.map((store: Store) => {
        return (
          // eslint-disable-next-line react/prop-types
          <div key={store.id}>
            <FileUploadInputV2 store={store} />
          </div>
        )
      })
    }
    return (
      <div>
        <FileUploadInputV2 />
        {fileInputs}
      </div>
    )
  }

  /** TO DO: Remove flour created once transitioned to data upload v2 */
  const responseCallouts = callouts?.length ? (
    callouts.map((callout: CalloutState) => (
      <Callout
        key={callout.title}
        title={callout.title}
        intent={callout.intent}
        className="admin-panel"
      >
        {callout.message}
        <br />
        <strong>
          {callout.created} {callout.created !== 1 ? 'entries' : 'entry'} created
        </strong>
      </Callout>
    ))
  ) : (
    <div />
  )

  const dataUpload = (
    <div className="mtb-1">
      <h2>Data upload</h2>
      <p>This will generate points for configured goals</p>
      {/* Toggle Duplicate file uploads */}
      <Checkbox
        checked={isDuplicates}
        label={`Allow duplicate file uploads ${isDuplicates ? '✅' : '❌'}`}
        onChange={handleDuplicateChange}
        className="mt-1"
      />
      <FormGroup helperText="." labelFor="file-input" inline={true} className="mr-1">
        {getFileInputs()}
      </FormGroup>
      <ButtonsGroup
        isLoading={isLoading}
        className="mr-1"
        hideIcon
        onSendButtonClick={handleUpload}
        text="Upload"
      />
    </div>
  )

  const rosterOrGoalsUpload = (
    <div className="mtb-1">
      <h2>Upload Goals or Roster</h2>
      <FormGroup label="Store">
        <StoreSelector onStoreSelected={handleStoreSelection} />
      </FormGroup>
      <FormGroup labelFor="file-input" inline={true} className="mr-1 mb-0">
        {getFileInputsV2()}
      </FormGroup>
      <p>Select if file upload is a goals or roster file.</p>
      <Checkbox
        checked={isGoals}
        label={`Upload Goals File`}
        onChange={handleGoalsChange}
        className="mt-1"
      />
      <Checkbox
        checked={isRoster}
        label={`Upload Roster File`}
        onChange={handleRosterChange}
        className="mt-1 mb-2"
      />
      <FormGroup label="Goals" className="mb-3">
        <p>Select related goal if uploading a goal file.</p>
        <GoalSelector onGoalSelected={handleGoalSelection} />
      </FormGroup>
      <ButtonsGroup
        isLoading={isLoading}
        className="mr-1"
        hideIcon
        onSendButtonClick={handleUploadV2}
        text="Store and Process"
      />
      <ButtonsGroup
        isLoading={isLoading}
        className="ml-1"
        hideIcon
        onSendButtonClick={handleSaveToS3}
        text="Store File"
      />
    </div>
  )

  return (
    <div className="mbt-2">
      {responseCallouts}
      {dataUpload}
      {rosterOrGoalsUpload}
    </div>
  )
}
