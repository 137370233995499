import React, { useState } from 'react'

import {
  InputGroup,
  Label,
  TextArea,
  Collapse,
  Button,
  Checkbox,
  NumericInput,
  IconName
} from '@blueprintjs/core'

import { ProductFulfillmentType, RewardProduct, WeGiftProduct } from '../types'

interface WeGiftProductProps {
  product?: WeGiftProduct
  onChange: (product: WeGiftProduct) => void
}

const defaultWeGiftProduct = {
  id: '',
  reward_id: '',
  product_code: '',
  amount: 0
}

const defaultInventoryProduct = {
  id: '',
  reward_id: '',
  card_id: '',
  serial: '',
  available: false,
  custom_redemption_message: ''
}

export const WeGiftFulfillment = (props: WeGiftProductProps) => {
  const product = props.product || defaultWeGiftProduct

  const initState = {
    ...product,
    fulfillmentType: ProductFulfillmentType.weGift
  }

  const [state, setState] = useState(initState)
  const handleCodeChange = (code: string) => {
    const newState = {
      ...state,
      product_code: code
    }

    setState(newState)
    props.onChange(newState)
  }

  const handleAmountChange = (amount: number) => {
    const newState = {
      ...state,
      amount
    }

    setState(newState)
    props.onChange(newState)
  }

  return (
    <div>
      <div className="mtb-2">
        <Label>Product code</Label>
        <InputGroup
          type="text"
          value={state.product_code}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleCodeChange(e.currentTarget.value)
          }
        />
      </div>

      <div className="mtb-1">
        <Label>Amount</Label>
        <NumericInput
          placeholder="Enter amount"
          value={state.amount}
          onValueChange={(value: number) => handleAmountChange(value)}
        />
      </div>
    </div>
  )
}

interface InventoryProductProps {
  product?: RewardProduct
  onChange: (product: RewardProduct) => void
}

export const InventoryFulfillment = (props: InventoryProductProps) => {
  const product = props.product || defaultInventoryProduct

  const initState = {
    ...product,
    fulfillmentType: ProductFulfillmentType.inventory
  }

  const [state, setState] = useState(initState)

  const handleSerialChange = (serial: string) => {
    const newState = {
      ...state,
      serial
    }

    setState(newState)
    props.onChange(newState)
  }

  const handleCardChange = (cardId: string) => {
    const newState = {
      ...state,
      card_id: cardId
    }

    setState(newState)
    props.onChange(newState)
  }
  const handleAvailableChange = (available: boolean) => {
    const newState = {
      ...state,
      available
    }

    setState(newState)
    props.onChange(newState)
  }

  const handleCustomMessage = (message: string) => {
    const newState = {
      ...state,
      custom_redemption_message: message
    }

    setState(newState)
    props.onChange(newState)
  }

  return (
    <div>
      <div className="mtb-2">
        <Label>Serial</Label>
        <InputGroup
          type="text"
          value={state.serial}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleSerialChange(e.currentTarget.value)
          }
        />
      </div>

      <div className="mtb-1">
        <Label>Card ID</Label>
        <InputGroup
          type="text"
          placeholder="Enter Card ID"
          value={state.card_id}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleCardChange(e.currentTarget.value)
          }
        />
      </div>

      <div className="mtb-1">
        <Checkbox
          checked={state.available}
          label="Is Available"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleAvailableChange(e.target.checked)
          }
        />
      </div>

      <div className="mtb-1">
        <Label>Custom Redemption Message</Label>
        <TextArea
          fill={true}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
            handleCustomMessage(e.target.value)
          }
          value={state.custom_redemption_message}
        />
      </div>
    </div>
  )
}

interface ToggleButtonProps {
  text: string
  icon: IconName
  isOpen: boolean
  children: React.ReactNode
}

export const ToggleButton = (props: ToggleButtonProps) => {
  const [isOpen, setIsOpen] = useState(props.isOpen)

  return (
    <div>
      <Button
        icon={props.icon}
        className="w100"
        onClick={() => setIsOpen(!isOpen)}
        text={props.text}
      />
      <Collapse className="w100 mb-2" isOpen={isOpen}>
        {props.children}
      </Collapse>
    </div>
  )
}

interface NewProductFulfillmentProps {
  source: ProductFulfillmentType
  onChange: (product: WeGiftProduct | RewardProduct | undefined) => void
}

export const NewProductFulfillment = (props: NewProductFulfillmentProps) => {
  const [state, setState] = useState<WeGiftProduct | RewardProduct>()

  const handleWeGiftProductChange = (product: WeGiftProduct) => setState({ ...product })
  const handleInventoryProductChange = (product: RewardProduct) => setState({ ...product })

  const productFulfillment = () => {
    switch (props.source) {
      case ProductFulfillmentType.weGift:
        return <WeGiftFulfillment onChange={handleWeGiftProductChange} />
      case ProductFulfillmentType.inventory:
        return <InventoryFulfillment onChange={handleInventoryProductChange} />
      default:
        return <div />
    }
  }

  return (
    <div>
      {productFulfillment()}
      <Button
        disabled={state ? false : true}
        icon={'add'}
        className="w100"
        intent={'success'}
        onClick={() => props.onChange(state)}
        text={'Attach Product to Reward'}
      />
    </div>
  )
}
