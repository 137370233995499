// React
import React from 'react'

// Components
import { Card } from '@blueprintjs/core'
import { DateRangeSelector } from './DateRangeSelector'

interface AdminSearchCardProps {
  options?: string[]
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const AdminSearchCard = (_props: AdminSearchCardProps) => {
  return (
    <div className="admin-row">
      <Card className="admin-card">
        <div>
          <DateRangeSelector />
        </div>
      </Card>
    </div>
  )
}
