import { createStore, applyMiddleware, compose, combineReducers } from 'redux'
import { Reducer, Action } from 'redux'
import thunk, { ThunkDispatch } from 'redux-thunk'

import { createLogger } from 'redux-logger'

import { createReducer } from './reducerGenerator'
import {
  DoughEntryDataResponse,
  RewardDataResponse,
  StoreDataResponse,
  StoreGroupDataResponse,
  WorkerDataResponse,
  UserProfileDataResponse,
  BatchPostMessageResponse,
  DateRange,
  DataIntegrationDataResponse,
  QuestionAnsweredDataResponse,
  WorkerLatestAnswers,
  CachedPointDataResponse,
  UploadSheetNamesResponse,
  Whoami, RosterSheetNamesResponse, GoalsDataResponse,
} from './types'

// Manage transient states like loading and success responses
export const DISPLAY_STATE_REDUCER = 'display'
type DisplayStateReducer = Reducer<{
  dataFetchError: boolean
  isLoading: boolean
}>
const defaultDisplayStateReducer = {
  dataFetchError: false,
  isLoading: false
}

// Manage stateful data fetched from the backend
export const DATA_REDUCER = 'data'
export enum DataSelector {
  goals = 'goals',
  doughEntries = 'doughEntries',
  workers = 'workers',
  userProfiles = 'userProfiles',
  stores = 'stores',
  storeGroups = 'storeGroups',
  rewards = 'rewards',
  integrations = 'integrations',
  questionsAnswered = 'questionsAnswered',
  latestAnswersByWorkerId = 'latestAnswersByWorkerId',
  cachedPoints = 'cachedPoints',
  uploadSheetNames = 'uploadSheetNames',
  rosterSheetNames = 'rosterSheetNames',
}
type DataReducer = Reducer<{
  [DataSelector.goals]: GoalsDataResponse
  [DataSelector.doughEntries]: DoughEntryDataResponse
  [DataSelector.workers]: WorkerDataResponse
  [DataSelector.userProfiles]: UserProfileDataResponse
  [DataSelector.stores]: StoreDataResponse
  [DataSelector.storeGroups]: StoreGroupDataResponse
  [DataSelector.rewards]: RewardDataResponse
  [DataSelector.integrations]: DataIntegrationDataResponse
  [DataSelector.questionsAnswered]: QuestionAnsweredDataResponse
  [DataSelector.latestAnswersByWorkerId]: WorkerLatestAnswers
  [DataSelector.cachedPoints]: CachedPointDataResponse
  [DataSelector.uploadSheetNames]: UploadSheetNamesResponse
  [DataSelector.rosterSheetNames]: RosterSheetNamesResponse
}>
const defaultDataReducer = {
  [DataSelector.goals]: {},
  [DataSelector.doughEntries]: {},
  [DataSelector.workers]: {},
  [DataSelector.userProfiles]: {},
  [DataSelector.stores]: {},
  [DataSelector.storeGroups]: {},
  [DataSelector.rewards]: {},
  [DataSelector.integrations]: {},
  [DataSelector.questionsAnswered]: {},
  [DataSelector.latestAnswersByWorkerId]: {},
  [DataSelector.cachedPoints]: {},
  [DataSelector.uploadSheetNames]: {},
  [DataSelector.rosterSheetNames]: {},
}

// Generate the current date and 7 days ago in UTC
const initializeDate = (): DateRange => {
  const now = new Date()
  now.setUTCHours(0, 0, 0, 0)
  now.setDate(now.getDate() + 1)
  const weekAgo = new Date()
  weekAgo.setUTCHours(0, 0, 0, 0)
  weekAgo.setDate(weekAgo.getDate() - 6)
  return [weekAgo, now]
}

// Manage stateful user input data
export const INPUT_REDUCER = 'input'
export enum InputSelector {
  workers = 'workers',
  stores = 'stores',
  storeGroups = 'storeGroups',
  dateRange = 'dateRange',
  goals = 'goals'
}
type InputReducer = Reducer<{
  [InputSelector.workers]: WorkerDataResponse
  [InputSelector.stores]: StoreDataResponse
  [InputSelector.storeGroups]: StoreGroupDataResponse
  [InputSelector.dateRange]: DateRange
  [InputSelector.goals]: GoalsDataResponse
}>

const defaultInputReducer = {
  [InputSelector.goals]: {},
  [InputSelector.workers]: {},
  [InputSelector.stores]: {},
  [InputSelector.storeGroups]: {},
  [InputSelector.dateRange]: initializeDate()
}

export const AUTH_REDUCER = 'auth'
type AuthReducer = Reducer<{
  whoami: Whoami | null
  isUnauthorized: boolean
}>
const defaultAuthReducer = {
  whoami: null,
  isUnauthorized: false
}

interface SendMessageError {
  status?: number
  url?: string
  message?: string
  when?: number
}

export const MESSAGE_REDUCER = 'message'
type MessageReducer = Reducer<{
  message: string
  bonus: number
  enrollment_complete: boolean
  news_post?: {
    title: string
    message: string
    published_at: Date
    featured: boolean
    image?: File
    thumbnail?: File
  }
  user_profile_ids: string[]
  isSending: boolean
  requestFailed: boolean
  requestSucceeded: boolean
  errors: SendMessageError[]
  response: BatchPostMessageResponse
}>
export const defaultMessageReducer = {
  text: '',
  user_profile_ids: [],
  bonus: 0,
  enrollment_complete: false,
  isSending: false,
  requestFailed: false,
  requestSucceeded: false,
  errors: [],
  response: { text: '', user_profile_ids: [] }
}

const reducers = {
  [INPUT_REDUCER]: createReducer(INPUT_REDUCER, defaultInputReducer) as InputReducer,
  [DISPLAY_STATE_REDUCER]: createReducer(
    DISPLAY_STATE_REDUCER,
    defaultDisplayStateReducer
  ) as DisplayStateReducer,
  [DATA_REDUCER]: createReducer(DATA_REDUCER, defaultDataReducer) as DataReducer,
  [AUTH_REDUCER]: createReducer(AUTH_REDUCER, defaultAuthReducer) as AuthReducer,
  [MESSAGE_REDUCER]: createReducer(MESSAGE_REDUCER, defaultMessageReducer) as MessageReducer
}

const middlewares = [thunk, createLogger()]

const combinedReducers = combineReducers(reducers)

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export const reduxStore = createStore(
  combinedReducers,
  composeEnhancers(applyMiddleware(...middlewares))
)

export type RootState = ReturnType<typeof combinedReducers>

export type OnarollThunkDispatch = ThunkDispatch<RootState, void, Action>
