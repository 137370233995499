import { MockWorker } from '../types'
import { getApiHost } from '../urls/router-paths'

// Freeform message editor
export const VARIABLES = [
  {
    $url_root: 'token url to root',
    $url_perks: 'token url to perks page',
    $url_stats: 'token url to stats page V3 APP ONLY',
    $url_help: 'token url to help page',
    $first: 'Team Member first name',
    $last: 'Team Member last name',
    $display_name: 'Team Member display mane (UserProfile)',
    $bonus_points: 'bonus points assigned',
    $points_accrued: 'total points accrued',
    $points_balance: 'total points available',
    $points_remaining: 'points needed for next reward',
    $next_reward: 'title of next reward'
  }
]

export const TOKEN = 'DmUCeJhJ'

const getUrlRoot = () => {
  const host = getApiHost()
  if (host && host.length > 0) {
    return host
  } else {
    return `${window.location.protocol}//${window.location.hostname}`
  }
}

export const ROOT_URL = getUrlRoot() + '/a/' + TOKEN

export const FIRST_NAME = 'Marie'

export const LAST_NAME = 'Curie'

export const DISPLAY_NAME = 'Marie Curie'

export const ACCRUED_POINTS = 1555

export const BALANCE_POINTS = 100

export const NEXT_REWARD = '1 month of Spotify premium 🎶'

export const REMAINING_POINTS = '200'

export const EMOJIS = [
  '🚀',
  '🔥',
  '💥',
  '❤️',
  '🙏',
  '😆',
  '😂',
  '😀',
  '😊',
  '😌',
  '🙂',
  '😝',
  '👉',
  '👈',
  '☝️',
  '👇',
  '👆',
  '👋',
  '👌',
  '🤞',
  '🤟'
]

export const MOCK_WORKER: MockWorker = {
  first: FIRST_NAME,
  last: LAST_NAME,
  display_name: DISPLAY_NAME,
  url_root: ROOT_URL,
  url_perks: ROOT_URL + '/perks',
  url_stats: ROOT_URL + '/stats',
  url_help: ROOT_URL + '/help',
  points_accrued: ACCRUED_POINTS,
  points_balance: BALANCE_POINTS,
  points_remaining: REMAINING_POINTS,
  next_reward: NEXT_REWARD
}

export const MOCK_SMS_REDEEM_MESSAGE =
  'BOOM!!💥 You just unlocked a reward! Hit the link below to redeem it' +
  ' ' +
  'https://app.onaroll.co/perks'

const SMS_FREQUENCY_OPTIONS = [
  { id: 'daily', name: 'Daily', label: '' },
  { id: 'weekly', name: 'Weekly', label: '' },
  { id: 'never', name: 'Never', label: '' }
]

export const USER_SETTINGS = { messages_frequency: [...SMS_FREQUENCY_OPTIONS] }

export const INDEFINITE_REWARD_EXPIRY_DATE = new Date('2050-12-31 00:00:00+00')
