import React, { useState } from 'react'
import { GenericDataTable } from './GenericDataTable'

import { RewardData, WorkerData, UserProfileData } from './data'

// Components
import { LibrarySidebar } from './LibrarySidebar'
import { Card, Button } from '@blueprintjs/core'

// Types
import { Reward, SetUpViews, Worker, Store, UserProfile } from '../types'

export const RewardDataTable = () => {
  const [state, setState] = useState({ sideBarData: {} })

  enum ReferencedValues {
    stores = 'stores',
    storeGroups = 'storeGroups'
  }
  const referenceColumns = {
    [ReferencedValues.stores]: 'name',
    [ReferencedValues.storeGroups]: 'name'
  }
  const columns = {
    title: 'Title',
    points: 'Points',
    active: 'Active',
    [ReferencedValues.stores]: 'Stores',
    [ReferencedValues.storeGroups]: 'Store Groups'
  }

  const data = RewardData()
  const handleOnEditClick = (row: Reward) => {
    setState({ ...state, sideBarData: row })
  }

  const actions = (
    <div>
      <Button
        icon="plus"
        text="Create"
        intent="primary"
        onClick={() => setState({ sideBarData: {} })}
      />
    </div>
  )

  return (
    <div className="admin-main">
      <div className="datatable-section">
        <Card>{actions}</Card>
        <GenericDataTable
          onButtonClick={handleOnEditClick}
          columns={columns}
          referenceColumns={referenceColumns}
          data={data}
        />
      </div>

      <LibrarySidebar view={SetUpViews.perks} targetRow={state.sideBarData} />
    </div>
  )
}

interface StoreDataTableProps {
  data: Store[]
}

export const StoreDataTable = (props: StoreDataTableProps) => {
  // Local state
  const [state, setState] = useState({ sideBarData: {} })

  const handleOnEditClick = (row: Store) => {
    setState({ ...state, sideBarData: row })
  }

  const columns = {
    name: 'Name',
    group_name: 'Group',
    timezone: 'Timezone'
  }
  const editButtonTitle = 'Details'
  const data = props.data ?? []

  return (
    <div className="admin-main">
      <GenericDataTable
        buttonTitle={editButtonTitle}
        columns={columns}
        data={data}
        onButtonClick={handleOnEditClick}
      />
      <LibrarySidebar view={SetUpViews.stores} targetRow={state.sideBarData} />
    </div>
  )
}

export const WorkerDataTable = () => {
  // Local state
  const [state, setState] = useState({ sideBarData: {} })

  const handleOnEditClick = (row: Worker) => setState({ ...state, sideBarData: row })

  enum ReferencedValues {
    store = 'store'
  }
  const referenceColumns = {
    [ReferencedValues.store]: 'name'
  }
  const columns = {
    first_name: 'First',
    last_name: 'Last',
    [ReferencedValues.store]: 'Store',
    outside_id: 'Outside ID',
    phone: 'Phone',
    active: 'Active'
  }

  const data = WorkerData()

  return (
    <div className="admin-main">
      <GenericDataTable
        columns={columns}
        onButtonClick={handleOnEditClick}
        referenceColumns={referenceColumns}
        data={data}
      />
      <LibrarySidebar view={SetUpViews.workers} targetRow={state.sideBarData} />
    </div>
  )
}

export const UserProfileDataTable = () => {
  // Local state
  const [state, setState] = useState({ sideBarData: {} })

  const handleOnEditClick = (row: UserProfile) => setState({ ...state, sideBarData: row })

  const columns = {
    first_name: 'First',
    last_name: 'Last',
    full_name: 'Full Name',
    display_name: 'Display Name',
    phone: 'Phone',
    sms_frequency: 'SMS Frequency',
    birthday: 'Birthday',
    notes: 'Notes'
  }

  const data = Object.values(UserProfileData())

  return (
    <div className="admin-main">
      <GenericDataTable columns={columns} onButtonClick={handleOnEditClick} data={data} />
      <LibrarySidebar view={SetUpViews.users} targetRow={state.sideBarData} />
    </div>
  )
}
