import { StateType } from './types'

const UPDATE = 'UPDATE'

// This library should not depend circularly on anything in our app: state can be anything

interface UpdateAction {
  type: string
  reducer: string
  tag: string
  state: StateType
}

export const update = (reducer: string, tag: string, state: StateType, type = UPDATE) => ({
  reducer,
  tag,
  state,
  type
})

export const createReducer = (name: string, initialState: StateType) => (
  state = initialState,
  action: UpdateAction
) => {
  if (action.reducer === name) {
    const nextState = action.state
    return {
      ...state,
      ...nextState,
      _lastAction: action.tag
    }
  }
  return state
}
