import React, { ChangeEvent, FormEvent } from 'react'
import { Card, FileInput, Switch } from '@blueprintjs/core'
import { DateInput, TimePrecision } from '@blueprintjs/datetime'

interface NewsPostMessageConfigProps {
  title: string
  deliveryDate: Date
  featured: boolean
  image?: File
  thumbnail?: File
  onTitleChange: (event: ChangeEvent<HTMLInputElement>) => void
  onImageChange: (event: FormEvent<HTMLInputElement>) => void
  onThumbnailChange: (event: FormEvent<HTMLInputElement>) => void
  onDeliveryDateChange: (selectedDate: Date, isUserChange?: boolean) => void
  onFeaturedChange: () => void
}

export const NewsPostMessageConfig = (props: NewsPostMessageConfigProps) => {
  const {
    title,
    deliveryDate,
    featured,
    image,
    thumbnail,
    onTitleChange,
    onImageChange,
    onThumbnailChange,
    onDeliveryDateChange,
    onFeaturedChange
  } = props

  const formatDate = (date: Date) => {
    const dateString = date.toLocaleDateString('en-US')
    const timeString = date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' })
    return `${dateString} ${timeString}`
  }

  const parseDate = (str: string) => new Date(str)

  return (
    <Card className="mtb-2">
      <label className="bp3-label">Title</label>
      <input
        className="bp3-input mb-1"
        type="text"
        dir="auto"
        onChange={onTitleChange}
        value={title}
        required={true}
      />
      <label className="bp3-label">
        Image <span className="bp3-text-muted">(Min size: 1024x576px, Aspect ratio: 16:9)</span>
      </label>
      <FileInput
        className="mb-1"
        text={image ? image.name : 'Choose image...'}
        onInputChange={onImageChange}
      />
      <label className="bp3-label">
        Thumbnail <span className="bp3-text-muted">(Min size: 256x256px, Aspect ratio: 1:1)</span>
      </label>
      <FileInput
        className="mb-1"
        text={thumbnail ? thumbnail.name : 'Choose thumbnail...'}
        onInputChange={onThumbnailChange}
      />
      <label className="bp3-label mb-1">Delivery Date</label>
      <DateInput
        closeOnSelection={false}
        formatDate={formatDate}
        parseDate={parseDate}
        value={deliveryDate}
        onChange={onDeliveryDateChange}
        timePickerProps={{
          precision: TimePrecision.MINUTE,
          showArrowButtons: true,
          useAmPm: true
        }}
      />
      <Switch
        className="mt-1"
        checked={featured}
        onChange={onFeaturedChange}
        label="Is featured?"
      />
    </Card>
  )
}
