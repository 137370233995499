// React
import React, { useState } from 'react'

// Components
import { Collapse, Button, IconName } from '@blueprintjs/core'

interface ToggleButtonProps {
  text: string
  isOpen: boolean
  children: React.ReactNode
  iconName?: IconName
  disabled?: boolean
}

export const ToggleButton = (props: ToggleButtonProps) => {
  const [isOpen, setIsOpen] = useState(props.isOpen)

  return (
    <div>
      <Button
        onClick={() => setIsOpen(!isOpen)}
        icon={props.iconName || 'expand-all'}
        text={props.text}
        disabled={props.disabled ?? false}
      />
      <Collapse className="mtb-1" isOpen={props.disabled ? false : isOpen}>
        {props.children}
      </Collapse>
    </div>
  )
}
