import React, { useState } from 'react'
import { DATA_REDUCER, RootState } from '../reduxStore'
import { useSelector, useDispatch } from 'react-redux'
import { fetchStoreThunk, putStoreDataThunk, postStoreDataThunk } from '../thunks'
import { FormGroup, InputGroup, Label, Card, Icon, Colors } from '@blueprintjs/core'
import { ToggleButton } from '../components/common/ToggleButton'
import { GenericSelector, SelectionItem, GenericMultiSelector } from './GenericSelector'
import { SubmitButton, Action } from './SubmitButton'
import {
  Worker,
  Store,
  StoreGroup,
  Reward,
  WorkerDataResponse,
  StoreGroupDataResponse,
  RewardDataResponse
} from '../types'

const defaultState = {
  id: '',
  name: '',
  timezone: '',
  group_id: '',
  group_name: '',
  points: 0,
  worker_count: 0
}

export const StoreSidebarForm = (props: Store) => {
  // Redux Store
  const workerResponse: WorkerDataResponse = useSelector(
    (state: RootState) => state[DATA_REDUCER].workers
  )

  const storeGroupResponse: StoreGroupDataResponse = useSelector(
    (state: RootState) => state[DATA_REDUCER].storeGroups
  )

  const rewardResponse: RewardDataResponse = useSelector(
    (state: RootState) => state[DATA_REDUCER].rewards
  )
  const allRewards: Reward[] = Object.values(rewardResponse)

  // Selector Options
  const storeGroupItems: SelectionItem<StoreGroup>[] = Object.values(storeGroupResponse).map(
    (storeGroup: StoreGroup) => ({
      id: storeGroup.id,
      title: storeGroup.name,
      item: storeGroup
    })
  )

  const rewardsItems: SelectionItem<Reward>[] = allRewards.map((reward: Reward) => ({
    id: reward.id,
    title: reward.title,
    item: reward
  }))

  const storeWorkers = Object.values(workerResponse).filter(
    (worker) => worker.store_id === props.id
  )

  const activeStoreWorkers = storeWorkers.filter((worker) => worker.active)

  const inactiveStoreWorkers = storeWorkers.filter((worker) => !worker.active)

  const storeRewards = allRewards.filter((reward: Reward) => reward.store_ids.includes(props.id))

  //Local State
  const initState = props ?? defaultState
  const [state, setState] = useState<Store>({
    ...initState
  })
  const [selectedWorkers, setSelectedWorkers] = useState<string[]>([])
  const [rewardsSelected, setRewardsSelected] = useState<Reward[]>(storeRewards ?? [])

  const selectedRewardsItems: SelectionItem<Reward>[] = rewardsSelected.map((reward: Reward) => ({
    id: reward.id,
    title: reward.title,
    item: reward
  }))

  // Internal methods
  const toggleIndividualWorker = (workerId: string) => {
    const selectedStoreWorkers = selectedWorkers

    const workerIndex: number = selectedStoreWorkers.indexOf(workerId)

    if (workerIndex === -1) {
      selectedStoreWorkers.push(workerId)
    } else {
      selectedStoreWorkers.splice(workerIndex, 1)
    }

    setSelectedWorkers([...selectedStoreWorkers])
  }

  const renderWorkerInfo = (worker: Worker) => {
    const isSelected = selectedWorkers.includes(worker.id)
    const backgroundColor = isSelected ? Colors.WHITE : Colors.BLUE5
    return (
      <Card
        className="databar"
        interactive={true}
        style={{ background: backgroundColor }}
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onClick={(_event: React.MouseEvent<HTMLDivElement>) => {
          toggleIndividualWorker(worker.id)
        }}
      >
        <div className="databar-text">
          <div>
            <Icon className="mr-1" icon="user" iconSize={20} intent="primary" />
            <b>{worker.first_name + ' ' + worker.last_name}</b>
          </div>

          <span>{worker.phone}</span>
        </div>
        <div className="databar-text mt-1">
          <span>
            <b>Onboarding seen:</b> {worker.onboarding_modal_seen ? 'Yes' : 'No'}
          </span>
        </div>
      </Card>
    )
  }

  // Persistence and data store operations
  const dispatch = useDispatch()
  const dispatchUpdateOperation = async () => {
    const request = await dispatch(putStoreDataThunk(state))
    dispatch(fetchStoreThunk())

    return request
  }

  const dispatchCreateOperation = async () => {
    const request = await dispatch(postStoreDataThunk(state))
    dispatch(fetchStoreThunk())

    return request
  }

  // Components

  const title = (
    <div className="mtb-2">
      <Label>Title</Label>
      <InputGroup
        type="text"
        value={state.name}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setState({ ...state, name: e.currentTarget.value })
        }
      />
    </div>
  )

  const activeWorkers = (
    <div className="mtb-2">
      <ToggleButton
        iconName={'user'}
        text={`${activeStoreWorkers?.length} Active Team Members`}
        isOpen={false}
      >
        <div>{activeStoreWorkers.map((worker: Worker) => renderWorkerInfo(worker))}</div>
      </ToggleButton>
    </div>
  )

  const inactiveWorkers = (
    <div className="mtb-2">
      <ToggleButton
        iconName={'user'}
        text={`${inactiveStoreWorkers?.length} Inactive Team Members`}
        isOpen={false}
      >
        <div>{inactiveStoreWorkers.map((worker: Worker) => renderWorkerInfo(worker))}</div>
      </ToggleButton>
    </div>
  )

  const storeGroup = (
    <div className="mtb-2">
      <Label>Store Group</Label>

      <GenericSelector
        items={storeGroupItems}
        buttonText={storeGroupResponse[state.group_id]?.name || 'Store'}
        onItemSelect={(item) =>
          setState((prevState: Store) => {
            prevState.group_id = item.id
            prevState.group_name = item.title
            return { ...prevState }
          })
        }
      />
    </div>
  )

  const perks = (
    <div className="mtb-2">
      <Label>Perks</Label>
      <GenericMultiSelector
        items={rewardsItems}
        selectedItems={selectedRewardsItems}
        placeholder="Perks"
        onItemSelect={(item: SelectionItem<Reward>) => {
          setRewardsSelected((prev: Reward[]) => {
            return Array.from(new Set([...prev, item.item]))
          })
        }}
        onItemRemove={(item: SelectionItem<Reward>) => {
          setRewardsSelected((prev: Reward[]) => {
            return prev.filter((comp: Reward) => comp.id !== item.id)
          })
        }}
      />
    </div>
  )

  const actions = state.id ? (
    <div className="mt-2">
      <div className="mtb-1">
        <SubmitButton object={state} action={Action.update} apiCall={dispatchUpdateOperation} />
      </div>

      <div className="mtb-1">
        <SubmitButton object={state} action={Action.duplicate} apiCall={dispatchCreateOperation} />
      </div>
    </div>
  ) : (
    <div className="mt-2">
      <SubmitButton object={state} action={Action.create} apiCall={dispatchCreateOperation} />
    </div>
  )

  return (
    <FormGroup helperText="." labelFor="text-input">
      <h2>{state.name ?? 'Add a new Store'}</h2>
      {title}
      {storeGroup}
      {activeWorkers}
      {inactiveWorkers}
      {perks}
      {actions}
    </FormGroup>
  )
}
