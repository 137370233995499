/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { DATA_REDUCER, RootState } from '../reduxStore'
import { useDispatch } from 'react-redux'
import { postRewardsDataThunk, putRewardsDataThunk, fetchRewardThunk } from '../thunks'

import {
  FormGroup,
  InputGroup,
  Label,
  Card,
  Text,
  TextArea,
  Switch,
  NumericInput,
  Button
} from '@blueprintjs/core'
import { DateTimePickerField } from '../components/common/Fields'
import {
  InventoryFulfillment,
  ToggleButton,
  WeGiftFulfillment,
  NewProductFulfillment
} from './RewardProductFulfillment'

import { GenericSelector, SelectionItem, GenericMultiSelector } from './GenericSelector'

import { SubmitButton, Action } from './SubmitButton'

import {
  HasId,
  Store,
  StoreDataResponse,
  StoreGroup,
  StoreGroupDataResponse,
  Reward,
  MessageTextVariable,
  ProductFulfillmentType,
  WeGiftProduct,
  RewardProduct,
  MockWorker
} from '../types'

import {
  MOCK_SMS_REDEEM_MESSAGE as DEFAULT_SMS_MESSAGE,
  MOCK_WORKER,
  INDEFINITE_REWARD_EXPIRY_DATE
} from '../fixtures'

import { mergeToUniqueArray } from '../utils'

const defaultState = {
  id: '',
  active: false,
  points: 0,
  title: '',
  tagline: '',
  description: '',
  image: '',
  image_alternate: '',
  brand_color: '',
  custom_sms_message: DEFAULT_SMS_MESSAGE,
  effective_at: new Date(),
  expires_at: INDEFINITE_REWARD_EXPIRY_DATE,
  wegift_products: [],
  raise_product_id: null,
  reward_products: [],
  store_ids: [],
  store_group_ids: [],
  stores: [],
  storeGroups: []
}

type ProductFulfillment = WeGiftProduct | RewardProduct

export const PerksLibrarySidebarForm = (props: Reward) => {
  const storeResponse: StoreDataResponse = useSelector(
    (state: RootState) => state[DATA_REDUCER].stores
  )
  const allStores: Store[] = Object.values(storeResponse)

  const storeGroupResponse: StoreGroupDataResponse = useSelector(
    (state: RootState) => state[DATA_REDUCER].storeGroups
  )
  const allStoreGroups: StoreGroup[] = Object.values(storeGroupResponse)

  const initState = Object.keys(props).length ? props : defaultState
  const [state, setState] = useState<Reward>({ ...initState })

  const dispatch = useDispatch()

  // Stores Select Items
  const storeItems: SelectionItem<Store>[] = allStores.map((store: Store) => ({
    id: store.id,
    title: store.name,
    label: store.group_name,
    item: store
  }))

  // StoreGroup Select Items
  const storeGroupItems: SelectionItem<StoreGroup>[] = allStoreGroups.map((group: StoreGroup) => ({
    id: group.id,
    title: group.name,
    item: group
  }))
  // Local Store
  const [storesSelected, setStoresSelected] = useState<Store[]>(
    props.store_ids?.length
      ? allStores.filter((store: Store) => props.store_ids.includes(store.id))
      : []
  )
  const [storeGroupsSelected, setStoreGroupsSelected] = useState<StoreGroup[]>(
    props.store_group_ids?.length
      ? allStoreGroups.filter((group: StoreGroup) => props.store_group_ids.includes(group.id))
      : []
  )
  const [perkSourceSelected, setPerkSourceSelected] = useState<
    SelectionItem<ProductFulfillmentType>
  >()

  const selectedStoreItems: SelectionItem<Store>[] = storesSelected.map((store: Store) => ({
    id: store.id,
    title: store.name,
    item: store
  }))

  const selectedStoreGroupItems: SelectionItem<StoreGroup>[] = storeGroupsSelected.map(
    (storeGroup: StoreGroup) => ({
      id: storeGroup.id,
      title: storeGroup.name,
      item: storeGroup
    })
  )

  const weGiftProductsData = state.wegift_products ?? []
  const rewardProductsData = state.reward_products ?? []

  const rewardObject: Reward = {
    ...state,
    store_ids: storesSelected.map((item: Store) => item.id) ?? props.store_ids
  }

  const dispatchUpdateOperation = async () => {
    const request = await dispatch(putRewardsDataThunk(rewardObject))
    dispatch(fetchRewardThunk())

    return request
  }

  const dispatchCreateOperation = async () => {
    const request = await dispatch(postRewardsDataThunk(rewardObject))
    dispatch(fetchRewardThunk())

    return request
  }

  const displayLogo = (title: string, logo: string) => {
    if (!logo) return

    return (
      <div>
        <h4>{title}</h4>
        <div className="reward-logo-container">
          <div>
            <p>Light:</p>
            <img className="sidebar-logo reward-logo-light" src={logo} alt="logo light" />
          </div>

          <div>
            <p>Dark:</p>
            <img className="sidebar-logo reward-logo-dark" src={logo} alt="logo dark" />
          </div>
        </div>
      </div>
    )
  }

  const perkSourceItems: SelectionItem<ProductFulfillmentType>[] = Object.entries(
    ProductFulfillmentType
  ).map((source: [string, ProductFulfillmentType]) => ({
    id: source[0],
    title: source[1],
    item: source[1]
  }))

  const perkLogo = state.image_alternate ? state.image_alternate : state.image

  const get_var = (val: string) => {
    const variable = val.replace(/\$/g, '') as MessageTextVariable
    if (!variable) return

    let value: any = ''
    if (variable in MOCK_WORKER) value = MOCK_WORKER[variable as keyof MockWorker]
    if (value instanceof Array) return value.slice(-1).pop()

    return value
  }

  const escapeRegExp = (string: string) => {
    if (!string) return
    return string.replace(/\$\w+/g, (text) => get_var(text))
  }

  const updateOrCreateFulfillment = (stateProperty: string, product: ProductFulfillment) => {
    if (product.id) {
      setState((prevState: Reward) => {
        const property: any = prevState[stateProperty as keyof Reward]
        if (property instanceof Array) {
          const productIndex = property.findIndex(
            (object: WeGiftProduct | RewardProduct) => object.id === product.id
          )
          property[productIndex] = { ...product }
        }
        return { ...prevState }
      })
    } else {
      setState((prevState: Reward) => {
        let property: any = prevState[stateProperty as keyof Reward]
        if (property instanceof Array) {
          property = [...property, { ...product }]
        }
        return { ...prevState }
      })
    }
  }

  const handleProductChange = (product: ProductFulfillment | undefined) => {
    if (!product) {
      // eslint-disable-next-line no-console
      console.error('No product defined')
      return
    }
    switch (product.fulfillmentType) {
      case ProductFulfillmentType.weGift:
        updateOrCreateFulfillment('wegift_products', product)
        break
      case ProductFulfillmentType.inventory:
        updateOrCreateFulfillment('reward_products', product)
        break
      default:
        // eslint-disable-next-line no-console
        console.error('Object has no discriminant property', product)
        break
    }
  }

  const getStoresFromStoreGroups = (storeGroups: StoreGroup[]): Store[] => {
    if (!storeGroups?.length) return []
    const storeGroupStores = storeGroups.map((group: StoreGroup) =>
      allStores.filter((store: Store) => store.group_id === group.id)
    )
    return mergeToUniqueArray(storeGroupStores)
  }

  const getStoreGroupsFromStores = (stores: Store[]): StoreGroup[] => {
    if (!stores?.length) return []
    const storeGroupStores = stores.map((store: Store) =>
      allStoreGroups.filter((storeGroup: StoreGroup) => storeGroup.id === store.group_id)
    )
    return mergeToUniqueArray(storeGroupStores)
  }

  const makeTitle = (prefix: string, hasId: HasId) => {
    return `${prefix} Fulfillment #${hasId.id.substr(0, 5)}`
  }

  const weGiftProducts = weGiftProductsData.map((w) => {
    return (
      <ToggleButton key={w.id} icon={'expand-all'} text={makeTitle('WeGift', w)} isOpen={false}>
        <WeGiftFulfillment product={w} onChange={handleProductChange} />
      </ToggleButton>
    )
  })
  const inventoryProducts = rewardProductsData.map((r) => {
    return (
      <ToggleButton key={r.id} icon={'expand-all'} text={makeTitle('Inventory', r)} isOpen={false}>
        <InventoryFulfillment product={r} onChange={handleProductChange} />
      </ToggleButton>
    )
  })

  const NewProductFulfillmentForm = () => {
    if (!perkSourceSelected) return <div />

    return (
      <div>
        <h4>Create New {perkSourceSelected.title} Fulfillment</h4>
        <NewProductFulfillment
          source={perkSourceSelected.title as ProductFulfillmentType}
          onChange={handleProductChange}
        />
      </div>
    )
  }

  const submitButton = state.id ? (
    <div className="mt-2">
      <div className="mtb-1">
        <SubmitButton object={state} action={Action.update} apiCall={dispatchUpdateOperation} />
      </div>

      <div className="mtb-1">
        <SubmitButton object={state} action={Action.duplicate} apiCall={dispatchCreateOperation} />
      </div>
    </div>
  ) : (
    <div className="mt-2">
      <SubmitButton object={state} action={Action.create} apiCall={dispatchCreateOperation} />
    </div>
  )

  const clearButton = (
    <div className="mtb-1">
      <Button onClick={() => setState({ ...defaultState })} icon="eraser" text="Clear" />
    </div>
  )
  return (
    <FormGroup helperText="." labelFor="text-input">
      <h2>{state.title ? state.title : 'Add a new perk'}</h2>
      <div className="mtb-2">{displayLogo('Logo', state.image)}</div>
      <div className="mtb-2">{displayLogo('Alternative Logo', state.image_alternate)}</div>
      <div className="mtb-2">
        <Label>Title</Label>
        <InputGroup
          type="text"
          value={state.title}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setState({ ...state, title: e.currentTarget.value })
          }
        />
      </div>
      <div className="mtb-2">
        <Label>Tagline</Label>
        <InputGroup
          type="text"
          value={state.tagline}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setState({ ...state, tagline: e.currentTarget.value })
          }
        />
      </div>
      <div className="mtb-2">
        <Label>Description</Label>
        <TextArea
          className="textarea-section"
          fill={true}
          value={state.description}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
            setState({ ...state, description: e.currentTarget.value })
          }
        />
      </div>
      <div className="mtb-2">
        <Label>Points</Label>
        <NumericInput
          placeholder="Points Required to Redeem"
          value={state.points}
          onValueChange={(value: number) => setState({ ...state, points: value })}
        />
      </div>

      <div className="mtb-2">
        <Label>Redemption SMS Copy</Label>
        <TextArea
          className="textarea-section"
          fill={true}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
            setState({ ...state, custom_sms_message: e.currentTarget.value })
          }
          value={state.custom_sms_message}
        />
      </div>
      <div className="sidebar-special-row">
        <Label>Product Source Fulfillments</Label>
        <div className="mtb-2">
          {weGiftProducts}
          {inventoryProducts}
        </div>

        <div className="mtb-1">
          <ToggleButton icon={'add'} text={'Create New  Fulfillment'} isOpen={false}>
            <div className="mtb-1">
              <GenericSelector
                items={perkSourceItems}
                buttonText={perkSourceSelected?.title || 'Select Perk Source'}
                onItemSelect={setPerkSourceSelected}
              />
              <NewProductFulfillmentForm />
            </div>
          </ToggleButton>
        </div>
      </div>
      <div className="mtb-2">
        <Label>Assign to Store</Label>
        <GenericMultiSelector
          items={storeItems}
          selectedItems={selectedStoreItems}
          onItemSelect={(item: SelectionItem<Store>) => {
            setStoresSelected((prev: Store[]) => {
              const stores = Array.from(new Set([...prev, item.item]))
              setStoreGroupsSelected(getStoreGroupsFromStores(stores))
              return [...stores]
            })
          }}
          onItemRemove={(item: SelectionItem<Store>) => {
            setStoresSelected((prev: Store[]) => {
              const stores = prev.filter((comp: Store) => comp.id !== item.id)
              setStoreGroupsSelected(getStoreGroupsFromStores(stores))
              return [...stores]
            })
          }}
        />
      </div>
      <div className="mtb-2">
        <Label>Assign to Store Group</Label>
        <GenericMultiSelector
          items={storeGroupItems}
          selectedItems={selectedStoreGroupItems}
          onItemSelect={(item: SelectionItem<StoreGroup>) => {
            setStoreGroupsSelected((prev: StoreGroup[]) => {
              const storeGroups = Array.from(new Set([...prev, item.item]))
              setStoresSelected(getStoresFromStoreGroups(storeGroups))
              return [...storeGroups]
            })
          }}
          onItemRemove={(item: SelectionItem<StoreGroup>) => {
            setStoreGroupsSelected((prev: StoreGroup[]) => {
              const storeGroups = prev.filter((comp: StoreGroup) => comp.id !== item.id)
              setStoresSelected(getStoresFromStoreGroups(storeGroups))
              return [...storeGroups]
            })
          }}
        />
      </div>

      <div className="mtb-2">
        <DateTimePickerField
          label={'Effective Date'}
          value={state.effective_at}
          update={(value: Date | null) =>
            setState((prevState) => {
              prevState.effective_at = value
              return { ...prevState }
            })
          }
          iconName={'calendar'}
        />
        <DateTimePickerField
          label={'Expiry Date'}
          value={state.expires_at}
          update={(value: Date | null) =>
            setState((prevState) => {
              prevState.expires_at = value
              return { ...prevState }
            })
          }
          iconName={'calendar'}
        />

        <div className="mtb-1">
          <Switch
            innerLabelChecked="Active"
            innerLabel="Inactive"
            checked={state.active}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setState((prevState) => {
                prevState.active = event.target.checked
                return { ...prevState }
              })
            }}
          />
        </div>
      </div>

      <div>
        <Card>
          <h3 className="bp3-heading">Redemption Modal Live review</h3>
          <div className="redeem-drawer-reward-info-container">
            {perkLogo ? <img className="redeem-perk-logo" src={perkLogo} alt="perk logo" /> : null}

            <div className="redeem-perk-title">{state.title}</div>
            {state.tagline ? <div className="redeem-perk-tagline">{state.tagline}</div> : null}
            {state.description ? (
              <div className="redeem-perk-description">{state.description}</div>
            ) : null}
          </div>
        </Card>
      </div>

      <div className="mtb-2">
        <Card>
          <h3 className="bp3-heading">Redemption SMS Live Preview</h3>
          <Text>{escapeRegExp(state.custom_sms_message)}</Text>
        </Card>
      </div>

      {submitButton}
      {clearButton}
    </FormGroup>
  )
}
