/* eslint-disable @typescript-eslint/ban-types */
import { Button, Callout, FileInput, FormGroup, Intent } from '@blueprintjs/core'
import React, { useState } from 'react'
import { postForm } from '../../api/backend'

const postGoalsUpdate = async (file: File) => {
  const formData = new FormData()

  formData.append('file', file)
  return await postForm('integration/update-goals', formData)
}

interface CalloutState {
  title: string
  message: string | Object
  goal_ids?: Object
  intent: Intent
}
export const GoalsUpdate = () => {
  const [file, setFile] = useState<File | null>(null)
  const [callout, setCallout] = useState<CalloutState | null>(null)

  const setIds = (goalIdArray: Object) => {
    const liId = Object.entries(goalIdArray).map(([id, url]) => (
      <li key={id}>
        <a href={url}>{id}</a>
      </li>
    ))
    return <ul>{liId}</ul>
  }

  const setError = (errors: string[]) => {
    const getErrors = (errorMsgArray: string[]) => {
      const liId = errorMsgArray.map((msg) => <li key={msg}>{msg}</li>)
      return <ul>{liId}</ul>
    }

    setCallout({ title: 'Error', message: getErrors(errors), intent: 'danger' })
  }

  const setSuccess = (message: { detail: string; updated_goals: Object; errors: string[] }) => {
    if (message.errors.length !== 0) {
      setError(message.errors)
    } else {
      setCallout({
        title: 'Success',
        message: Object.keys(message.updated_goals).length + ' ' + message.detail,
        goal_ids: setIds(message.updated_goals),
        intent: 'success'
      })
    }
  }

  const handleUpload = async () => {
    if (!file) {
      const missingFileError = (errorMsg: string) => {
        setCallout({ title: 'Error', message: errorMsg, intent: 'danger' })
      }

      missingFileError('Please select a file')
      return
    }
    const response = await postGoalsUpdate(file)
    try {
      setSuccess(response)
    } catch (e) {
      setError(response)
    }
  }

  const fileText = file ? file.name : 'Select goals file to upload'

  return (
    <div>
      {callout ? (
        <Callout title={callout.title} intent={callout.intent} className="admin-panel">
          {callout.message}
          {callout.goal_ids}
        </Callout>
      ) : null}

      <h2>Update Goals</h2>
      <FormGroup helperText="" label="File" labelFor="file-input" inline={true}>
        <FileInput
          text={fileText}
          inputProps={{ accept: '.csv' }}
          onInputChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const fileList = e.target.files
            if (fileList && fileList.length > 0) {
              setFile(fileList[0])
            }
          }}
        />
      </FormGroup>
      <Button text="Upload" onClick={() => handleUpload()} />
    </div>
  )
}
